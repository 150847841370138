import React, { useState } from "react";
import getImage from "functions/getImage";
import "./UpdateGallery.css";
import CancelIcon from "@material-ui/icons/Cancel";
import DeletePopup from "components/DeletePopup/DeletePopup";
const UpdateGallery = ({ id, img, delImage }) => {
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  function openDeleteModal() {
    setShowDeleteModel(true);
  }
  const deleteProduct = () => {
    delImage(id);

    setShowDeleteModel(false);
  };
  return (
    <React.Fragment>
      <div className="imgDrop">
        <img className="DropImg" src={getImage(img)} alt="gallery img" />
        <div className="dropDiv"></div>
        <CancelIcon
          onClick={openDeleteModal}
          className="top-right"
        ></CancelIcon>
      </div>
      <DeletePopup
        show={showDeleteModel}
        handleClose={() => setShowDeleteModel(false)}
        handleDelete={deleteProduct}
        // product={id}
        text={"Image"}
      />
    </React.Fragment>
  );
};
export default UpdateGallery;
