import React, { useState, useEffect, useMemo } from "react";
import "./Telmed.css";
import Layout from "components/Layout/Layout";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import { API } from "config";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { Table, Card, Button } from "reactstrap";
import TelmedNewTableVar from "components/StorePage/Product/Table/TelmedNewTableVar";
import TelmedProductTable from "./TelmedProductTable/TelmedProductTable";
import TelmedPopup from "./TelmedPopup/TelmedPopup";
import DeletePopup from "components/DeletePopup/DeletePopup";
import WarehousePopup from "components/WarehousePopup/WarehousePopup";

const Telmed = () => {
  const columnsVar = useMemo(
    () => [
      {
        Header: "Depo",
        id: "expander", // 'id' is required
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? `👇` : `👉`}
          </span>
        ),
      },
      {
        Header: "#",
        id: "row",
        Cell: (rowInfo) => (
          <React.Fragment>
            <b>{parseInt(rowInfo.row.id) + 1}</b>
          </React.Fragment>
        ),
      },
      {
        Header: "Variant(s)",
        accessor: "values",
        Cell: ({ cell: { value } }) => <Genres values={value} />,
      },
      {
        Header: "Price €",
        accessor: "price",
        Cell: (rowInfo) => (
          <React.Fragment>
            <span>{rowInfo.cell.row.original.price}</span>
          </React.Fragment>
        ),
      },
      {
        Header: "Count",
        accessor: "count_sum",
        Cell: (rowInfo) => (
          <React.Fragment>
            <span>{rowInfo.cell.row.original.count_sum}</span>
          </React.Fragment>
        ),
      },
    ],
    []
  );
  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander", // 'id' is required
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? "👇" : "👉"}
          </span>
        ),
      },
      {
        Header: "#",
        id: "row",
        maxWidth: 50,
        filterable: false,
        Cell: (row) => {
          return (
            <span style={{ fontWeight: "bold" }}>{row.row.index + 1}</span>
          );
        },
      },
      // {
      //   Header: "Code",
      //   accessor: "code",
      // },
      {
        Header: "Code",
        accessor: "code",
        Cell: (rowInfo) => (
          <React.Fragment>
            <b>{rowInfo.cell.row.original.code}</b>
          </React.Fragment>
        ),
      },
      {
        Header: "Title",
        accessor: "name",
      },
      {
        Header: "Price",
        accessor: "price",
      },
      {
        Header: "Quantity",
        accessor: "quantity",
      },
      {
        Header: "Actions",
        disableSortBy: true,
        filterable: false,
        // accessor: "id",

        Cell: (row) => (
          <div className="cellIcons">
            <EditIcon
              color="primary"
              onClick={() => {
                setProduct(row.row.original);
                setShow(true);
              }}
            />
            <DeleteForeverIcon
              className="mr-1"
              color="error"
              onClick={() => {
                console.log("row.row.original.id", row.row.original.id);
                openDeleteModal(row.row.original.id);
              }}
            ></DeleteForeverIcon>
            <Link
              to={{
                pathname: `/user/telmed/variations/${row.row.original.slug}`,

                product: product,
                url: `/product/${row.row.original.slug}`,
              }}
            >
              <Button outline size="sm" color="primary">
                Variations
              </Button>
            </Link>
          </div>
        ),
      },
    ],
    []
  );
  const renderRowSubComponentDepo = (row) => {
    const { warehouses } = row.original;
    console.log("MuhamedSezairi", warehouses);
    return (
      <Card style={{ width: "30rem", margin: "0 auto" }}>
        <strong style={{ backgroundColor: "#b7b7a4" }} className="text-center">
          {" "}
          Warehouses{" "}
        </strong>
        <Table size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            {warehouses.map((item, key) => (
              <tr>
                <th scope="row">{key + 1}</th>
                <td>{item.name}</td>
                <td>{item.count}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
    );
  };
  const renderRowSubComponent = (row) => {
    const { product_variants } = row.original;
    return (
      <Card style={{ width: "70rem", margin: "0 auto" }}>
        <strong className="text-center"> Varations </strong>
        <TelmedNewTableVar
          columns={columnsVar}
          data={product_variants}
          // updateMyData={updateMyData}
          // skipPageReset={skipPageReset}
          // updateRow={updateRow}
          renderRowSubComponent={renderRowSubComponentDepo}
        />
      </Card>
    );
  };
  const Genres = ({ values }) => {
    return (
      <>
        {values.map((genre, idx) => {
          return (
            <span key={idx} className="badge">
              {genre.value.split("<->")[1]}
            </span>
          );
        })}
      </>
    );
  };
  const history = useHistory();
  const jwt = JSON.parse(localStorage.getItem("jwt"));
  const [isTelmed, setIsTelmed] = useState(false);
  const [show, setShow] = useState(false);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [store, setStore] = useState();
  const [forDelete, setForDelete] = useState();
  const [showWarehouseModel, setShowWarehouseModel] = useState(false);
  const handleClose = () => {
    setShow(false);
    setShowDeleteModel(false);
    setProduct(null);
  };

  function openDeleteModal(forDel) {
    console.log("forDel", forDel);
    setForDelete(forDel);
    setShowDeleteModel(true);
  }
  useEffect(() => {
    let data =
      jwt !== null
        ? {
            headers: {
              Authorization: `Token ${jwt.token}`,
            },
          }
        : {};

    var URL = `${API}/account/is_telmed/`;
    axios
      .post(URL, {}, data)
      .then(function (response) {
        console.log("kentsel", response);
        if (!response.data.result) {
          history.push("/user/dashboard");
        } else {
          setIsTelmed(response.data.result);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const getProducts = () => {
      let data =
        jwt !== null
          ? {
              headers: {
                Authorization: `Token ${jwt.token}`,
              },
            }
          : {};
      var URL = `${API}/products/telmed/`;
      axios
        .get(URL, data)
        .then(function (response) {
          setProducts(response.data.products);
          setStore(response.data.store);
          console.log("kentsel", response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    if (isTelmed) {
      //get functions
      getProducts();
    }
  }, [isTelmed]);

  const deleteProduct = async () => {
    const jwt = JSON.parse(localStorage.getItem("jwt"));
    let data =
      jwt !== null
        ? {
            method: "GET",
            headers: {
              // Accept: "application/json",
              // "Content-Type": "application/json",
              Authorization: `Token ${jwt.token}`,
            },
          }
        : {};
    let URL = `${API}/products/telmed/delete/product/${forDelete}/`;
    axios
      .delete(URL, data)
      .then((res) => {
        setProducts(res.data);
        setStore(res.data.store);
        console.log("res", res.status);
        console.log("res.data", res.data);
      })
      .catch((error) => {
        let errObj = JSON.parse(JSON.stringify(error));
        console.log("-------------------------741", errObj);
        // if (error.response.status === 401) {
        //   setIsLogin(false);
        // }
      });
    setShowDeleteModel(false);
  };

  return (
    <Layout>
      <div className="container tableContainer"></div>
      <div className="container pt-4">
        <div className="row justify-content-between">
          <Button color="primary" size="sm" onClick={() => setShow(true)}>
            Add Product
          </Button>
          <Button
            color="success"
            size="sm"
            onClick={() => setShowWarehouseModel(true)}
          >
            Add Depo
          </Button>
          <div className="mt-2 w-100">
            {products !== undefined && (
              <TelmedProductTable
                columns={columns}
                data={products}
                renderRowSubComponent={renderRowSubComponent}
              />
            )}
          </div>
        </div>
      </div>

      <TelmedPopup
        show={show}
        handleClose={handleClose}
        setProducts={setProducts}
        product={product}
      />
      <DeletePopup
        show={showDeleteModel}
        handleClose={() => setShowDeleteModel(false)}
        handleDelete={deleteProduct}
        // product={forDelete}
        text={"product"}
      />
      {store !== undefined && (
        <WarehousePopup
          show={showWarehouseModel}
          handleClose={() => setShowWarehouseModel(false)}
          warehousesCopy={store.warehouse}
          store_id={store.id}
          // handleDelete={deleteProduct}
          // product={forDelete}
          // text={"product"}
        />
      )}
    </Layout>
  );
};

export default Telmed;
