import React from "react";
import Modal from "react-modal";
import DeleteIcon from "@material-ui/icons/Delete";
import CancelIcon from "@material-ui/icons/Cancel";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));
function DeletePopup({ show, handleClose, handleDelete, text }) {
  // const [modalIsOpen, setIsOpen] = useState(true);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const classes = useStyles();
  return (
    <div>
      <Modal
        isOpen={show}
        onRequestClose={handleClose}
        style={customStyles}
        contentLabel="Delete Product"
        ariaHideApp={false}
      >
        <h2>Delete</h2>
        <h4>Are you sure that you want to delete this {text}?</h4>
        <Button
          variant="contained"
          className={classes.button}
          startIcon={<CancelIcon />}
          onClick={handleClose}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<DeleteIcon />}
          onClick={handleDelete}
        >
          Delete
        </Button>
      </Modal>
    </div>
  );
}
export default DeletePopup;
