import React, { useEffect } from "react";

import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import { API } from "../../config";
// import axios from "axios";
import "./Layout.css";
// import { signout } from "auth";
const Layout = ({
  title = "Title",
  description = "Description",
  className = "",
  children,
  cartSize,
}) => {
  const isAuth = () => {
    const jwt = JSON.parse(localStorage.getItem("jwt"));
    console.log("jwwwt", jwt);
    if (jwt !== null) {
      const token = jwt.token;
      let form_data = new FormData();
      form_data.append("token", token);
      fetch(`${API}/account/token/`, {
        method: "POST",
        body: form_data,
      })
        .then((res) => res.json())
        .then((ress) => {
          console.log("fffffffffffff", ress.success);
          if (ress.success) {
            console.log("jwt2 login true", ress);
          } else {
            localStorage.removeItem("jwt");
          }
        });
    } else {
      console.log("jwt yok");
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    isAuth();
  }, [window.location.href]);
  return (
    <React.Fragment>
      <Navbar cartSize={cartSize} />

      {/* <div className="jumbotron">
                <h2>{title}</h2>
                <p className="lead">{description}</p>
            </div>
            <div></div>
            <div className={className}>{children}</div> */}

      <div className="contentt">{children}</div>
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
