import React, { useState, useEffect } from "react";
import "./CartIcon.css";

import { useHistory } from "react-router-dom";

const CartIcon = ({ cartSize }) => {
    const history = useHistory();
    //yarin cartcount u yolla buraya
    const [cartCount, setCartCount] = useState("");
    const [linkTo, setLinkTo] = useState("/");

    useEffect(() => {
        if (cartSize === undefined) {
            setCartCount(
                localStorage.getItem("cart") != null
                    ? JSON.parse(localStorage.getItem("cart")).length
                    : 0
            );
            setLinkTo(
                localStorage.getItem("business") != null
                    ? `/${JSON.parse(localStorage.getItem("business")).slug}`
                    : "/"
            );
        } else {
            setCartCount(cartSize);
        }
    }, [cartSize]); //[localStorage.getItem("cart"), localStorage.getItem("business")]

    const routeChange = () => {
        history.push(linkTo);
    };

    return (
        <div className="cartIconDiv bg-success" onClick={routeChange}>
            <i className="fa fa-shopping-cart" />
            <span className="cartText">{cartCount}</span>
        </div>
    );
};

export default CartIcon;
