import React, { useState, useEffect, Fragment } from "react";
import { useTable, usePagination, useSortBy, useExpanded } from "react-table";
import NumberFormat from "react-number-format";
import { Table } from "reactstrap";
import "./ProductTable.css";
// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  row: { original },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  updateRow,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);
  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
    updateRow(original.id, id, value);
  };
  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      updateMyData(index, id, value);
      updateRow(original.id, id, value);
      event.target.blur();
    }
    // console.log(event.target.blur);
  };
  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  //   return <input value={value} onChange={onChange} onBlur={onBlur} />;
  return (
    <NumberFormat
      value={value}
      thousandSeparator={false}
      className="colsPriceCount"
      inputMode="numeric"
      decimalSeparator={"."}
      onFocus={(e) => e.target.select()}
      onChange={onChange}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
    />
  );
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};

// Be sure to pass our updateMyData and the skipPageReset option
export default function NewTableVar({
  columns,
  data,
  updateMyData,
  updateRow,
  skipPageReset,
  renderRowSubComponent,
}) {
  // For this example, we're using pagination to illustrate how to stop
  // the current page from resetting when our data changes
  // Otherwise, nothing is different here.
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    page,
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    // gotoPage,
    // nextPage,
    // previousPage,
    // setPageSize,
    // state: {
    //   //  pageIndex,
    //   //   pageSize
    // },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      // use the skipPageReset option to disable page resetting temporarily
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
      updateRow,
    },
    useSortBy,
    useExpanded,
    usePagination
  );
  // Render the UI for your table
  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };
  // const onChangeInSelect = (event) => {
  //   setPageSize(Number(event.target.value));
  // };

  // const onChangeInInput = (event) => {
  //   const page = event.target.value ? Number(event.target.value) - 1 : 0;
  //   gotoPage(page);
  // };
  return (
    <div
      style={{
        maxWidth: 1000,
        margin: "0 auto 30px auto",
        textAlign: "center",
      }}
    >
      <Table {...getTableProps()}>
        <thead className="prodTableHead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                <th
                  {...column.getHeaderProps()}
                  // className={
                  //     key < 2
                  //         ? "d-none d-md-table-cell"
                  //         : "d-table-cell"
                  // }
                >
                  <div {...column.getSortByToggleProps()} className="divTitle">
                    {column.render("Header")}
                    {generateSortingIndicator(column)}
                  </div>
                  {/* <Filter column={column} /> */}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Fragment key={row.getRowProps().key}>
                <tr>
                  {row.cells.map((cell, key) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
                {row.isExpanded && (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {renderRowSubComponent(row)}
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </Table>
      {/* <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div> */}
      {/* <Row>
        <Col className="col-4 col-md-3">
          <Button
            color="primary"
            className="mr-1"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </Button>
          <Button
            color="primary"
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            {"<"}
          </Button>
        </Col>
        <Col className="col-4 col-md-2" style={{ marginTop: 7 }}>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </Col>
        <Col className="d-none d-md-inline-block col-md-2">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>
        <Col className="d-none d-md-inline-block col-md-2">
          <CustomInput
            type="select"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </CustomInput>
        </Col>
        <Col className="col-4 col-md-3">
          <Button
            color="primary"
            onClick={nextPage}
            disabled={!canNextPage}
            className="mr-1"
          >
            {">"}
          </Button>
          <Button
            color="primary"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </Button>
        </Col>
      </Row> */}
    </div>
  );
}
