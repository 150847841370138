import React, { useState } from "react";
import Modal from "react-modal";
// import MultiImageInput from "react-multiple-image-input";
import { Button } from "reactstrap";
// import { makeStyles } from "@material-ui/core/styles";
// import UpdateGallery from "./UpdateGallery/UpdateGallery";
import axios from "axios";
import { API } from "config";
// import { useHistory } from "react-router-dom";
import VarMultiImage from "components/MultiImages/VarMultiImage";

// const useStyles = makeStyles((theme) => ({
//   button: {
//     margin: theme.spacing(1),
//   },
// }));
function VarImagePopup({
  show,
  handleClose,
  onClickDelete,
  product,
  varForImg,
  data,
  setData,
  setProductVariants,
}) {
  // const history = useHistory();
  // const jwt = JSON.parse(localStorage.getItem("jwt"));
  const [gallery, setGallery] = useState(product.gallery);
  // const [product_id, setProduct_id] = useState(product.id);
  const [selectedImg, setSelectedImg] = useState({});

  const customStyles = {
    content: {
      top: "40%",
      left: "50%",
      right: "auto",
      width: "60%",
      bottom: "-1%",
      marginRight: "-50%",
      marginBottom: "-10%",
      transform: "translate(-50%, -50%)",
    },
  };
  const updateVariationImage = async (id, image_id) => {
    const jwt = JSON.parse(localStorage.getItem("jwt"));
    let dataHeader =
      jwt !== null
        ? {
            method: "GET",
            headers: {
              // Accept: "application/json",
              // "Content-Type": "application/json",
              Authorization: `Token ${jwt.token}`,
            },
          }
        : {};
    // console.log(id, image_id);
    let form_data = new FormData();
    // form_data.append("id", id);
    form_data.append("image_id", image_id);

    const response = await axios.put(
      `${API}/products/update/variantion/${product.slug}/${id}`,
      form_data,
      dataHeader
    );
    console.log("22222", response.data);
    setProductVariants(response.data);
    setData(response.data);
  };
  const onClickSaveChange = () => {
    let new_data = [...data];
    console.log("varForImg", varForImg);
    console.log("varol image inş. calışır", selectedImg);
    console.log("data", data);
    new_data.filter((item) => item.id === varForImg.id)[0].image =
      selectedImg.src;
    updateVariationImage(varForImg.id, selectedImg.id);
    setData(new_data);
    handleClose();
  };
  return (
    <Modal
      isOpen={show}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel="Add Images"
      ariaHideApp={false}
    >
      <div className="text-center">
        <span>
          <b>Choose image</b>
        </span>

        <i className="fa fa-times close" onClick={handleClose}></i>
        <hr style={{ borderColor: "#999" }}></hr>
      </div>
      <VarMultiImage
        product_id={product.id}
        gallery={product.gallery}
        setGallery={setGallery}
        setSelectedImg={setSelectedImg}
      ></VarMultiImage>
      <Button
        className="float-right"
        // style={{ position: "absolute", bottom: "10px" }}
        outline
        size="sm"
        onClick={handleClose}
        color="secondary"
      >
        Cancel
      </Button>
      <Button
        className="float-right"
        // style={{ position: "absolute", bottom: "10px" }}
        outline
        size="sm"
        color="success"
        onClick={onClickSaveChange}
      >
        Save Change
      </Button>
      {/* <div className="row">
        <div className="col">
          <Button
            className="float-right"
            // style={{ position: "absolute", bottom: "10px" }}
            outline
            size="sm"
            color="secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
        <div className="col">
          <Button
            className="float-right"
            // style={{ position: "absolute", bottom: "10px" }}
            outline
            size="sm"
            color="secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </div> */}
    </Modal>
  );
}
export default VarImagePopup;
