import React, { useEffect } from "react";

import "./ImgDropzone.css";
import Dropzone from "react-dropzone";
import getImage from "functions/getImage";

const ImgDropzone = ({ logoImg, onLogoDrop, backgroundImg, onBackDrop }) => {
  const style = {
    backgroundImage: `url(${getImage(backgroundImg)})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  // const logoStyle = {
  //   display: logoImg !== null ? "table-cell" : "none",
  // };

  useEffect(() => {
    // console.log("121dsfds", logoImg, backgroundImg);
  }, [logoImg, backgroundImg]);
  return (
    <div className="allDropzones">
      <Dropzone onDrop={onBackDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="imgDrop" style={style}>
            <div className="borderDash">
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
          </div>
        )}
      </Dropzone>
      <Dropzone onDrop={onLogoDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="logoDrop">
            <img className="logoDropImg" src={getImage(logoImg)} alt="" />
            <div className="dropDiv">
              <input {...getInputProps()} />
              <p>521 X 512</p>
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  );
};

export default ImgDropzone;
