import React, { useState } from "react";

import "./SignupBusiness.css";
import Layout from "components/Layout/Layout";
import { Link } from "react-router-dom";

import { signupBusiness, signin, authenticate } from "auth";
import { useHistory } from "react-router-dom";
// import mangoImg from "img/mango.png";

const SignupBusiness = () => {
  const history = useHistory();

  const [values, setValues] = useState({
    full_name: "",
    phone: "",
    email: "",
    password: "",
    error: "",
    site: "mango724",
    success: false,
  });
  const { full_name, phone, email, password, success, error, site } = values;

  //2 parametre aliyor, values[name]'i guncelliyor
  const handleChange = (name) => (e) => {
    setValues({ ...values, error: false, [name]: e.target.value });
    // console.log("handleChange", name, e.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    setValues({ ...values, error: false });
    console.log("handleSubmit", values);
    signupBusiness({ full_name, phone, email, password, site }).then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error, success: false });
      } else {
        signin({ email, password, site }).then((data) => {
          if (data.error) {
            setValues({
              ...values,
              error: data.error,
              loading: false,
            });
          } else {
            authenticate(data, () => {
              history.push("/user/dashboard");
              setValues({
                ...values,
                redirectToReferrer: true,
              });
            });
          }
        });
        setValues({
          ...values,
          full_name: "",
          phone: "",
          email: "",
          password: "",
          error: "",
          success: true,
        });
      }
    });
  };

  const signUpForm = () => {
    return (
      <form onSubmit={submitHandler}>
        {showSuccess()}
        {showError()}
        <div className="form-group">
          <label htmlFor="name">First Name</label>
          <input
            type="text"
            className="form-control"
            id="name"
            onChange={handleChange("full_name")}
            placeholder="First Name"
            name="full_name"
            value={full_name}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number</label>
          <input
            type="text"
            className="form-control"
            id="phoneNumber"
            onChange={handleChange("phone")}
            placeholder="Phone Number"
            name="phone"
            value={phone}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email address</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={email}
            onChange={handleChange("email")}
            aria-describedby="emailHelp"
            placeholder="Enter email"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            className="form-control"
            onChange={handleChange("password")}
            id="password"
            value={password}
            name="password"
            placeholder="Password"
            required
          />
        </div>

        {/* <div className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck1"
                        />
                        <label className="form-check-label" htmlFor="exampleCheck1">
                            Check me out
                        </label>
                    </div> */}
        <button type="submit" className="btn btn-primary">
          Sign Up
        </button>
      </form>
    );
  };

  const showError = () => (
    <div
      className="alert alert-danger"
      style={{ display: error ? "" : "none" }}
    >
      {error}
    </div>
  );

  const showSuccess = () => (
    <div
      className="alert alert-info"
      style={{ display: success ? "" : "none" }}
    >
      New account is created. Please <Link to="/login">Signin</Link>
    </div>
  );

  return (
    <Layout
      title="Signup Business"
      description="Signup for shops and restaurants"
      className="container col-md-8 offset-md-2"
    >
      <div className="signupDiv  ">
        {/* <img src={mangoImg} className="imgmango" alt="mango" /> */}
        <h3 className="text-center">Sign up as Business</h3>
        <h6 className="mt-3 mb-2 text-center">
          Already have an account?
          <Link to="/login" className="ml-2">
            Login
          </Link>
        </h6>
        {signUpForm()}
      </div>
    </Layout>
  );
};

export default SignupBusiness;
