import React, { useState, useEffect } from "react";

import "./Language.css";

// import albania from "img/flags/albania.png";
// import turkey from "img/flags/turkey.png";
// import bosnia from "img/flags/bosnia.png";
// import united_kingdom from "img/flags/united-kingdom.png";

import Languages from "languages.json";
import { Link } from "react-router-dom";
import getImage from "functions/getImage";

const Language = () => {
    const [languages, setLanguages] = useState([]);
    const [defaultLang, setDefaultLang] = useState("EN");

    useEffect(() => {
        //async
        // axios.get('/api/restaurants/').then((res) => {
        //     setStores(res.data);
        //     console.log(res.data);
        // });
        setLanguages(Languages);
    }, []);

    const handleClick = (e, short) => {
        e.preventDefault();
        setDefaultLang(short);
    };

    return (
        <li className="nav-item dropdown languages ">
            <Link
                className="nav-link dropdown-toggle langText"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                to="/"
            >
                {defaultLang}
            </Link>
            <div className="dropdown-menu dropdown-menu-right ">
                {languages.map((item, key) => (
                    <Link
                        key={key}
                        value={item}
                        to={"/" + item.short}
                        className={`dropdown-item ${
                            item.short === defaultLang ? "activeLang" : ""
                        }`}
                        onClick={(e) => handleClick(e, item.short)}
                    >
                        <img src={getImage(item.img)} alt={item.name} />
                        {item.lang}
                    </Link>
                ))}
            </div>
        </li>
    );
};

export default Language;
