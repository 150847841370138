import React, { useState } from "react";
import Modal from "react-modal";
import CancelIcon from "@material-ui/icons/Cancel";
// import Button from "@material-ui/core/Button";
import { Button } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import { API } from "config";
import "./NewProductPopup.css";
import DeletePopup from "components/DeletePopup/DeletePopup";
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));
function NewProductPopup({
  show,
  handleClose,
  slug,
  id,
  productsDraft,
  setProductsDraft,
}) {
  const history = useHistory();
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState();
  const deleteProduct = () => {
    deleteProductDraft(deleteProductId);
    setShowDeleteModel(false);
  };

  const deleteProductDraft = async (id) => {
    const jwt = JSON.parse(localStorage.getItem("jwt"));
    let data =
      jwt !== null
        ? {
            method: "GET",
            headers: {
              // Accept: "application/json",
              // "Content-Type": "application/json",
              Authorization: `Token ${jwt.token}`,
            },
          }
        : {};
    let URL = `${API}/${id}/product/update/`;
    axios
      .delete(URL, data)
      .then((res) => {
        console.log("res", res.status);
        console.log("res.data", res.data);
        setProductsDraft(res.data.filter((item) => item.draft === true));
      })
      .catch((error) => {
        let errObj = JSON.parse(JSON.stringify(error));
        console.log("-------------------------741", errObj);
        // if (error.response.status === 401) {
        //   setIsLogin(false);
        // }
      });
  };
  // console.log("idddddddddddddddddddddd", id);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "600px",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const classes = useStyles();
  const [name, setName] = useState("");

  const createProductDraft = async () => {
    const jwt = JSON.parse(localStorage.getItem("jwt"));
    let data =
      jwt !== null
        ? {
            // method: "POST",
            headers: {
              // Accept: "application/json",
              // "Content-Type": "application/json",
              Authorization: `Token ${jwt.token}`,
            },
          }
        : {};
    var URL_POST = `${API}/products/add/draft/`;
    let form_data = new FormData();
    form_data.append("store_id", id);
    form_data.append("name", name);
    // form_data.append("active", "test");
    const new_product = await axios.post(URL_POST, form_data, data);
    return new_product.data;
  };
  const handleChangeProductName = (e) => {
    setName(e.target.value);
  };

  const onClickCreateProduct = () => {
    // let id = 100;
    createProductDraft().then((data) => {
      history.push(`/product/${data.slug}`);
    });
  };
  const onClickDelete = (product_id) => {
    // deleteProductDraft(product_id);
    setDeleteProductId(product_id);
    setShowDeleteModel(true);
  };
  return (
    <div>
      <Modal
        isOpen={show}
        onRequestClose={handleClose}
        style={customStyles}
        // className="npPopup"
        // overlayClassName="Overlay"
        contentLabel="Delete Product"
        ariaHideApp={false}
      >
        <h2 className="text-center">New Product Name</h2>
        <div className="titleDiv1 mb-4 mx-auto w-100 d-table">
          <input
            type="text"
            value={name}
            name="name"
            className="inputName"
            onChange={handleChangeProductName}
            // onBlur={handleonBlurUpdateStoreName}
            // onKeyPress={handleKeyPress}
          />
          <Button
            variant="contained"
            color="primary"
            //   className={classes.button}
            //   startIcon={<DeleteIcon />}
            onClick={onClickCreateProduct}
          >
            Create
          </Button>
        </div>
        <h5>Latest drafts</h5>
        <div className="row">
          {productsDraft !== undefined &&
            productsDraft.map((item, key) => (
              <>
                <span className="col-4 mb-3">
                  {item.name}
                  <br />
                  <Link to={`/product/${item.slug}`}>Resume</Link> |
                  <b
                    className="deleteDraft ml-1"
                    onClick={() => onClickDelete(item.id)}
                  >
                    Delete
                  </b>
                </span>
              </>
            ))}
        </div>
        <br />
        <Button
          variant="contained"
          className={classes.button}
          startIcon={<CancelIcon />}
          onClick={handleClose}
        >
          Cancel
        </Button>

        <DeletePopup
          show={showDeleteModel}
          handleClose={() => setShowDeleteModel(false)}
          handleDelete={deleteProduct}
          // product={forDelete}
          text={"product"}
        />
      </Modal>
    </div>
  );
}
export default NewProductPopup;
