import React, { useState, useEffect } from "react";
// import { Card } from "semantic-ui-react";
import { API } from "config";
import axios from "axios";
import StoreCard from "components/StoreCard/StoreCard";

const Stores = () => {
  const jwt = JSON.parse(localStorage.getItem("jwt"));
  const token = jwt.token;
  const [stores, setStores] = useState([]);
  useEffect(() => {
    const header = {
      Authorization: `Token ${token}`,
    };
    const restaurants = `${API}/stores`;
    let form_data = new FormData();
    form_data.append("site", "mango724");
    axios
      .post(restaurants, form_data, {
        headers: header,
      })
      .then(function (response) {
        // handle success
        // console.log(response);
        // console.log(response.data.result, API);
        // setStores(response.data);
        setStores(response.data.result);
        // console.log("stores", stores);
        // setLoading(true);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {});
  }, []);
  return (
    <React.Fragment>
      {/* <Card.Group> */}
      {stores.map((s, i) => (
        <StoreCard store={s} key={i} />
      ))}
      {/* </Card.Group> */}
    </React.Fragment>
  );
};

export default Stores;
