import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import CancelIcon from "@material-ui/icons/Cancel";
// import Button from "@material-ui/core/Button";
import { Button } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import { API } from "config";
import "./TelmedPopup.css";
import DeletePopup from "components/DeletePopup/DeletePopup";
import Select from "react-select";
import NumberFormat from "react-number-format";
const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
}));
function TelmedPopup({ show, handleClose, setProducts, product }) {
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [measureList, setMeasureList] = useState([]);
    const [values, setValues] = useState({
        name: "",
        code: "",
        price: 0,
        unit: "piece",
    });

    const handleClose2 = () => {
        setValues({
            name: "",
            code: "",
            price: 0,
            unit: "piece",
        });
        handleClose();
    };
    useEffect(() => {
        if (product !== null) {
            setValues({
                name: product.name,
                code: product.code,
                price: product.price,
                unit: product.measure_unit.value,
            });
            setSelectedUnit(product.measure_unit);
        }
    }, [product]);

    //   const [jsonData, setJsonData] = useState({});
    //   const history = useHistory();
    //   const [showDeleteModel, setShowDeleteModel] = useState(false);

    // console.log("idddddddddddddddddddddd", id);
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            // width: "400px",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
        },
    };

    const createProduct = async () => {
        const jwt = JSON.parse(localStorage.getItem("jwt"));
        let data =
            jwt !== null
                ? {
                      headers: {
                          Authorization: `Token ${jwt.token}`,
                      },
                  }
                : {};
        var URL_POST = `${API}/products/telmed/add/product/`;
        let form_data = new FormData();
        if (product !== null) {
            form_data.append("id", product.id);
        }
        form_data.append("name", values.name);
        form_data.append("code", values.code);
        form_data.append("price", parseFloat(values.price));
        // if (values.price !== 0) {
        //     form_data.append("price", parseInt(values.price.slice(0, -2)));
        // } else {
        //     form_data.append("price", values.price);
        // }

        form_data.append("unit", values.unit);
        const res = await axios.post(URL_POST, form_data, data);
        setProducts(res.data);
        setValues({
            name: "",
            code: "",
            price: 0,
            unit: "piece",
        });
        console.log("new_product", res);
        // return new_product.data;
    };
    //   const handleChangeProductName = (e) => {
    //     setName(e.target.value);
    //   };

    // const onClickCreateProduct = () => {
    //     // let id = 100;
    //     createProductDraft().then((data) => {
    //         history.push(`/product/${data.slug}`);
    //     });
    // };
    // const onClickDelete = (product_id) => {
    //     // deleteProductDraft(product_id);
    //     setDeleteProductId(product_id);
    //     setShowDeleteModel(true);
    // };

    useEffect(() => {
        const jwt = JSON.parse(localStorage.getItem("jwt"));
        let data =
            jwt !== null
                ? {
                      method: "GET",
                      headers: {
                          Authorization: `Token ${jwt.token}`,
                      },
                  }
                : {};
        var URL_measuere_unit = `${API}/products/get/measuere_unit/`;
        axios
            .get(URL_measuere_unit, data)
            .then((response) => {
                console.log("measuere_unit", response.data.result);
                setMeasureList(response.data.result);
                setSelectedUnit(response.data.result[0]);
            })
            .catch((error) => {
                let errObj = JSON.parse(JSON.stringify(error));
                console.log("products/owner/measuere_unit---------741", errObj);
                // setIsLogin(false);
            });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        // e.stopPropagation();
        console.log("valuesss", values);
        createProduct();
        handleClose();
        // updateProduct(values);
    };

    //   const handleChangeProductPrice = (event) => {
    //     // setPrice(event.target.value.slice(0, -2));
    //     if (parseInt(event.target.value.slice(0, -2)) === 0) {
    //       // setError({ ...error, price: true });
    //     } else {
    //       // setError({ ...error, price: false });
    //       // setJsonData({
    //       //     ...jsonData,
    //       //     price: event.target.value.slice(0, -2),
    //       // });
    //     }
    //   };
    //   const handleonBlurUpdateProductPrice = () => {
    //     // updateProduct(jsonData);
    //   };

    const handleChangeUnit = (Unit) => {
        console.log("valuess2", Unit);
        setSelectedUnit(Unit);
        let abc = { ...values };
        abc["unit"] = Unit.value;
        setValues(abc);
        // setJsonData({ ...jsonData, unit: Unit.value });
    };
    //   const handleOnBlurUnit = (Unit) => {
    //     // setSelectedUnit(Unit);
    //     console.log("Unitmm", selectedUnit.value);
    //     // updateProduct(jsonData);
    //   };
    const changeValues = (varName, value) => {
        console.log("valuesss1", value, varName);
        let abc = { ...values };
        abc[varName] = value;
        if (varName === "price") {
            console.log("bumbum", value);
            abc[varName] = parseFloat(value).toFixed(2);
        }
        setValues(abc);
    };
    console.log("product", product);
    return (
        <div className="telmedPopup">
            <Modal
                isOpen={show}
                onRequestClose={handleClose2}
                id="telmedModal"
                style={customStyles}
                // className="npPopup"
                // overlayClassName="Overlay"
                contentLabel="Delete Product"
                ariaHideApp={false}
            >
                <h2 className="text-center mb-3">New Product</h2>
                <div className="closeBtn" onClick={handleClose2}>
                    <i className="fa fa-times"></i>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="row justify-content-center">
                        <div className="form-group col-11 mb-2">
                            <label htmlFor="productNameInput">Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="productNameInput"
                                required
                                placeholder="Enter name"
                                value={values.name}
                                onChange={(e) =>
                                    changeValues("name", e.target.value)
                                }
                            />
                        </div>

                        <div className="form-group col-11 mb-2">
                            <label htmlFor="codeInput">Code</label>
                            <input
                                type="text"
                                className="form-control"
                                id="codeInput"
                                value={values.code}
                                placeholder="Enter code"
                                onChange={(e) =>
                                    changeValues("code", e.target.value)
                                }
                            />
                        </div>
                        <div className="form-group col-11 mb-2">
                            <label htmlFor="productNameInput">
                                Measure Unit
                            </label>

                            <Select
                                value={selectedUnit}
                                // onBlur={handleOnBlurUnit}
                                options={measureList}
                                isClearable={true}
                                name="measuereUnit"
                                placeholder="Measuere unit"
                                className=""
                                onChange={handleChangeUnit}
                            />
                        </div>
                        <div className="form-group col-11 mb-2">
                            <label htmlFor="productNameInput">Price</label>
                            <div className="w-100">
                                <input
                                    type="number"
                                    className="form-control col-10 d-inline-block"
                                    name="price"
                                    onFocus={(e) => e.target.select()}
                                    // onBlur={(e) =>
                                    //     changeValues("price", e.target.value)
                                    // }
                                    onChange={(e) =>
                                        changeValues("price", e.target.value)
                                    }
                                    defaultValue={parseFloat(
                                        values.price
                                    ).toFixed(2)}
                                    // value={
                                    //     product !== null
                                    //         ? product.price
                                    //         : "0.00"
                                    // }
                                    step=".01"
                                    min="0"
                                ></input>
                                <span
                                    className=""
                                    style={{
                                        fontWeight: "600",
                                        fontSize: "24px",
                                        float: "right",
                                    }}
                                >
                                    €
                                </span>
                            </div>

                            {/* <NumberFormat
                                value={values.price}
                                // className="itemPrice"
                                thousandSeparator={false}
                                inputMode="numeric"
                                decimalSeparator={"."}
                                onFocus={(e) => e.target.select()}
                                suffix={" €"}
                                name="price"
                                onChange={(e) =>
                                    changeValues("price", e.target.value)
                                }
                                // onBlur={handleonBlurUpdateProductPrice}
                                className=" form-control"
                                // onKeyPress={handleKeyPressProductPrice}
                            /> */}
                        </div>
                    </div>

                    <div className="row justify-content-between">
                        <button
                            className="btn btn-md btn-danger col-4"
                            onClick={handleClose2}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="btn btn-md btn-success col-4"
                        >
                            Submit
                        </button>
                    </div>
                </form>

                <br />
            </Modal>
        </div>
    );
}
export default TelmedPopup;
