import React from "react";
import "./SuccessMessage.css";
import checkImg from "img/checkmark.gif";

const SuccessMessage = ({ show }) => {
  return (
    <div className={`successDiv ${!show && "fade"}`}>
      <img src={checkImg} alt="Success Message" />
      Data saved
    </div>
  );
};

export default SuccessMessage;
