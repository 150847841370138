import React from 'react';

import LoadingImg from './img/loading.gif';

const Loading = () => {
    const styles = {
        width: '150px',
        height: 'auto',
    };
    return (
        <div>
            <img src={LoadingImg} style={styles} alt="Loading" />
        </div>
    );
};

export default Loading;
