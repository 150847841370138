import React from "react";

import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    //   main-footer text-center bg-tava
    // <div className="ui inverted vertical footer segment bg-tava">
    <div className="main-footer text-center bg-tava">
      Copyright Reserved.{" "}
      <Link to="/">
        <b>Store.Tele724</b>
      </Link>
    </div>
  );
};

export default Footer;
