import React from 'react';

import './ImagePopup.css';
import { Modal } from 'react-bootstrap';

const ImagePopup = ({ show, handleClose, imgSrc, alt }) => {
    return (
        <Modal show={show} onHide={handleClose} animation={false} centered>
            <Modal.Header closeButton>
                <Modal.Title style={{ color: 'green' }}>{alt}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
                <div className="imgPopup">
                    <img src={imgSrc} alt={alt} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ImagePopup;
