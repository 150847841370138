import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import Layout from "components/Layout/Layout";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { API } from "../../config";
import axios from "axios";
import SuccessMessage from "components/SuccessMessage/SuccessMessage";
// import VariationPopup from "../VariationPopup/VariationPopup";
import TelmedNewTableVar from "components/StorePage/Product/Table/TelmedNewTableVar";
import WarehouseTable from "components/StorePage/Product/Table/WarehouseTable";
import "./ProductVariations.css";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import styled from "styled-components";
import getImage from "functions/getImage";
import VarImagePopup from "./VarImagePopup";
import VarWarehousePopup from "./VarWarehousePopup";
import DeletePopup from "components/DeletePopup/DeletePopup";
import { Card } from "reactstrap";
const Styles = styled.div`
  padding: 1rem;
  table {
    border-spacing: 0;
    border: 1px solid black;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      :last-child {
        border-right: 0;
      }
      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }
  .pagination {
    padding: 0.5rem;
  }
`;
const TelmedProductVariations = (props) => {
  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const updateRow = async (id, col, value) => {
    const jwt = JSON.parse(localStorage.getItem("jwt"));
    let dataHeader =
      jwt !== null
        ? {
            method: "GET",
            headers: {
              Authorization: `Token ${jwt.token}`,
            },
          }
        : {};
    let form_data = new FormData();
    form_data.append(col, value);

    await axios.put(
      `${API}/products/update/variantion/${product.slug}/${id}`,
      form_data,
      dataHeader
    );
  };
  const [successShow, setSuccessShow] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [showVarImage, setShowVarImage] = useState(false);
  const [varForImg, setVarForImg] = useState({});
  const [product, setProduct] = useState({});
  const [variants, setVariants] = useState([]);

  const [selectedVariantions, setSelectedVariantions] = useState([]);
  const jwt = JSON.parse(localStorage.getItem("jwt"));
  const token = jwt.token;
  const header = {
    Authorization: `Token ${token}`,
  };
  const columns1 = useMemo(
    () => [
      {
        Header: "#",
        id: "row",
        Cell: (rowInfo) => (
          <React.Fragment>
            <b>{parseInt(rowInfo.row.id) + 1}</b>
          </React.Fragment>
        ),
      },
      {
        Header: "Name",
        Cell: (rowInfo) => (
          <React.Fragment>
            <b>{rowInfo.cell.row.original.name}</b>
          </React.Fragment>
        ),
      },
      // {
      //   Header: "Count",
      //   accessor: "count",
      // },
      {
        Header: "Count",
        Cell: (rowInfo) => (
          <React.Fragment>
            <b>{rowInfo.cell.row.original.count}</b>
          </React.Fragment>
        ),
      },
    ],
    []
  );
  const columns = useMemo(
    () => [
      {
        Header: "Depo",
        id: "expander", // 'id' is required
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? `👇` : `👉`}
          </span>
        ),
      },
      {
        Header: "#",
        id: "row",
        Cell: (rowInfo) => (
          <React.Fragment>
            <b>{parseInt(rowInfo.row.id) + 1}</b>
          </React.Fragment>
        ),
      },
      {
        Header: "Code",
        Cell: (rowInfo) => (
          <React.Fragment>
            <b>{rowInfo.cell.row.original.code}</b>
          </React.Fragment>
        ),
      },
      {
        Header: "Variant(s)",
        accessor: "values",
        Cell: ({ cell: { value } }) => <Genres values={value} />,
      },
      {
        Header: "Price €",
        accessor: "price",
      },
      {
        Header: "Count",
        accessor: "count_sum",
        Cell: (rowInfo) => (
          <React.Fragment>
            <span>{rowInfo.cell.row.original.count_sum}</span>
          </React.Fragment>
        ),
      },
      // {
      //   Header: "Count",
      //   accessor: "count_sum",
      // },
      //   {
      //     Header: "Image",
      //     // id: "delete",
      //     disableSortBy: true,
      //     accessor: "image",

      //     Cell: (rowInfo) => (
      //       <React.Fragment>
      //         <img
      //           onClick={() => {
      //             onClickAddImage(rowInfo.cell.row.original);
      //           }}
      //           src={getImage(rowInfo.cell.row.original.image)}
      //           style={{ width: "80px" }}
      //           alt=""
      //         ></img>
      //       </React.Fragment>
      //     ),
      //   },
      {
        Header: "Actions",
        disableSortBy: true,
        accessor: "id",

        Cell: (rowInfo) => (
          <div className="cellIcons">
            <DeleteForeverIcon
              color="secondary"
              onClick={() => {
                console.log("ccccc", rowInfo.cell.row.original.id);
                if (rowInfo.cell.row.original.id !== undefined) {
                  openDeleteModal(rowInfo.cell.row.original);
                } else {
                  const newList = rowInfo.data.filter(
                    (item) => item.key !== rowInfo.cell.row.original.key
                  );
                  setData(newList);
                }
              }}
            ></DeleteForeverIcon>
            <i
              className="fa fa-university ml-2"
              aria-hidden="true"
              onClick={() => {
                console.log("onClick Depo", rowInfo.cell.row.original.id);
                openVarWarehouseModel(rowInfo.cell.row.original);
              }}
            ></i>
          </div>
        ),
      },
    ],
    []
  );
  useEffect(() => {
    if (successShow) {
      setTimeout(function () {
        setSuccessShow(false);
      }, 2000);
    }
  }, [successShow]);

  useEffect(() => {
    var URL_GET_Product = `${API}/products/owner/details/${props.match.params.productSlug}`;
    axios
      .get(URL_GET_Product, {
        headers: header,
      })
      .then((response) => {
        setProduct(response.data.result);
      });

    const getVariants = async () => {
      const response = await axios.get(`${API}/products/variants/get`);
      setVariants(response.data);
      setSelectedVariantions(response.data);
    };
    getVariants();

    const getVariantions = async () => {
      let jwt = JSON.parse(localStorage.getItem("jwt"));
      let data =
        jwt !== null
          ? {
              headers: {
                Authorization: `Token ${jwt.token}`,
              },
            }
          : {};
      console.log("buraya geliyor mu", API);
      const response = await axios.get(
        `${API}/products/get/variantions/${props.match.params.productSlug}`,
        data
      );
      console.log("codee", response.data);
      setData(response.data);
    };
    getVariantions();
  }, []);

  const history = useHistory();

  const [productVariants, setProductVariants] = useState([]);
  useEffect(() => {
    if (product.product_variants !== undefined) {
      setProductVariants(product.product_variants);
    }
  }, [product]);

  useEffect(() => {
    if (successShow) {
      setTimeout(function () {
        setSuccessShow(false);
      }, 2000);
    }
  }, [successShow]);

  const onClickBack = () => {
    history.goBack();
  };

  const Genres = ({ values }) => {
    return (
      <>
        {values.map((genre, idx) => {
          return (
            <span key={idx} className="badge">
              {genre.value.split("<->")[1]}
              {/* {genre.label} */}
            </span>
          );
        })}
      </>
    );
  };

  const renderRowSubComponent = (row) => {
    const { warehouses } = row.original;
    return (
      <Card style={{ width: "18rem", margin: "0 auto" }}>
        <strong className="text-center"> Warehouses </strong>
        <WarehouseTable columns={columns1} data={warehouses} />
      </Card>
    );
  };

  const deleteVariation = async (id) => {
    let form_data = new FormData();
    form_data.append("id", id);

    const response = await axios.delete(
      `${API}/products/delete/variantion/${props.match.params.productSlug}/${id}`,
      {
        headers: header,
      }
    );
    setProductVariants(response.data);
    setData(response.data);
  };

  const [variantValues, setVariantValues] = useState([]);
  const [selectedVariantValues, setSelectedVariantValues] = useState([]);

  const onClickVariant = (event) => {
    let newArr = [...variants];
    newArr.map((item, key) => (item.activeItem = false));
    newArr[event].activeItem = true;
    setVariants(newArr);
    setVariantValues(
      variants.filter((item) => item.value === newArr[event].value)[0]
        .variant_values
    );
    setSelectedVariantValues(
      selectedVariantions.filter(
        (item) => item.value === newArr[event].value
      )[0].variant_values
    );
  };
  const [btnContinue, setBtnContinue] = useState(true);

  const onClickValue = (index) => {
    let newArr = [...variantValues];
    let newArr_show = [...selectedVariantValues];
    let selectedVariantions_arr = [...selectedVariantions];
    let i = 0;
    if (newArr[index].activeItem === true) {
      newArr[index].activeItem = false;
      newArr_show[index].selected = false;
      newArr_show.map((v, k) => {
        if (v.selected === true) {
          i++;
        }
      });
    } else {
      newArr[index].activeItem = true;
      newArr_show[index].selected = true;
      newArr_show.map((v, k) => {
        if (v.selected === true) {
          i++;
        }
      });
    }

    if (i > 0) {
      selectedVariantions_arr.filter(
        (item) => item.value === newArr[index].variant_id
      )[0].selected = true;
    } else {
      selectedVariantions_arr.filter(
        (item) => item.value === newArr[index].variant_id
      )[0].selected = false;
    }

    if (
      selectedVariantions_arr.filter((item) => item.selected === true)[0] !==
      undefined
    ) {
      setBtnContinue(false);
    } else {
      setBtnContinue(true);
    }

    setSelectedVariantions(selectedVariantions_arr);
    setSelectedVariantValues(newArr_show);
    setVariantValues(newArr);
  };

  const onClickRemoveValue = (value, variant_id) => {
    let new_arr = [...selectedVariantions];
    let newArr = [...variantValues];

    newArr.map((v2, k2) => {
      if (v2.value === value) {
        v2.activeItem = false;
      }
    });
    setVariantValues(newArr);

    let i = 0;
    new_arr.map((v, k) => {
      if (v.value === variant_id) {
        v.variant_values.map((v2, k2) => {
          if (v2.value === value) {
            v2.selected = false;
          }
          if (v2.selected === true) {
            i++;
          }
        });
      }
    });
    if (i > 0) {
      new_arr.filter((item) => item.value === variant_id)[0].selected = true;
    } else {
      new_arr.filter((item) => item.value === variant_id)[0].selected = false;
    }
    if (new_arr.filter((item) => item.selected === true)[0] !== undefined) {
      setBtnContinue(false);
    } else {
      setBtnContinue(true);
    }
    setSelectedVariantions(new_arr);
  };
  const [secilenler, setSecilenler] = useState([]);
  const [numberOfVar, setNumberOfVar] = useState(0);

  const objectsEqual = (o1, o2) =>
    typeof o1 === "object" && Object.keys(o1).length > 0
      ? Object.keys(o1).length === Object.keys(o2).length &&
        Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
      : o1 === o2;
  const arraysEqual = (a1, a2) =>
    a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

  useEffect(() => {
    const son = getCombn(secilenler);
    let data1 = [...data];
    setNumberOfVar(son.length);
    for (var i = 0; i < son.length; i++) {
      let d = {};
      d["key"] = i;
      d["price"] = "0.0";
      d["count"] = "0";
      d["values"] = [];
      son[i].split("#").map((val, k) => {
        d["values"].push({ value: val });
      });
      let var_var = false;
      for (var j = 0; j < data1.length; j++) {
        let new_val = [...data1[j].values];
        let new_arr_values = [];
        new_val.map((v, k) => {
          new_arr_values.push({ value: v.value });
        });
        if (arraysEqual(d["values"], new_arr_values)) {
          var_var = true;
        }
      }
      if (!var_var) {
        data1.push(d);
      }
    }
    setData(data1);
    postVariantions(data1);
  }, [secilenler]);

  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [varForDelete, setVarForDelete] = useState();
  const [originalVar, setOriginalVar] = useState({});
  const [showVarWarehouse, setShowVarWarehouse] = useState();
  useEffect(() => {
    setSkipPageReset(false);
  }, [data]);
  const openDeleteModal = (variant, data) => {
    setShowDeleteModel(true);
    setVarForDelete(variant.id);
  };
  const openVarWarehouseModel = (variant) => {
    setOriginalVar(variant);
    setShowVarWarehouse(true);
  };
  const DeleteVar = () => {
    deleteVariation(varForDelete);
    setShowDeleteModel(false);
  };
  const onClickAddImage = (variant) => {
    setVarForImg(variant);
    setShowVarImage(true);
  };

  const postVariantions = async (variations) => {
    var URL = `${API}/products/add/variant/${product.id}/`;
    const add = await axios.post(
      URL,
      { variations },
      {
        headers: header,
      }
    );
    setData(add.data);
  };

  function getCombn(arr, pre) {
    pre = pre || "";
    if (!arr.length) {
      return pre;
    }
    var ans = arr[0].reduce(function (ans, value) {
      if (pre !== "") {
        return ans.concat(getCombn(arr.slice(1), pre + "#" + value));
      } else {
        return ans.concat(getCombn(arr.slice(1), pre + value));
      }
    }, []);

    return ans;
  }
  const onClickBtnContinue = () => {
    setSecilenler([]);
    setShowTable(true);
    let new_arr = [...selectedVariantions];
    new_arr = new_arr
      .filter(function (item) {
        return item.selected === true;
      })
      .map(function ({ variant_values }) {
        return { variant_values };
      });
    for (var i = 0; i < new_arr.length; i++) {
      let new_arr1 = new_arr[i].variant_values
        .filter(function (item) {
          return item.selected === true;
        })
        .map(function ({ value, label }) {
          return value + "<->" + label;
        });
      setSecilenler((secilenler) => [...secilenler, new_arr1]);
    }
    new_arr.map((item, key) => {
      item.variant_values.map((i, k) => {
        if (i.selected === true) {
        }
      });
    });
  };
  return (
    <Layout>
      <div className="container mt-4">
        <div className="d-flex justify-content-center">
          <div>
            <h3>
              {product.code}| {product.name} - varations
            </h3>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            {" "}
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<ArrowBackIcon />}
              onClick={onClickBack}
            >
              Back
            </Button>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-6">
            <h6>Variations</h6>
          </div>
          <div className="col-6">
            <h6>Attributes and options you've selected.</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="d-flex flex-wrap var-tag">
              {variants !== undefined &&
                variants.map((item, index) => (
                  <span
                    key={index}
                    className={
                      item.activeItem ? "inline-block selected" : "inline-block"
                    }
                    id={"msku-variation-tag-" + item.label}
                    onClick={() => onClickVariant(index)}
                  >
                    {item.label}
                    <a href="#"></a>
                  </span>
                ))}
            </div>
            <div className="d-flex flex-wrap var-val">
              <ul>
                {variantValues !== undefined &&
                  variantValues.map((item, index) => (
                    <li
                      key={index}
                      className={item.activeItem ? "selected" : ""}
                      onClick={() => onClickValue(index)}
                    >
                      {item.label}
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          <div className="col-6">
            <div className="d-flex flex-wrap right-section">
              <div id="msku-variation-details-container" className="var-dtl">
                {selectedVariantions !== undefined &&
                  selectedVariantions.map((item, key) => (
                    <div key={key} className="msku-parent-attribute-container">
                      {item.selected && (
                        <>
                          {" "}
                          <div className="msku-details-header">
                            <b> {item.label}</b>
                          </div>
                          <ul
                            className="sortable ui-sortable"
                            id="msku-details-list-0"
                          >
                            {item.variant_values !== undefined &&
                              item.variant_values.map((val, key) => (
                                <>
                                  {val.selected && (
                                    <>
                                      {" "}
                                      <li className="">
                                        <span
                                          title="Drag to rearrange"
                                          className="ui-sortable-handle"
                                        >
                                          {" "}
                                          {val.label}{" "}
                                        </span>
                                        <a
                                          onClick={() =>
                                            onClickRemoveValue(
                                              val.value,
                                              val.variant_id
                                            )
                                          }
                                        ></a>
                                      </li>
                                    </>
                                  )}
                                </>
                              ))}
                          </ul>
                          <hr></hr>
                        </>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4 mt-4">
          <div className="col-12">
            <button
              className="btn-primary btn"
              id="msku-create-variations-button"
              type="button"
              disabled={btnContinue && "disabled"}
              onClick={onClickBtnContinue}
            >
              Continue
            </button>
            <hr></hr>
            {showTable && <span>Number of variations: {numberOfVar}</span>}
          </div>
        </div>

        {data.length > 0 && (
          <Styles>
            <TelmedNewTableVar
              columns={columns}
              data={data}
              updateMyData={updateMyData}
              skipPageReset={skipPageReset}
              updateRow={updateRow}
              renderRowSubComponent={renderRowSubComponent}
            />
          </Styles>
        )}
        <VarImagePopup
          show={showVarImage}
          handleClose={() => setShowVarImage(false)}
          varForImg={varForImg}
          product={product}
          data={data}
          setData={setData}
          setProductVariants={setProductVariants}
        />
        <DeletePopup
          show={showDeleteModel}
          handleClose={() => setShowDeleteModel(false)}
          handleDelete={DeleteVar}
          text={"Variant"}
        />
        <VarWarehousePopup
          show={showVarWarehouse}
          handleClose={() => setShowVarWarehouse(false)}
          originalVar={originalVar}
          data={data}
          setData={setData}
        />
        <SuccessMessage show={successShow}></SuccessMessage>
      </div>
    </Layout>
  );
};
export default TelmedProductVariations;
