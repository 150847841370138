import React, { useEffect, useState } from "react";
import axios from "axios";
import getCookie from "getCookie";

const Form = () => {
    const [csrf, setCsrf] = useState("");
    const [nameValue, setNameValue] = useState("");
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("senin icin", csrf);

        var bodyFormData = new FormData();
        bodyFormData.set("name", nameValue);
        axios({
            method: "post",
            url: "/api/abcd/",
            data: bodyFormData,
            headers: {
                "Content-Type": "multipart/form-data",
                "X-CSRFToken": csrf,
            },
        })
            .then(function (response) {
                //handle success
                console.log(response);
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    };
    useEffect(() => {
        //async
        setCsrf(getCookie("csrftoken"));

        // console.log("speed", csrf);      anında degismiyor, atliyor bunu
    }, []);
    return (
        <form action="/api/abcd" method="post">
            <input
                name="name"
                type="text"
                value={nameValue}
                onChange={(e) => setNameValue(e.target.value)}
            ></input>
            <button type="submit" onClick={handleSubmit}>
                Send
            </button>
        </form>
    );
};

export default Form;
