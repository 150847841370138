import React from "react";
// import { Button, Card, Image } from "semantic-ui-react";
// import getImage from "functions/getImage";
import { Link } from "react-router-dom";
const StoreCard = ({ store }) => (
  <Link to={"/" + store.slug}>
    <div>Muhamed</div>
    {/* <Card>
      <Card.Content>
        <Image floated="right" size="mini" src={getImage(store.logo)} />
        <Card.Header>{store.name}</Card.Header>
        <Card.Meta>{store.category}</Card.Meta>
        <Card.Description>
          <Image size="medium" src={getImage(store.background)} wrapped />
        </Card.Description>
      </Card.Content>
      {store.active === false && (
        <Card.Content extra>
          <span className="errorMsg">
            Your store is not active please contact the administrator.
          </span>
        </Card.Content>
      )}
    </Card> */}
  </Link>
);

export default StoreCard;
