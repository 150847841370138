import { API } from "config";
import axios from "axios";
const is_auth = async () => {
  const jwt = JSON.parse(localStorage.getItem("jwt"));
  const token = jwt.token;
  let form_data = new FormData();
  form_data.append("token", token);
  const response = await axios.post(`${API}/account/token/`, form_data);
  return response.data.success;
};
export default is_auth;
