import React, { useState, useEffect } from "react";
import "./VarMultiImage.css";
import getImage from "functions/getImage";
import axios from "axios";
import { API } from "config";
import arrayMove from "array-move";
// import LinearProgress from "@material-ui/core/LinearProgress";

import { SortableContainer, SortableElement } from "react-sortable-hoc";

import DeleteIcon from "img/false.png";

const VarMultiImage = ({ product_id, gallery, setSelectedImg }) => {
  const [images, setImages] = useState([
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ]);
  //   console.log("use efect in multiimages", gallery);
  useEffect(() => {
    imagesFromApi(gallery);
  }, [gallery]);

  const imagesFromApi = (gallery) => {
    let i = 0;
    let imgCopy = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
    while (imgCopy[i]["src"] !== undefined) {
      i++;
    }
    if (gallery !== undefined) {
      //   console.log("don bak dunyaya", imgCopy, gallery);
      gallery.slice(0, 12).map((item, key) => {
        imgCopy[i].name = key;
        imgCopy[i].src = item.src;
        imgCopy[i].id = item.id;
        imgCopy[i].rank = item.rank;
        imgCopy[i].ext = "jpg";
        i++;
      });
      if (i > 1) {
        settEditImage(imgCopy[i - 1]);
      }
    }
    setImages(imgCopy);
  };

  const [errorMsg, setErrorMsg] = useState("");
  // const [imagesHtml, setImagesHtml] = useState([]);
  // const [uploads, setUploads] = useState([]);
  const [webUrls, setWebUrls] = useState([{}]);
  const [tabPage, setTabPage] = useState(0);
  // const [loading, setLoading] = useState(false);
  const url = "";

  const uploadImage = async (image, i) => {
    const jwt = JSON.parse(localStorage.getItem("jwt"));
    let data =
      jwt !== null
        ? {
            method: "GET",
            headers: {
              // Accept: "application/json",
              // "Content-Type": "application/json",
              Authorization: `Token ${jwt.token}`,
            },
          }
        : {};
    let form_data = new FormData();
    form_data.append("image", image, image.name);
    //   form_data.append("name", "test");
    //   form_data.append("active", "test");
    var URL_PUT = `${API}/products/add/image/${product_id}/`;
    console.log("************************************************");
    const change = await axios.post(
      URL_PUT,
      form_data,
      {
        onUploadProgress: (ProgressEvent) => {
          console.log(
            i,
            image.name,
            "upload Progress: ",
            Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100),
            "%"
          );
          // let progress = Math.round(
          //     (ProgressEvent.loaded / ProgressEvent.total) * 100
          // );

          // document.querySelectorAll(".imageItemDrag")[i].innerHTML = (
          //     <LinearProgress value={progress} />
          // );
        },
      },
      data
    );
    // {API}/product/delete/image/{prod_id}/{image_id}
    console.log("-----------------------------------------------");
    console.log("change", change);
    imagesFromApi(change.data);
    // setSuccessShow(true);
  };
  const uploadUrlImages = async (url) => {
    const jwt = JSON.parse(localStorage.getItem("jwt"));
    let data =
      jwt !== null
        ? {
            method: "GET",
            headers: {
              // Accept: "application/json",
              // "Content-Type": "application/json",
              Authorization: `Token ${jwt.token}`,
            },
          }
        : {};
    // let form_data = new FormData();
    // form_data.append("image", image, image.name);
    //   form_data.append("name", "test");
    //   form_data.append("active", "test");
    var URL_PUT = `${API}/products/add/image/${product_id}/`;
    console.log("************************************************");
    // setLoading(true);
    const change = await axios.post(
      URL_PUT,
      { url },
      {
        onUploadProgress: (ProgressEvent) => {
          //   console.log(
          //     i,
          //     image.name,
          //     "upload Progress: ",
          //     Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100),
          //     "%"
          //   );
        },
      },
      data
    );
    // setLoading(false);
    console.log("-----------------------------------------------");
    console.log("change", change);
    imagesFromApi(change.data);
    // setSuccessShow(true);
  };

  const settEditImage = (item) => {
    console.log("daglarim--------------", item);
    setSelectedImg(item);
    document.querySelector(".addImageDiv").classList.add("d-none");
    document.querySelector(".emppty").classList.remove("d-none");
    document.querySelector(".editImage").classList.remove("d-none");
    // document
    //   .querySelectorAll(".editImage")
    //   [item.rank - 1].classList.add("selectedImg");
    document.querySelector(".editImage img").setAttribute("src", item.src);
  };

  const deleteElement = async (id) => {
    const jwt = JSON.parse(localStorage.getItem("jwt"));
    const token = jwt.token;
    const header =
      jwt !== null
        ? {
            Authorization: `Token ${token}`,
          }
        : {};
    let URL = `${API}/products/delete/image/${product_id}/${id}`;
    const newGallery = await axios.delete(URL, {
      headers: header,
    });
    imagesFromApi(newGallery.data.result);
  };

  const SortableItem = SortableElement(({ item }) => {
    // console.log('siz yine de', item);
    return (
      <li className="imageItemDrag col-4" onClick={() => settEditImage(item)}>
        {/* <i
                    className="fa fa-times-circle deleteItemDrag"
                    onClick={() => deleteElement(item.id)}
                ></i> */}
        <img
          src={DeleteIcon}
          onClick={() => deleteElement(item.id)}
          className="deleteItemDrag"
          alt=""
        ></img>
        <img src={item.src} className="uploadedImage" alt="" />
        {/* <button className="clickElement"></button> */}
      </li>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    let eklendi = false;
    let html = [];
    items.map((item, key) => {
      if (item.ext === undefined) {
        if (!eklendi) {
          html = [
            ...html,

            <li key={key} className="imageItemDrag addBtnTest col-4">
              <label>
                <i className="fa fa-plus"></i>
                <br></br>
                Add Photos
                <input
                  type="file"
                  multiple
                  hidden
                  accept="image/*"
                  onChange={(e) => saveImage(e)}
                />
              </label>
            </li>,
          ];
          eklendi = true;
        } else {
          html = [
            ...html,
            <li key={key} className="imageItemDrag col-4">
              <img src={getImage("")} className="uploadedImage" alt="" />
            </li>,
          ];
        }
      } else {
        html = [...html, <SortableItem key={key} index={key} item={item} />];
      }
    });

    return <ul>{html}</ul>;
  });

  const changeTabPage = (tabIndex) => {
    tabIndex === 0 && setWebUrls([{}]);
    setTabPage(tabIndex);
  };

  const addWebUrls = () => {
    // setWebUrls([...webUrls, {}]);
    setWebUrls([...webUrls, { url: url }]);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    console.log("depreston", oldIndex, newIndex);
    // setImages(({ images }) => ({
    //     items: arrayMove(items, oldIndex, newIndex),
    // }));

    const items = arrayMove(images, oldIndex, newIndex);

    setImages(items);
    const jwt = JSON.parse(localStorage.getItem("jwt"));
    let data =
      jwt !== null
        ? {
            method: "POST",
            headers: {
              // Accept: "application/json",
              // "Content-Type": "application/json",
              Authorization: `Token ${jwt.token}`,
            },
          }
        : {};
    let form_data = new FormData();
    form_data.append("images", JSON.stringify(items));
    //   form_data.append("name", "test");
    //   form_data.append("active", "test");
    var URL_PUT = `${API}/products/images/sort/${product_id}/`;
    // const change = axios.post(URL_PUT, form_data, {}, data);
    const change = axios.post(URL_PUT, { items }, data);
    console.log("*****", change);
  };

  const saveImage = async (e) => {
    let input = e.target;
    let url = input.value;
    let i = 0;
    let imgCopy = [...images];
    while (images[i]["src"] !== undefined) {
      i++;
    }
    Object.keys(input.files).forEach((ind) => {
      let prodImage = {};
      prodImage.ext = url.substring(url.lastIndexOf(".") + 1).toLowerCase();
      if (
        input.files[ind] &&
        (prodImage.ext === "gif" ||
          prodImage.ext === "png" ||
          prodImage.ext === "jpeg" ||
          prodImage.ext === "jpg")
      ) {
        prodImage.name = input.files[ind].name;
        console.log("************", input.files[ind].name);
        console.log("---------------------", input.files[ind]);

        var reader = new FileReader();
        prodImage.src = URL.createObjectURL(input.files[ind]);
        reader.onload = function (e) {
          // prodImage.src = e.target.result;
          // document
          //     .querySelectorAll('.uploadedImage')[0]
          //     .setAttribute('src', e.target.result);
        };
        // reader.readAsDataURL(input.files[0]);
      }
      if (i < images.length) {
        prodImage.index = i;
        imgCopy[i] = prodImage;
        i += 1;
        uploadImage(input.files[ind], i);
      } else {
        setErrorMsg(
          "! You have exceeded the total amount of photos allowed. Only the first 12 photos have been uploaded."
        );
      }
    });
    if (i > 0) {
      settEditImage(imgCopy[i - 1]);
    }

    console.log("eventt", e, e.target, e.target.value, e.target.files[0]);
    // setImages(imgCopy);
    // getImagesLi();

    // uploadImage('logo', e.target.files[0]);
  };

  // const getImagesLi = () => {
  //   let eklendi = false;
  //   let html = [];
  //   images.map((item, key) => {
  //     if (item.ext === undefined) {
  //       if (!eklendi) {
  //         html = [
  //           ...html,

  //           <li key={key} className="imageItem addBtnTest col-4">
  //             <label>
  //               <i className="fa fa-plus"></i>
  //               <br></br>
  //               Add Photos
  //               <input
  //                 type="file"
  //                 multiple
  //                 hidden
  //                 accept="image/*"
  //                 onChange={(e) => saveImage(e)}
  //               />
  //             </label>
  //           </li>,
  //         ];
  //         eklendi = true;
  //       } else {
  //         html = [
  //           ...html,
  //           <li key={key} className="imageItem col-4">
  //             <img src={getImage("")} className="uploadedImage" />
  //           </li>,
  //         ];
  //       }
  //     } else {
  //       html = [
  //         ...html,
  //         <li key={key} className="imageItem col-4">
  //           <img src={item.src} className="uploadedImage" />
  //         </li>,
  //       ];
  //     }
  //   });
  //   console.log("gece yarisi", html);
  //   // return html;
  //   // document.querySelector('.allImagesDiv ul').innerHTML = html;
  //   setImagesHtml(html);
  // };

  const deleteAll = () => {
    setImages([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);

    document.querySelector(".emppty").classList.add("d-none");
    document.querySelector(".addImageDiv").classList.remove("d-none");
    document.querySelector(".editImage img").removeAttribute("src");
    document.querySelector(".editImage").classList.add("d-none");
  };

  // useEffect(() => {
  //     getImagesLi();
  //     console.log('gelen', images);
  // }, [images]);

  // useEffect(() => {
  //   console.log("gelen2", imagesHtml);
  // }, [imagesHtml]);
  const handleOnChangeUrl = (event, key) => {
    // console.log(event.target.value);
    let webCopy = [...webUrls];
    webCopy[key].url = event.target.value;
    setWebUrls(webCopy);
    // setUrl(event.target.value);
  };
  const handleOnClickImport = () => {
    changeTabPage(0);
    webUrls.forEach((item) => {
      uploadUrlImages(item.url);
    });
    setWebUrls([{}]);
    // uploadUrlImages();
  };
  //   console.log("webUrls", webUrls);
  return (
    <React.Fragment>
      <div className="multiImageContainer ">
        <div className={`firstTab ${tabPage === 1 ? "d-none" : ""}`}>
          <div className="topText">
            <span className="float-left">Add up to 12 more photos</span>
            <div className="float-right colorText">
              <span onClick={deleteAll}>Delete All </span>
              <span className="seperator">|</span>
              <span onClick={() => changeTabPage(1)}>Import from web</span>
            </div>
          </div>
          <div className="multiImageDiv ">
            <div className="emppty d-none"></div>
            <div className=" editImage d-none">
              <img src={getImage("")} alt=""></img>
              <div className="editOptionsDiv">
                <div className="float-left">
                  <i className="fa fa-rotate-right"></i>
                  <i className="fa fa-rotate-left"></i>
                  <i className="fa fa-crop"></i>
                </div>
                <div className="float-right">
                  <i className="fa fa-floppy-o"></i>
                </div>
              </div>
            </div>
            <div className="addImageDiv">
              <label className="addImageBtn btn ">
                Add photos
                <input
                  type="file"
                  multiple
                  onChange={(e) => saveImage(e)}
                  accept="image/*"
                  hidden
                />
              </label>

              <p className="mt-3">
                Add up to 12 photos. We don't allow photos with extra borders,
                text or artwork.
              </p>
              <span className="mt-3">
                You can also{" "}
                <span className="colorText" onClick={() => changeTabPage(1)}>
                  copy your photos from a web address.
                </span>
              </span>
            </div>
            <div className="allImagesDiv row mx-0">
              <SortableList
                distance={1}
                items={images}
                onSortEnd={onSortEnd}
                axis="xy"
              />

              {/* <ul>{imagesHtml}</ul> */}
              {/* <li className="imageItem addBtnTest col-4">
                                    <label>
                                        <i className="fa fa-plus"></i>
                                        <br></br>
                                        Add Photos
                                        <input
                                            type="file"
                                            hidden
                                            accept="image/*"
                                            onChange={(e) => saveImage(e)}
                                        />
                                    </label>
                                </li>
                                {images.map((item, key) => (
                                    <li className="imageItem col-4" key={key}>
                                        <img
                                            src={getImage('')}
                                            className="uploadedImage"
                                        />
                                    </li>
                                ))} */}
            </div>
          </div>
        </div>
        <div className={`secondTab ${tabPage === 0 ? "d-none" : ""}`}>
          <div className="topText p-3">Copy your photos from web address:</div>
          <div className="webImageDiv">
            <div className="webUrls">
              {webUrls.map((item, key) => (
                <div key={key} className="form-group">
                  <label className="">{key + 1}.</label>
                  <input
                    type="text"
                    placeholder="Enter the URL where your photo is located"
                    onChange={(e) => handleOnChangeUrl(e, key)}
                  ></input>
                </div>
              ))}
            </div>
            <label className="colorText addWebUrlDiv" onClick={addWebUrls}>
              <i className="fa fa-plus mr-2"> </i> Add another
            </label>
            <br></br>
            <div className="btn btn-primary mr-5" onClick={handleOnClickImport}>
              Import
            </div>
            <div className="btn  colorText" onClick={() => changeTabPage(0)}>
              Cancel
            </div>

            <p className="mt-2">
              Add up to 12 photos. We don't allow photos with extra borders,
              text or artwork.
            </p>
          </div>
        </div>
        {errorMsg !== "" && <p className="errorMessage">{errorMsg}</p>}
      </div>
    </React.Fragment>
  );
};

export default VarMultiImage;
