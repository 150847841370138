import React, { useState, useEffect } from "react";
import "./Popup.css";
import { Button, Modal } from "react-bootstrap";
import ImagePopup from "../ImagePopup/ImagePopup";
import getImage from "functions/getImage";
const Popup = ({
  show,
  handleClose,
  product,
  cart,
  defaultCart,
  changeDefaultCart,
  changeQuantity,
  editJson,
}) => {
  const [txtArea, setTxtArea] = useState("");
  const [btnText, setBtnText] = useState("");
  //arrayleri ayır, hasownproperty ile bişey yap
  const [selectedOptionsCount, setSelectedOptionsCount] = useState({});
  const [selectedOptionsArr, setSelectedOptionsArr] = useState({});
  const [optionns, setOptionns] = useState([]);

  const [showImgPopup, setShowImgPopup] = useState(false);
  // const [arr, setArr] = useState([]);

  // const [yeniObj, setYeniObj] = useState({}) ;

  useEffect(() => {
    // console.log("????", product);
    // setArr([]);
    // setYeniObj([]);
  }, [product]);

  useEffect(() => {
    // console.log("????2222", editJson);
    setBtnText(editJson.name !== undefined ? "OK" : "Add to cart");
    setTxtArea(editJson.comment !== undefined ? editJson.comment : "");

    setOptionns(editJson.options !== undefined ? editJson.options : []);
    setSelectedOptionsArr(
      editJson.data_options !== undefined ? editJson.data_options : {}
    );
    // setSelectedOptions({ count: {}, arr: editJson.data_options });
  }, [editJson]);

  const handleAddToCart = () => {
    let jsonArr = {
      id: product.id,
      name: product.name,
      images: product.images,
      extended_options: [],
      code: "11NhHI",
      comment: txtArea !== undefined ? txtArea : "",
      data_options: selectedOptionsArr,
      options: optionns,
      quantity: defaultCart,
      price: product.price,
      ingredients: product.ingredients,
      balance: 0,
      category_id: product.category_id,
      extended_data_options: {},
    };

    // if (product.extras !== undefined) {
    //     product.extras.map((extra, key1)= (
    //         extra.options((option,key2) => {
    //             option.map((subOption, key3)){
    //                 if(subOption.id)
    //             }
    //         })
    //     ))
    // } else {
    //     jsonArr.options = [];
    // }

    setSelectedOptionsCount({});
    setSelectedOptionsArr({});
    setTxtArea("");
    setOptionns([]);
    changeQuantity(jsonArr, defaultCart);
  };

  const getChoose = (itemOption) => {
    if (itemOption.min === 1) {
      if (itemOption.max === 1) {
        // setInputType("radio");
        return "Choose one";
      } else {
        // setInputType("checkbox");
        return `min ${itemOption.min} / max ${itemOption.max}`;
      }
    } else if (itemOption.min === 0) {
      // setInputType("checkbox");
      return `max ${itemOption.max}`;
    }
  };
  const getInputType = (itemOption) => {
    if (itemOption.min === 1) {
      if (itemOption.max === 1) {
        return "radio";
      } else {
        return "checkbox";
      }
    } else if (itemOption.min === 0) {
      return "checkbox";
    }
  };
  const checkboxHandler = (extraKey, itemOption, itemSubOption, event) => {
    let objCount = selectedOptionsCount;
    let objArr = selectedOptionsArr;

    let extraOp = [...product.extras];
    let arr = optionns.length > 0 ? optionns : [];

    let filterValue =
      arr.filter((item) => item.id === itemOption.id).length > 0
        ? arr.filter((item) => item.id === itemOption.id)[0]
        : undefined;
    let yeni = filterValue !== undefined ? filterValue.suboptions : [];

    if (itemOption.id > 51) {
      //size ı belirten option id 51, onda sıkıntı olmayacak
      if (objCount[itemOption.id] === undefined) {
        objCount[itemOption.id] = 0;
      }

      if (!event.target.checked) {
        objCount[itemOption.id] = parseInt(objCount[itemOption.id]) - 1;
        delete objArr[itemSubOption.id]; //objeden eleman siliyor, ilginc komut

        yeni.splice(
          yeni.findIndex((item) => item.id === itemSubOption.id),
          1
        );
      }

      if (parseInt(objCount[itemOption.id]) < itemOption.max) {
        if (event.target.checked) {
          objCount[itemOption.id] = parseInt(objCount[itemOption.id]) + 1;
          objArr[itemSubOption.id] = true;

          let yeniSub = {};
          yeniSub.id = itemSubOption.id;
          yeniSub.name = itemSubOption.name;
          yeniSub.price = itemSubOption.price;
          yeni = [...yeni, yeniSub];
        }
      } else {
        event.preventDefault();
      }

      setSelectedOptionsCount(objCount);
      setSelectedOptionsArr(objArr);
    } else {
      yeni.splice(
        yeni.findIndex((item) => item.id === itemSubOption.id),
        1
      );

      extraOp[extraKey]["options"]
        .filter((item) => item.id === itemOption.id)[0]
        ["suboptions"].forEach((subOption) => {
          if (subOption.id !== itemSubOption.id) {
            delete objArr[subOption.id];
          } else {
            let yeniSub = {};
            yeniSub.id = subOption.id;
            yeniSub.name = subOption.name;
            yeniSub.price = subOption.price;
            yeni = [...yeni, yeniSub];
            objArr[itemSubOption.id] = true;
          }
        });
    }

    let yeniObj = filterValue !== undefined ? filterValue : {};
    yeniObj.id = itemOption.id;
    yeniObj.name = itemOption.name;
    yeniObj.suboptions = yeni;
    if (filterValue === undefined) {
      arr = [...arr, yeniObj];
    }
    console.log("last", arr);
    setOptionns(arr);
  };

  const getChecked = (itemOption, itemSubOption) => {
    console.log("dbdbdıpdıp", editJson.data_options);
    if (editJson.name !== undefined && editJson.options.length > 0) {
      console.log("girdin mi");
      if (itemSubOption.id in editJson.data_options) {
        return true;
      }
    }
    return false;
  };

  const handleOnceClose = () => {
    if (editJson === {}) {
      setSelectedOptionsCount({});
      setSelectedOptionsArr({});
      setTxtArea("");
      setOptionns([]);
    }

    handleClose();
  };

  return (
    <Modal show={show} onHide={handleOnceClose} animation={false} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: "green" }}>{product.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {product.images !== undefined && (
          <div className="photoGallery">
            <h4 className="mb-1">Photo Gallery</h4>
            <img
              src={getImage(product.images)}
              alt={product.name}
              width="100"
              onClick={() => setShowImgPopup(true)}
            />
            <ImagePopup
              show={showImgPopup}
              handleClose={() => setShowImgPopup(false)}
              imgSrc={getImage(product.images)}
              alt={product.name}
            />
          </div>
        )}

        {product.name !== undefined &&
          product.extras !== undefined &&
          product.extras.map((itemExtra, key1) => {
            return (
              <div key={key1}>
                {/* <h3>{itemExtra.name}</h3> */}

                {itemExtra.options.map((itemOption, key2) => {
                  return (
                    <div key={key2} className=" mb-3">
                      <h5>
                        {itemOption.name} ( {getChoose(itemOption)} )
                      </h5>
                      {itemOption.suboptions.map((itemSubOption, key3) => (
                        <div
                          key={key3}
                          className="form-group inputBoxes"
                          style={
                            getInputType(itemOption) === "radio"
                              ? {
                                  borderBottom: "0.5px solid #bbb",
                                }
                              : {
                                  border: "none",
                                }
                          }
                        >
                          <input
                            type={getInputType(itemOption)}
                            name={itemOption.name}
                            id={itemSubOption.id}
                            value={itemSubOption.id}
                            // checked={
                            //     `${itemSubOption.id}` in
                            //     selectedOptions[
                            //         "arr"
                            //     ]
                            //         ? "checked"
                            //         : "false"
                            // }
                            defaultChecked={getChecked(
                              itemOption,
                              itemSubOption
                            )}
                            onClick={(e) =>
                              checkboxHandler(
                                key1,
                                itemOption,
                                itemSubOption,
                                e
                              )
                            }
                            className="form-control"
                          />
                          <label htmlFor={itemSubOption.id}>
                            {itemSubOption.name}
                            <span className="float-right">
                              {itemSubOption.price > 0 &&
                                `+ ${itemSubOption.price.toFixed(2)} €`}
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            );
          })}
        <textarea
          className="form-control w-100 orderTextarea"
          placeholder="Add special instruction"
          onChange={(e) => setTxtArea(e.target.value)}
          value={txtArea}
        ></textarea>
      </Modal.Body>
      <Modal.Footer className="d-inline-block">
        <div className="changeQuantityDiv pull-left btn btn-lg">
          <div className="minusDiv" onClick={() => changeDefaultCart("-")}>
            <i className="fa fa-minus"></i>
          </div>
          <div className="plusDiv" onClick={() => changeDefaultCart("+")}>
            <i className="fa fa-plus"></i>
          </div>
        </div>
        <Button
          variant="success btn-lg addCartButton pull-right"
          onClick={() => handleAddToCart()}
        >
          <span className="quantText">{defaultCart}</span>
          <span className="btnText">{btnText}</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Popup;
