import React, { useState, useEffect } from "react";

import "./Signin.css";
import Layout from "../Layout/Layout";
import { Link } from "react-router-dom";

import { Redirect } from "react-router-dom";
import { signin, authenticate, isAuthenticated, confirmEmail } from "auth";
import { useHistory } from "react-router-dom";
import storeLogo from "img/logo-si.png";

const Signin = (props = undefined) => {
  const history = useHistory();
  const [values, setValues] = useState({
    email: "",
    password: "",
    error: "",
    loading: "",
    redirectToReferrer: false,
    site: "mango724",
  });
  const {
    email,
    password,
    loading,
    redirectToReferrer,
    error,
    success,
    site,
  } = values;
  const { user } = isAuthenticated();

  useEffect(() => {
    console.log("***** useEffect");
    if (props.match.params.key !== undefined) {
      console.log("**** confirmEmail ************************");
      confirmEmail(props.match.params.key).then((data) => {
        if (data.error) {
          setValues({ ...values, error: data.error, loading: false });
        } else {
          if (!isAuthenticated()) {
            setValues({
              ...values,
              success: `Your account was confirmed. You can login right now`,
            });
          } else {
            // const dataa = {
            //     token: '61ac24c1f7e4277f36916ac045047a1aa5077081',
            //     user: {
            //         role: '2',
            //         id: 46,
            //         email: 'mrtgnc03@gmail.com',
            //         name: 'fgdgdf',
            //         active: 1,
            //     },
            // };

            if (data.user.email === user.email) {
              //Eger birisi baskasinin mailine erisirse activated linkine tıklar ve o başkasının hesabı açılırdı
              console.log("Muhamed Sezairi and Varol Fuşa");
              localStorage.setItem("jwt", JSON.stringify(data));

              // jwt ya kaydetme işlemini böyle yapmamız lazım, ama active olunca djangoadn array gelmesi lazım
              // authenticate((data) => {
              //     setValues({
              //         ...values,
              //         redirectToReferrer: true,
              //     });
              // });
            }

            console.log("kentsel do", data.user.telmed);
            if (data.user.telmed !== undefined) {
              history.push("/user/telmed");
            }
            console.log("kentsel do", data.user.telmed);
            history.push("/user/dashboard"); //history iceriye
          }
        }
      });
    }
  }, [props, history, user, values]); //props vardi, ama degisince calistiracagimiz bisey degil bu
  //2 parametre aliyor, values[name]'i guncelliyor
  const handleChange = (name) => (e) => {
    setValues({ ...values, error: false, [name]: e.target.value });
    // console.log("handleChange", name, e.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    console.log("***** buraya da geliyor");
    setValues({ ...values, error: false, loading: true });
    signin({ email, password, site }).then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error, loading: false });
      } else {
        authenticate(data, () => {
          setValues({
            ...values,
            redirectToReferrer: true,
          });
        });
      }
    });
    // console.log(values);
  };

  const signInForm = () => {
    return (
      <form onSubmit={submitHandler}>
        {showLoading()}
        {showSuccess()}
        {showError()}

        <div className="form-group">
          <label htmlFor="email">Email address</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={email}
            onChange={handleChange("email")}
            aria-describedby="emailHelp"
            placeholder="Enter email"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            className="form-control"
            onChange={handleChange("password")}
            id="password"
            value={password}
            name="password"
            placeholder="Password"
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          LOGIN
        </button>
      </form>
    );
  };

  const showSuccess = () => (
    <div
      className="alert alert-info"
      style={{ display: success ? "" : "none" }}
    >
      {success}
    </div>
  );

  const showError = () => (
    <div
      className="alert alert-danger"
      style={{ display: error ? "" : "none" }}
    >
      {error}
    </div>
  );

  const redirectUser = () => {
    if (redirectToReferrer) {
      if (user && user.telmed !== undefined) {
        return <Redirect to="/user/telmed" />;
      }
      if (user && user.role === 1) {
        return <Redirect to="/admin/dashboard" />;
      } else {
        return <Redirect to="/user/dashboard" />;
      }
    }
    if (isAuthenticated()) {
      return <Redirect to="/" />;
    }
  };

  const showLoading = () =>
    loading && (
      <div className="alert alert-info">
        <h2>Loading...</h2>
      </div>
    );

  return (
    <Layout
      title="Signin"
      description="Signin"
      className="container col-md-8 offset-md-2"
    >
      <div className="signupDiv  ">
        {/* <h2 className="text-center mt-3">Store</h2> */}
        <img
          style={{ width: "40%" }}
          src={storeLogo}
          className="imgmango"
          alt="mango"
        />
        {/* <h3 className="text-center">Login</h3> */}
        <h6 className="mt-3 text-center">
          New on Store tele724?
          <Link to="/sign-up" className="ml-1">
            Create an account
          </Link>
        </h6>
        {signInForm()}

        {redirectUser()}
      </div>
    </Layout>
  );
};

export default Signin;
