import React, { useState, useEffect, useMemo } from "react";
import Modal from "react-modal";
import CancelIcon from "@material-ui/icons/Cancel";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import WarehouseTable from "components/StorePage/Product/Table/WarehouseTable";
import { API } from "../../config";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
}));
function VarWarehousePopup({ show, handleClose, originalVar, setData }) {
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
        },
    };
    const columns = useMemo(
        () => [
            // {
            //     Header: "#",
            //     id: "row",
            //     Cell: (rowInfo) => (
            //         <React.Fragment>
            //             <b>{parseInt(rowInfo.row.id) + 1}</b>
            //         </React.Fragment>
            //     ),
            // },
            {
                Header: "Name",
                Cell: (rowInfo) => (
                    <React.Fragment>
                        <b>{rowInfo.cell.row.original.name}</b>
                    </React.Fragment>
                ),
            },
            {
                Header: "Count",
                accessor: "count",
            },
        ],
        []
    );
    // const [modalIsOpen, setIsOpen] = useState(true);

    const classes = useStyles();
    console.log("gggg", originalVar.id, originalVar.warehouses);
    const [skipPageReset, setSkipPageReset] = useState(false);
    const [warehousesData, setWarehousesData] = useState([]);
    useEffect(() => {
        if (originalVar.warehouses !== undefined) {
            setWarehousesData(originalVar.warehouses);
        }
    }, [originalVar]);

    console.log("dd", warehousesData);
    const updateMyDataWh = (rowIndex, columnId, value) => {
        console.log("44", rowIndex, columnId, value);
        setSkipPageReset(true);
        setWarehousesData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            })
        );
    };
    const updateCountWh = async (original, value) => {
        console.log("##", original, value);
        const jwt = JSON.parse(localStorage.getItem("jwt"));
        let dataHeader =
            jwt !== null
                ? {
                      method: "GET",
                      headers: {
                          Authorization: `Token ${jwt.token}`,
                      },
                  }
                : {};
        let form_data = new FormData();
        form_data.append("count", value);
        form_data.append("depo_id", original.id);
        form_data.append("var_id", originalVar.id);
        const update = await axios.post(
            `${API}/products/update/warehouse/count/`,
            form_data,
            dataHeader
        );
        console.log("update", update.data);
        setData(update.data);
    };
    return (
        <div>
            <Modal
                isOpen={show}
                onRequestClose={handleClose}
                style={customStyles}
                id="warePopup"
                contentLabel="Warehouses"
                ariaHideApp={false}
            >
                <h2 className="text-center my-3">Warehouses</h2>
                <div className="closeBtn" onClick={handleClose}>
                    <i className="fa fa-times"></i>
                </div>
                {warehousesData.length > 0 && (
                    <WarehouseTable
                        columns={columns}
                        data={warehousesData}
                        updateMyDataWh={updateMyDataWh}
                        skipPageReset={skipPageReset}
                        updateCountWh={updateCountWh}
                    />
                )}

                <div className="row justify-content-center">
                    <Button
                        variant="contained"
                        className={`${classes.button} col-6`}
                        startIcon={<CancelIcon />}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>
        </div>
    );
}
export default VarWarehousePopup;
