import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PrivateRoute from "./auth/PrivateRoute";
// import Signup from "./components/Signup/Signup";
import Signin from "./components/Signin/Signin";

import Dashboard from "./user/Dashboard";
import Telmed from "./user/Telmed/Telmed";
import SignupBusiness from "./components/SignupBusiness/SignupBusiness";

import App from "./App";
import StorePage from "components/StorePage/StorePage";
import NewProduct from "pages/NewProduct/NewProduct";
import UpdateProduct from "pages/UpdateProduct/UpdateProduct";
// import ImgUpload from "pages/ImgUpload/ImgUpload";
import MultiImage from "components/MultiImages/MultiImages";
import ProductVariations from "pages/ProductVariations/ProductVariations";
import TelmedProductVariations from "pages/ProductVariations/TelmedProductVariations";
import Orders from "pages/Orders/Orders";
import DragPage from "pages/DragPage/DragPage";
import Test from "pages/Test/Test";
// import Home from "./core/Home";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Signin} />
        <Route path="/login" exact component={Signin} />
        <Route path="/varol" exact component={MultiImage} />
        <Route path="/varol2" exact component={DragPage} />
        <Route path="/sign-up" exact component={SignupBusiness} />
        <Route path="/test" exact component={Test} />
        <PrivateRoute path="/:storeSlug/orders" exact component={Orders} />
        <PrivateRoute
          path="/update_product/:productSlug"
          exact
          component={UpdateProduct}
        />
        <PrivateRoute
          path="/user/telmed/variations/:productSlug"
          exact
          component={TelmedProductVariations}
        />
        <PrivateRoute
          path="/variations/:productSlug"
          exact
          component={ProductVariations}
        />

        <PrivateRoute path="/user/dashboard" exact component={Dashboard} />
        <PrivateRoute path="/user/dashboard2" exact component={Dashboard} />
        <PrivateRoute
          path="/user/dashboard/address"
          exact
          component={Dashboard}
        />
        <PrivateRoute
          path="/user/dashboard/store"
          exact
          component={Dashboard}
        />
        <PrivateRoute
          path="/user/dashboard/address/:id"
          exact
          component={Dashboard}
        />
        <PrivateRoute
          path="/user/dashboard/address/create"
          exact
          component={Dashboard}
        />
        <PrivateRoute
          path="/user/dashboard/add_store"
          exact
          component={Dashboard}
        />
        <PrivateRoute
          path="/user/dashboard/cards/create"
          exact
          component={Dashboard}
        />
        <PrivateRoute path="/user/telmed" exact component={Telmed} />
        {/* <Route path="/store/:storeId" exact component={StorePage} /> */}
        <PrivateRoute path="/:storeSlug" exact component={StorePage} />
        <PrivateRoute path="/product/:slug" exact component={NewProduct} />
        <Route path="/email/confirm/:key" exact component={Signin} />
        <Route render={() => <h1>Page not found</h1>} />

        {/*<AdminRoute
                    path="/admin/dashboard"
                    exact
                    component={AdminDashboard}
                />
                <AdminRoute
                    path="/create/category"
                    exact
                    component={AddCategory}
                />
                <AdminRoute
                    path="/create/product"
                    exact
                    component={AddProduct}
                />
                <Route path="/product/:productId" exact component={Product} />
                <Route path="/cart" exact component={Cart} />
                <AdminRoute path="/admin/orders" exact component={Orders} />
                <PrivateRoute
                    path="/profile/:userId"
                    exact
                    component={Profile}
                />
                <PrivateRoute
                    path="/admin/products"
                    exact
                    component={ManageProducts}
                />
                <AdminRoute
                    path="/admin/products"
                    exact
                    component={ManageProducts}
                /> */}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
