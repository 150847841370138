import React, { useState, useEffect } from "react";
// import ReactDOM from "react-dom";
// import { Tag, X } from "react-feather";
import axios from "axios";
import { API } from "config";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import "./Tags.css";

function Tags({ product_id }) {
  const [tags, setTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  useEffect(() => {
    // console.log("product_id", product_id);
    const getTags = () => {
      let jwt = JSON.parse(localStorage.getItem("jwt"));
      let data =
        jwt !== null
          ? {
              headers: {
                Authorization: `Token ${jwt.token}`,
              },
            }
          : {};
      // data = {};
      axios.defaults.withCredentials = true;
      axios.get(`${API}/tags/get/${product_id}/`, data).then((ress) => {
        // console.log("ressss", ress);
        if (ress.data === undefined) {
          // setErrorMessage(
          //     'The product you are looking for was not found'
          // );
          // console.log("Tags Error", ress);
        } else {
          // setProduct(ress.data.result);
          // setVariant(ress.data.result.variant);
          // setCount(ress.data.result.quantity)
          // console.log("Tags", ress.data);
          setTags(ress.data.tags);
          setSuggestions(ress.data.suggestions);
          // setSimilarProducts(ress.data.result);
        }
      });
    };
    if (product_id !== undefined) {
      getTags();
    }
  }, [product_id]);

  // console.log("tags", tags);

  const removeTag = (tag) => {
    // console.log("tag for remove", tag, product_id);
    let jwt = JSON.parse(localStorage.getItem("jwt"));
    let data =
      jwt !== null
        ? {
            headers: {
              Authorization: `Token ${jwt.token}`,
            },
          }
        : {};
    // data = {};
    let form_data = new FormData();
    form_data.append("tag", tag);
    axios.defaults.withCredentials = true;
    axios.post(`${API}/tags/remove/${product_id}/`, form_data, data);
  };
  const addTag = (tag) => {
    // console.log("tag for add", tag, product_id);
    let jwt = JSON.parse(localStorage.getItem("jwt"));
    let data =
      jwt !== null
        ? {
            headers: {
              Authorization: `Token ${jwt.token}`,
            },
          }
        : {};
    let form_data = new FormData();
    form_data.append("tag", tag);
    axios.defaults.withCredentials = true;
    axios.post(`${API}/tags/add/${product_id}/`, form_data, data);
  };
  const tagForRemove = (tags, newTags) => {
    for (let i = 0; i < tags.length; i++) {
      if (!newTags.includes(tags[i])) {
        return tags[i];
      }
    }
  };
  const tagForAdd = (tags, newTags) => {
    for (let i = 0; i < newTags.length; i++) {
      if (!tags.includes(newTags[i])) {
        return newTags[i];
      }
    }
  };
  const OnChangeTags = (newTags) => {
    if (tags.length < newTags.length) {
      addTag(tagForAdd(tags, newTags));
    } else {
      removeTag(tagForRemove(tags, newTags));
    }
    setTags(newTags);
  };
  const btnOnClick = (item, e) => {
    // console.log("btnOnClick", e, item);
    if (!tags.includes(item)) {
      setTags(tags.concat(item));
      addTag(item);
    }
  };
  return (
    <div>
      <h5>Tags</h5>
      <ReactTagInput
        tags={tags}
        placeholder="Type and press enter"
        maxTags={10}
        editable={true}
        readOnly={false}
        removeOnBackspace={true}
        onChange={(newTags) => OnChangeTags(newTags)}
      />
      {suggestions.length > 0 && (
        <>
          <b>Suggestions for tag:</b>
          <br></br>
        </>
      )}
      {suggestions.map((item, key) => (
        <React.Fragment key={key}>
          <span onClick={() => btnOnClick(item)} style={{ cursor: "pointer" }}>
            {item}
          </span>
          &nbsp;&nbsp;&nbsp;
        </React.Fragment>
      ))}
    </div>
  );
}
export default Tags;
