import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import getImage from "functions/getImage";
import DeleteIcon from "@material-ui/icons/Delete";
import DeletePopup from "../../../DeletePopup/DeletePopup";
import { Link } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import { API } from "config";
// import axios from "axios";
import "./ProductCard.css";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default function ProductCard({ item, delProduct, updateProduct }) {
  const classes = useStyles();
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [state, setState] = React.useState({
    active: item.active,
  });

  // const jwt = JSON.parse(localStorage.getItem("jwt"));
  // const token = jwt.token;
  // const updateProduct = async (id, active) => {
  //   //<int:pk>/update/store/
  //   console.log(id, active, token);
  //   const header = {
  //     Authorization: `Token ${token}`,
  //   };
  //   var URL = `${API}/${id}/update/store/`;
  //   let form_data = new FormData();

  //   form_data.append("active", active);
  //   // form_data.append("name", name);

  //   // const update = await axios.put(URL, form_data, {
  //   //   headers: header,
  //   // });
  // };
  function openDeleteModal() {
    setShowDeleteModel(true);
  }

  const deleteProduct = () => {
    delProduct(item.id);

    setShowDeleteModel(false);
  };
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    updateProduct(item.id, event.target.checked);
  };
  return (
    <Card className={classes.root}>
      <Link
        to={{
          pathname: `/update_product/${item.slug}`,

          product: item,
        }}
      >
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={getImage(item.image)}
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography
              className="kisaltmaClass"
              gutterBottom
              variant="h5"
              component="h2"
            >
              {item.name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              price: {item.price} €
            </Typography>
          </CardContent>
        </CardActionArea>
      </Link>
      <CardActions>
        {/* <Button size="small" color="primary">
          Share
        </Button> */}
        <FormControlLabel
          value="active"
          control={
            <Switch
              color="primary"
              name="active"
              onChange={handleChange}
              checked={state.active}
            />
          }
          label="Active"
          labelPlacement="end"
        />
        <Button
          size="small"
          color="secondary"
          className={classes.button}
          endIcon={<DeleteIcon />}
          onClick={openDeleteModal}
        >
          Delete
        </Button>
        <DeletePopup
          show={showDeleteModel}
          handleClose={() => setShowDeleteModel(false)}
          handleDelete={deleteProduct}
          product={item}
          text={"product"}
        />
      </CardActions>
    </Card>
  );
}
