import React, { useState, useEffect } from "react";
import Layout from "components/Layout/Layout";
import axios from "axios";
import { API } from "config";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";

const Test = (props) => {
  const [tags, setTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  useEffect(() => {
    const getTags = () => {
      let jwt = JSON.parse(localStorage.getItem("jwt"));
      let data =
        jwt !== null
          ? {
              headers: {
                Authorization: `Token ${jwt.token}`,
              },
            }
          : {};
      // data = {};
      axios.defaults.withCredentials = true;
      axios.get(`${API}/tags/get/58/8/`, data).then((ress) => {
        console.log("ressss", ress);
        if (ress.data === undefined) {
          // setErrorMessage(
          //     'The product you are looking for was not found'
          // );
          console.log("Tags Error", ress);
        } else {
          // setProduct(ress.data.result);
          // setVariant(ress.data.result.variant);
          // setCount(ress.data.result.quantity)
          console.log("Tags", ress.data);
          setTags(ress.data.tags);
          setSuggestions(ress.data.suggestions);
          // setSimilarProducts(ress.data.result);
        }
      });
    };
    getTags();
  }, []);

  console.log("tags", tags);
  const btnOnClick = (item, e) => {
    console.log("btnOnClick", e, item);
    if (!tags.includes(item)) {
      setTags(tags.concat(item));
    }
  };
  const itemForDelete = (tags, newTags) => {
    console.log("tags 1", tags);
    console.log("tags 2", newTags);
    for (let i = 0; i < tags.length; i++) {
      if (!newTags.includes(tags[i])) {
        return tags[i];
      }
    }
  };
  const OnChangeTags = (newTags) => {
    if (tags.length < newTags.length) {
      console.log("EKLEME", tags.length, newTags.length);
    } else {
      let forDelete = itemForDelete(tags, newTags);
      console.log("SILME", tags.length, newTags.length, forDelete);
    }
    setTags(newTags);
  };
  return (
    <Layout>
      <div className="container mt-4">
        <h5>Tags</h5>
        <ReactTagInput
          tags={tags}
          onChange={(newTags) => OnChangeTags(newTags)}
        />
        {suggestions.map((item, key) => (
          <React.Fragment key={key}>
            <span
              onClick={() => btnOnClick(item)}
              style={{ cursor: "pointer" }}
            >
              {item}
            </span>
            &nbsp;&nbsp;&nbsp;
          </React.Fragment>
        ))}
      </div>
    </Layout>
  );
};
export default Test;
