import React, { useState } from "react";

import "./StoreImages.css";
import ImgDropzone from "components/ImgDropzone/ImgDropzone";
import axios from "axios";
import { API } from "../../config";

const StoreImages = ({ storeInfos, setSuccessShow }) => {
  // <int:pk>/update/store/</int>;
  const jwt = JSON.parse(localStorage.getItem("jwt"));
  const token = jwt.token;
  const [backgroundImg, setBackgroundImg] = useState(storeInfos.header);
  const [logoImg, setlogoImg] = useState(storeInfos.logo);

  const updateImage = async (type_, image) => {
    let form_data = new FormData();
    form_data.append(type_, image, image.name);
    form_data.append("name", storeInfos.name);
    form_data.append("active", storeInfos.active);
    const header = {
      Authorization: `Token ${token}`,
    };
    var URL_PUT = `${API}/${storeInfos.id}/update/store/`;
    const change = await axios.put(URL_PUT, form_data, {
      headers: header,
    });
    console.log("change", change);
    setSuccessShow(true);
  };

  const onBackDrop = (acceptedFiles) => {
    setBackgroundImg(URL.createObjectURL(acceptedFiles[0]));
    console.log(acceptedFiles, "11111", acceptedFiles[0].name);
    updateImage("background", acceptedFiles[0]);
  };
  const onLogoDrop = (acceptedFiles) => {
    setlogoImg(URL.createObjectURL(acceptedFiles[0]));
    updateImage("logo", acceptedFiles[0]);
  };

  return (
    <ImgDropzone
      logoImg={logoImg}
      backgroundImg={backgroundImg}
      onLogoDrop={onLogoDrop}
      onBackDrop={onBackDrop}
    ></ImgDropzone>
    // <div className="storeImages" style={style}>
    //     {logo !== '' && (
    //         <div className="logoDiv">
    //             <img
    //                 className="logoImg"
    //                 src={getImage(logo)}
    //                 alt="Logo"
    //             ></img>
    //         </div>
    //     )}
    // </div>
  );
};

export default StoreImages;
