import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Layout from "components/Layout/Layout";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import CancelIcon from "@material-ui/icons/Cancel";
// import UpdateIcon from "@material-ui/icons/Update";
// import QueueTwoToneIcon from "@material-ui/icons/QueueTwoTone";
// import MultiImageInput from "react-multiple-image-input";
import TextareaAutosize from "react-textarea-autosize";
// import ImageDropzone from "../../components/ImageDropzone/ImageDropzone";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";
import Select from "react-select";
import { API } from "../../config";
import axios from "axios";
import SuccessMessage from "components/SuccessMessage/SuccessMessage";
import MomentUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "./UpdateProduct.css";
import { Link, Redirect } from "react-router-dom";
import MultiImage from "components/MultiImages/MultiImages";
import Tags from "components/Tags/Tags";
// const crop = {
//   unit: "%",
//   // aspect: 1 / 1,
//   width: "100",
// };
const UpdateProduct = (props) => {
  // sc-AxirZ sc-AxiKw lmMaya
  // const addImagesRef = useRef(null);
  // const maxNumber = 10;
  // const maxMbFileSize = 5 * 1024 * 1024; // 5Mb
  // var base64Img = require("base64-img");
  // const [variants, setVariants] = useState([]);
  const [categories, setCategories] = useState([]);
  const [measuereUnit, setMeasuereUnit] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedDateStart, setSelectedDateStart] = useState(new Date());
  const [selectedDateEnd, setSelectedDateEnd] = useState(new Date());
  // var dateFormat = require('dateformat');
  const [successShow, setSuccessShow] = useState(false);
  const [product, setProduct] = useState({});
  const [brands, setBrands] = useState([]);
  const [isLogin, setIsLogin] = useState(true);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const jwt = JSON.parse(localStorage.getItem("jwt"));
  // const token = jwt.token;
  // const header = {
  //   Authorization: `Token ${token}`,
  // };
  // console.log("dddddddd", props.match.params.productSlug);
  // console.log("props.location", props.location.product);
  useEffect(() => {
    if (jwt === null) {
      setIsLogin(false);
    } else {
      const token = jwt.token;
      const header = {
        Authorization: `Token ${token}`,
      };
      console.log("tokennnnnnnnn------------", token);
      var URL_GET_Category = `${API}/${department}/categories`;
      axios.get(URL_GET_Category).then((response) => {
        setCategories(response.data.result);
        // console.log("---*-category--*--", response.data.result);
      });
      var URL_GET_Product = `${API}/products/owner/details/${props.match.params.productSlug}`;
      axios
        .get(URL_GET_Product, {
          headers: header,
        })
        .then((response) => {
          setProduct(response.data.result);
          console.log("product ----", response.data.result);
        })
        .catch((error) => {
          let errObj = JSON.parse(JSON.stringify(error));
          console.log("-------------------------741", errObj);
          setIsLogin(false);
        });

      if (department === "techno") {
        var URL_GET_Brends = `${API}/products/brends`;
        axios.get(URL_GET_Brends).then((response) => {
          setBrands(response.data.result);
          // console.log("Brends", response.data.result);
        });
      }
      // const getVariants = async () => {
      //     const response = await axios.get(
      //         `${API}/products/variants/get`
      //     );
      //     // const body = await response.json();
      //     // const contacts = body.results;
      //     console.log('--------------------', response.data);
      //     setVariants(response.data);
      // };
      // getVariants();
      var URL_measuere_unit = `${API}/products/get/measuere_unit/`;
      axios
        .get(URL_measuere_unit, header)
        .then((response) => {
          console.log("measuere_unit", response.data.result);
          setMeasuereUnit(response.data.result);
          // setSelectedUnit(response.data.result[0]);
        })
        .catch((error) => {
          let errObj = JSON.parse(JSON.stringify(error));
          console.log("products/owner/measuere_unit---------741", errObj);
          // setIsLogin(false);
        });
    }
  }, []);
  const handleChangeUnit = (Unit) => {
    console.log("Unit", Unit);
    setSelectedUnit(Unit);
    // setJsonData({ ...jsonData, unit: Unit.value });
  };
  // const handleOnBlurUnit = (Unit) => {
  //   // setSelectedUnit(Unit);
  //   console.log("Unitmm", selectedUnit.value);
  //   updateProduct(jsonData);
  // };

  const history = useHistory();

  const department = localStorage.getItem("branch") || "";
  // const [product, setProduct] = useState(props.location.product);
  const [images, setImages] = useState({});

  const storeId = localStorage.getItem("id") || "";
  const link = localStorage.getItem("slug") || "";
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [percent, setPercent] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [state, setState] = useState({
    madeinKosova: false,
    haveVariation: false,
  });
  const [descriptionTitle, setDescriptionTitle] = useState("");
  const [selectedCategory, setSelectCategory] = useState();
  const [selectedSubCategory, setSelectSubCategory] = useState(null);
  const [selectedSubSubCategory, setSelectSubSubCategory] = useState(null);
  const [selectedSubSubSubCategory, setSelectSubSubSubCategory] = useState(
    null
  );
  const [gallery, setGallery] = useState([]);
  const [productVariants, setProductVariants] = useState([]);
  const [subSubSubCategories, setSubSubSubCategories] = useState([]);
  const [subSubCategories, setSubSubCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  useEffect(() => {
    setSelectCategory(product.category);
    setSelectSubCategory(product.sub_category);
    setSelectSubSubCategory(product.sub_sub_category);
    setSelectSubSubSubCategory(product.sub_sub_sub_category);
    setTimeout(function () {
      setImg(product.image);
    }, 1000);

    setPrice(product.price);
    setQuantity(product.quantity);
    setSelectedUnit(product.measure_unit);
    setGallery(product.gallery);
    setName(product.name);
    setCode(product.code);
    setDiscount(product.offer_price);
    setPercent(
      parseFloat(
        (100 * (parseFloat(product.price) - parseFloat(product.offer_price))) /
          parseFloat(product.price)
      )
    );
    setDescriptionTitle(product.descriptionTitle);
    setSelectedBrand(product.brand);
    if (product.madein_kosova !== undefined) {
      setState({ ...state, madeinKosova: product.madein_kosova });
    }

    // setState({ madeinKosova: true });
    // console.log(product.madein_kosova, "imagessss", product.gallery);
    // console.log("discont", product);
    if (product.discount !== undefined) {
      setSelectedDateStart(product.discount.start_date);
      setSelectedDateEnd(product.discount.end_date);
      // setDateStart(
      //   dateFormat(product.discount.start_date, "dd-mm-yyyy hh:MM:ss")
      // );
      // setDateEnd(dateFormat(product.discount.end_date, "dd-mm-yyyy hh:MM:ss"));
    }
    if (product.product_variants !== undefined) {
      // console.log("****//", product.product_variants);
      // product.product_variants.map((item, key) => console.log("dddddcc", item));
      setProductVariants(product.product_variants);
    }

    // if (product.images !== undefined) {
    //   base64Img.requestBase64(product.image, function (err, res, body) {
    //     console.log("err", err);
    //     console.log("res", res);
    //     // console.log("body", body);

    //   });
    // }
  }, [product]);

  useEffect(() => {
    if (
      categories.length > 0 &&
      selectedCategory !== null &&
      selectedCategory !== undefined
    ) {
      setSubCategories(
        categories.filter((item) => item.value === selectedCategory.value)[0]
          .sub_categories
      );
    }
  }, [selectedCategory, categories]);

  useEffect(() => {
    // console.log("selectedSubCategory", selectedSubCategory);
    if (subCategories.length > 0 && selectedSubCategory !== null) {
      setSubSubCategories(
        subCategories.filter(
          (item) => item.value === selectedSubCategory.value
        )[0].sub_sub_categories
      );
    }
    // console.log("subbbbbb", subSubCategories);
  }, [subCategories]);

  useEffect(() => {
    // console.log("subSubCategories", subSubCategories);
    if (subSubCategories.length > 0 && selectedSubSubCategory !== null) {
      setSubSubSubCategories(
        subSubCategories.filter(
          (item) => item.value === selectedSubSubCategory.value
        )[0].sub_sub_sub_categories
      );
    }
  }, [subSubCategories]);

  const [Img, setImg] = useState(null);
  //   const [imgStr, setImgStr] = useState("");
  const [jsonData, setJsonData] = useState({
    store: null,
    category: null,
    sub_category: null,
    sub_sub_category: null,
    sub_sub_sub_category: null,
    name: null,
    descriptionTitle: null,
    description: null,
    price: null,
    images: null,
    default_img: null,
    // madein_kosova: state.madeinKosova,
  });
  const [error, setError] = useState({
    category: false,
    discount: false,
    dateStartEnd: false,
  });

  const updateProduct = async (new_product) => {
    // const jwt = JSON.parse(localStorage.getItem("jwt"));
    const token = jwt.token;
    const header = {
      Authorization: `Token ${token}`,
    };
    var URL = `${API}/${product.id}/product/store/update`;
    // const updated = await axios.put(
    //   URL,
    //   { new_product },
    //   {
    //     headers: header,
    //   }
    // );
    axios
      .put(
        URL,
        { new_product },
        {
          headers: header,
        }
      )
      .then((res) => {
        console.log("res", res.status);
        console.log("res.data", res.data);
        setProduct(res.data.result);
      })
      .catch((error) => {
        let errObj = JSON.parse(JSON.stringify(error));
        console.log("-------------------------741", errObj);
        setIsLogin(false);
      });
    // history.push(link);
    setImages({});

    // setProduct(updated.data.result);
    setSuccessShow(true);
    // window.location.reload();
    // console.log("updated", updated.data);
    // console.log("hataaaa", Object.keys(newStore.data.errors));
    // if (newStore.data.error === false) {
    //   window.location.reload();
    // } else {
    //   let error2 = { ...error };
    //   let key = Object.keys(newStore.data.errors)[0];
    //   error2[key] = true;
    //   error2.emailMsg = newStore.data.errors.email;
    //   setError(error2);
    // }
  };
  useEffect(() => {
    if (successShow) {
      setTimeout(function () {
        setSuccessShow(false);
      }, 2000);
    }
  }, [successShow]);
  const handleChangeCategory = (selectedCategory) => {
    setSelectCategory(selectedCategory);
    if (selectedCategory) {
      setSubCategories(
        categories.filter((item) => item.id === selectedCategory.id)[0]
          .sub_categories
      );
    }

    console.log(`Category:`, selectedCategory);
    setSelectSubCategory(null);
    setSelectSubSubCategory(null);
    setSelectSubSubSubCategory(null);
  };
  const handleChangeSubCategory = (selectedSubCategory) => {
    setSelectSubCategory(selectedSubCategory);
    if (selectedSubCategory !== null) {
      setSubSubCategories(
        subCategories.filter((item) => item.id === selectedSubCategory.id)[0]
          .sub_sub_categories
      );
    }

    console.log(`SubCategory:`, selectedSubCategory);
    setSelectSubSubCategory(null);
    setSelectSubSubSubCategory(null);
  };
  const handleChangeSubSubCategory = (selectedSubSubCategory) => {
    setSelectSubSubCategory(selectedSubSubCategory);
    if (selectedSubSubCategory !== null) {
      setSubSubSubCategories(
        subSubCategories.filter(
          (item) => item.id === selectedSubSubCategory.id
        )[0].sub_sub_sub_categories
      );
    }

    console.log(`SubSubCategory:`, selectedSubSubCategory);
    setSelectSubSubSubCategory(null);
  };
  const handleChangeSubSubSubCategory = (selectedSubSubSubCategory) => {
    setSelectSubSubSubCategory(selectedSubSubSubCategory);
    console.log(`SubSubSubCategory:`, selectedSubSubSubCategory);
  };
  const handleChangeProductPrice = (event) => {
    setPrice(event.target.value.slice(0, -2));
  };
  const handleChangeProductDiscount = (event) => {
    setDiscount(event.target.value.slice(0, -2));
    if (parseInt(event.target.value.slice(0, -2)) > 0) {
      setPercent(
        (100 * (parseInt(price) - parseInt(event.target.value.slice(0, -2)))) /
          parseInt(price)
      );
    }
  };

  useEffect(() => {
    if (isNaN(percent)) {
      setDiscount(0);
    } else {
      setDiscount(
        parseFloat(
          parseFloat(price) - (parseFloat(price) / 100) * parseFloat(percent)
        ).toFixed(2)
      );
    }
    // console.log("selectedDateStart", selectedDateStart);
    if (selectedDateStart === undefined) {
      let today = new Date();
      setSelectedDateStart(today);
      setSelectedDateEnd(today);
      //   setDateEnd(dateFormat(today, "dd-mm-yyyy hh:MM:ss"));
      //   setDateStart(dateFormat(today, "dd-mm-yyyy hh:MM:ss"));
    }
  }, [percent]);

  useEffect(() => {
    if (isNaN(discount) && price > 0) {
      setPercent(0);
    } else {
      setPercent(
        parseFloat(
          ((parseFloat(price) - parseFloat(discount)) / parseFloat(price)) * 100
        ).toFixed(2)
      );
    }
  }, [discount]);

  const handleChangeProductQuantity = (event) => {
    setQuantity(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let jsonData2 = { ...jsonData };
    let error2 = { ...error };
    event.target.category.value === ""
      ? (error2.category = true)
      : (error2.category = false);

    if (parseInt(discount) > parseInt(price)) {
      error2.discount = true;
    } else {
      if (parseInt(discount) !== 0) {
        if (selectedDateStart >= selectedDateEnd) {
          console.log("ssss", selectedDateEnd, new Date());
          error2.dateStartEnd = true;
        } else {
          error2.dateStartEnd = false;
        }
      }
      error2.discount = false;
    }

    let success = true;
    setError(error2);
    console.log("error2", error2);
    for (var i in error2) {
      if (error2[i] === true) {
        success = false;
        break;
      }
    }

    if (success) {
      console.log("buraya geliyor mu acaba");
      jsonData2.store = storeId;
      jsonData2.name = event.target.name.value;
      if (event.target.brand !== undefined) {
        jsonData2.brand = event.target.brand.value;
      }
      jsonData2.descriptionTitle = event.target.descriptionTitle.value;
      jsonData2.description = event.target.description.value;
      jsonData2.price = event.target.price.value.slice(0, -2);
      jsonData2.category = event.target.category.value;
      jsonData2.name = event.target.name.value;
      jsonData2.quantity = quantity;
      jsonData2.measuere_unit = selectedUnit.value;
      jsonData2.images = images;
      //   jsonData2.default_img = imgStr;
      jsonData2.madein_kosova = state.madeinKosova;
      if (discount > 0) {
        jsonData2.discount = discount;
        jsonData2.percent = percent;
        jsonData2.discount_start = event.target.discountDateStart.value;
        jsonData2.discount_end = event.target.discountDateEnd.value;
      }

      // jsonData2.madein_kosova = state.madeinKosova;
      if (event.target.subCategory !== undefined) {
        jsonData2.sub_category = event.target.subCategory.value;
      } else {
        jsonData2.sub_category = "";
      }
      if (event.target.subSubCategory !== undefined) {
        jsonData2.sub_sub_category = event.target.subSubCategory.value;
      } else {
        jsonData.sub_sub_category = "";
      }
      if (event.target.subSubSubCategory !== undefined) {
        jsonData2.sub_sub_sub_category = event.target.subSubSubCategory.value;
      } else {
        jsonData2.sub_sub_sub_category = "";
      }
      // console.log("buraya geliyor");
      setJsonData(jsonData2);

      updateProduct(jsonData2);
    }
  };

  // const onImgDrop = (acceptedFiles) => {
  //   // console.log("imagne", acceptedFiles[0], "222");
  //   const myfileItemReader = new FileReader();
  //   myfileItemReader.addEventListener(
  //     "load",
  //     () => {
  //       // console.log("dddddddddddddddd", myfileItemReader.result);
  //       setImgStr(myfileItemReader.result);
  //     },
  //     false
  //   );
  //   myfileItemReader.readAsDataURL(acceptedFiles[0]);
  //   setImg(URL.createObjectURL(acceptedFiles[0]));
  // };
  // const handleChangeMadeinKosova = (event) => {
  //   setState({ [event.target.name]: event.target.checked });
  //   // console.log("nameeeeee", event.target.checked, event.target.name);
  //   // if (event.target.name !== "LimitProdQuantity") {
  //   //   // updateProduct(event.target.name, event.target.checked);
  //   // }
  // };

  // const onClickDelete = (id) => {
  //   console.log("onClickDelete", id);
  // };
  // const delImage = async (id) => {
  //   const token = jwt.token;
  //   const header = {
  //     Authorization: `Token ${token}`,
  //   };
  //   let URL = `${API}/products/delete/image/${product.id}/${id}`;
  //   const newGallery = await axios.delete(URL, {
  //     headers: header,
  //   });
  //   setGallery(newGallery.data.result);
  // };
  const handleChangeProductName = (event) => {
    // console.log("eventttt", event.target.value);
    setName(event.target.value);
  };
  const handleChangeProductCode = (event) => {
    // console.log("eventttt", event.target.value);
    setCode(event.target.value);
  };
  const handleChangeDescriptionTitle = (event) => {
    setDescriptionTitle(event.target.value);
  };
  const onClickBack = () => {
    history.push(`/${link}`);
    // console.log("onClickBack", link);
  };
  const handleChangeProductPercent = (event) => {
    setPercent(parseFloat(event.target.value.slice(0, -2)));
  };
  // const handleonBlurUpdateProductPercent = (event) => {
  //   updateDiscount();
  // };
  // const handleKeyPressProductPercent = (event) => {
  //   updateDiscount();
  // };
  const handleDateChangeStart = (event) => {
    setSelectedDateStart(event);
  };
  const handleDateChangeEnd = (event) => {
    setSelectedDateEnd(event);
  };
  const handleChangeBrand = (selectedBrand) => {
    setSelectedBrand(selectedBrand);

    // console.log(`selectedBrand:`, selectedBrand);
  };
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  // const financial = (x) => Number.parseFloat(x).toFixed(0);
  //   useEffect(() => {
  // console.log("change image");
  //   }, [Img]);

  //   const [showVariationPopup, setShowVariationPopup] = useState(false);

  return (
    <Layout>
      {!isLogin && (
        <Redirect
          to={{
            pathname: "/login",
            // state: { from: props.location },
          }}
        />
      )}
      <form onSubmit={handleSubmit} className="container">
        <div className="row">
          <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 mb-1">
            <legend className="text-center mt-3">New Product Draft</legend>
          </div>
          <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-1">
            <div className="row mt-3">
              <div className="col-sm">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={onClickBack}
                  className="mr-2"
                  // className={classes.button}
                  startIcon={<ArrowBackIcon />}
                >
                  Back
                </Button>
              </div>
              <div className="col-sm">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  type="submit"
                  // onClick={onClickBtnSave}
                  // className={classes.button}
                  // startIcon={<SaveIcon />}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 mb-1 row">
            {/* prvi */}
            <div className="titleContainer col-12 col-md-8">
              <span className="font-weight-bold">Name</span>
              <div className="titleDiv">
                <input
                  type="text"
                  value={name}
                  name="name"
                  className="inputName"
                  onChange={handleChangeProductName}
                  // onBlur={handleonBlurProductName}
                  // onKeyPress={handleKeyPress}
                />
              </div>
              {error.name && (
                <div className="w-100">
                  <span className="errorMsg">* Name should not be empty.</span>
                </div>
              )}
            </div>

            <div className="col-12 col-md-4 pt-3">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.madeinKosova}
                    onChange={handleChange}
                    // onBlur={handleBlurMadeinKosova}
                    name="madeinKosova"
                    color="primary"
                  />
                }
                label="Made in Kosova"
              />
            </div>
            <div className="titleContainer col-12 col-md-8">
              <span className="font-weight-bold">Code</span>
              <div className="titleDiv">
                <input
                  type="text"
                  value={code}
                  name="code"
                  className="inputName"
                  onChange={handleChangeProductCode}
                  // onBlur={handleonBlurProductCode}
                  // onKeyPress={handleKeyPress}
                />
              </div>
              {error.name && (
                <div className="w-100">
                  <span className="errorMsg">* Name should not be empty.</span>
                </div>
              )}
            </div>
            {brands.length > 0 && (
              <div className="my-2">
                <div className="col-12">
                  <span className="font-weight-bold">Product brand</span>
                </div>
                <div className="col-6">
                  <Select
                    value={selectedBrand}
                    onChange={handleChangeBrand}
                    // onBlur={handleOnBlurBrand}
                    options={brands}
                    name="brand"
                    placeholder="Select Brend"
                  />
                </div>
              </div>
            )}
            <div className="col-12 col-md-6">
              <div className="w-100 mb-1">
                <span className="font-weight-bold">Category</span>
              </div>
              {/* <div className="col-12 mb-1">
                <span className="font-weight-bold">Category</span>
              </div> */}
              <div className="w-100 mb-1">
                <Select
                  value={selectedCategory}
                  onChange={handleChangeCategory}
                  // onBlur={handleOnBlurCategory}
                  options={categories}
                  isClearable={true}
                  name="category"
                  placeholder="Category"
                />
              </div>
              <div className="w-100 mb-1">
                {subCategories.length > 0 && selectedCategory !== null && (
                  <Select
                    value={selectedSubCategory}
                    onChange={handleChangeSubCategory}
                    // onBlur={handleOnBlurSubCategory}
                    name="subCategory"
                    isClearable={true}
                    options={
                      categories.filter(
                        (item) => item.value === selectedCategory.value
                      )[0].sub_categories
                    }
                    placeholder="Sub category"
                  />
                )}
              </div>
              <div className="w-100 mb-1">
                {subSubCategories.length > 0 &&
                  selectedSubCategory !== null && (
                    <Select
                      value={selectedSubSubCategory}
                      onChange={handleChangeSubSubCategory}
                      name="subSubCategory"
                      isClearable={true}
                      placeholder="Sub sub category"
                      options={
                        subCategories.filter(
                          (item) => item.value === selectedSubCategory.value
                        )[0].sub_sub_categories
                      }
                    />
                  )}
              </div>
              <div className="w-100 mb-1">
                {subSubSubCategories.length > 0 &&
                  selectedSubSubCategory !== null && (
                    <Select
                      value={selectedSubSubSubCategory}
                      onChange={handleChangeSubSubSubCategory}
                      name="subSubSubCategory"
                      isClearable={true}
                      placeholder="Sub sub sub category"
                      options={
                        subSubCategories.filter(
                          (item) => item.value === selectedSubSubCategory.value
                        )[0].sub_sub_sub_categories
                      }
                    />
                  )}
              </div>
            </div>
            {error.category && (
              <div className="col-12">
                <span className="errorMsg">
                  * Category should not be empty.
                </span>
              </div>
            )}

            <div className="col-12">
              <span className="font-weight-bold">Description title</span>
              <div className="titleContainer">
                <div className="titleDiv">
                  <input
                    type="text"
                    name="descriptionTitle"
                    value={descriptionTitle}
                    className="inputName"
                    onChange={handleChangeDescriptionTitle}
                    // onBlur={handleonBlurUpdateStoreName}
                    // onKeyPress={handleKeyPress}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <span className="font-weight-bold">Description</span>
              <TextareaAutosize
                maxRows={6}
                style={{ width: "100%" }}
                name="description"
                // onChange={onChangeDescription}
                // onBlur={onBlurDescription}
                // value={description}
                // defaultValue={product.description}
              />
            </div>

            <div className="col-12">
              <span className="font-weight-bold">Quantity</span>
            </div>
            <div className="col-12">
              <NumberFormat
                // itemDiscount errorDiscount
                value={quantity}
                // className="itemPrice"
                thousandSeparator={false}
                inputMode="numeric"
                decimalSeparator={"."}
                format="######"
                onFocus={(e) => e.target.select()}
                name="quantity"
                onChange={handleChangeProductQuantity}
                // onBlur={handleonBlurUpdateProductQuantity}
                className="col-6 col-md-3 mr-3 d-inline-block"
                // onKeyPress={handleKeyPressProductPrice}
              />

              <Select
                value={selectedUnit}
                onChange={handleChangeUnit}
                // onBlur={handleOnBlurUnit}
                options={measuereUnit}
                isClearable={true}
                name="measuereUnit"
                placeholder="Measuere unit"
                className="col-6 col-md-4 d-inline-block"
              />
            </div>
            <div className="col-12">
              <div className="w-100">
                <span className="font-weight-bold">Price</span>
              </div>
              <div className="w-100">
                <NumberFormat
                  value={price}
                  // className="itemPrice"
                  thousandSeparator={false}
                  inputMode="numeric"
                  decimalSeparator={"."}
                  onFocus={(e) => e.target.select()}
                  suffix={" €"}
                  name="price"
                  onChange={handleChangeProductPrice}
                  // onBlur={handleonBlurUpdateProductPrice}
                  className="col-6 col-md-3"
                  // onKeyPress={handleKeyPressProductPrice}
                />
              </div>

              {error.price && (
                <div className="w-100">
                  <span className="errorMsg">
                    * Price must be greater than zero.
                  </span>
                </div>
              )}
            </div>

            <div className="col-12">
              <div className="w-100">
                <span className="font-weight-bold">Discount</span>
              </div>
              <div className="w-50 discountDivv">
                <div className="">
                  <NumberFormat
                    // itemDiscount errorDiscount
                    value={discount}
                    className={
                      parseInt(discount) >= parseInt(price) &&
                      parseInt(price) > 0
                        ? "itemDiscount errorDiscount"
                        : "itemDiscount"
                    }
                    thousandSeparator={false}
                    inputMode="numeric"
                    decimalSeparator={"."}
                    fixedDecimalScale={true}
                    onFocus={(e) => e.target.select()}
                    suffix={" €"}
                    name="discount"
                    onChange={handleChangeProductDiscount}
                    // onBlur={handleonBlurUpdateProductDiscount}
                    // onKeyPress={handleKeyPressProductDiscount}
                    className="w-100"
                  />
                </div>
                <div className="">
                  <NumberFormat
                    // itemDiscount errorDiscount
                    value={percent >= 100 ? 0 : percent}
                    className={
                      parseInt(discount) >= parseInt(price) &&
                      parseInt(price) > 0
                        ? "itemDiscount errorDiscount"
                        : "itemDiscount"
                    }
                    thousandSeparator={false}
                    inputMode="numeric"
                    decimalSeparator={"."}
                    onFocus={(e) => e.target.select()}
                    suffix={" %"}
                    format="## %"
                    name="percent"
                    onChange={handleChangeProductPercent}
                    // onBlur={handleonBlurUpdateProductPercent}
                    // onKeyPress={handleKeyPressProductPercent}
                    className="w-100"
                  />
                </div>

                <div className="">
                  {parseInt(discount) <= parseInt(price) && discount > 0 && (
                    <div>
                      <div className="w-100">
                        <span className="font-weight-bold">Start</span>
                      </div>
                      <div className="">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DateTimePicker
                            value={selectedDateStart}
                            onChange={handleDateChangeStart}
                            // onBlur={handleonBlurDateStart}
                            format="DD-MM-YYYY hh:mm:ss"
                            name="discountDateStart"
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  )}
                </div>
                <div className="">
                  {parseInt(discount) <= parseInt(price) && discount > 0 && (
                    <div className="">
                      <div className="w-100">
                        <span className="font-weight-bold">End</span>
                      </div>
                      <div className="">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DateTimePicker
                            value={selectedDateEnd}
                            onChange={handleDateChangeEnd}
                            // onBlur={handleonBlurDateEnd}
                            format="DD-MM-YYYY hh:mm:ss"
                            name="discountDateEnd"
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* end date must be greater than start date */}
            {parseInt(discount) >= parseInt(price) && parseInt(price) > 0 && (
              <div className="col-12">
                <span className="errorMsg">
                  * Discount should be smaller than price.
                </span>
              </div>
            )}
            {error.dateStartEnd && (
              <div className="col-12">
                <span className="errorMsg">
                  * End date must be greater than start date.
                </span>
              </div>
            )}

            <div className="my-2 col-12">
              <Link
                to={{
                  pathname: `/variations/${product.slug}`,

                  product: product,
                  url: `/product/${product.slug}`,
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  className="btn btn-lg py-2"
                >
                  Create variations
                </Button>
              </Link>
            </div>

            <div className="col-12 my-4 varoll">
              <MultiImage
                product_id={product.id}
                gallery={product.gallery}
              ></MultiImage>
            </div>
          </div>
          <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-1">
            <div className="row mt-4">
              <div className="col-12">
                <Tags product_id={product.id} />
              </div>
            </div>
          </div>
        </div>
      </form>
      <SuccessMessage show={successShow}></SuccessMessage>
    </Layout>
  );
};
export default UpdateProduct;
