import NoImage from "img/noImage.png";

export default function getImage(imgUrl) {
    if (imgUrl !== "") {
        try {
            if (!imgUrl.trim().toLowerCase().includes("http")) {
                console.log("+++-", imgUrl);
                return require("../" + imgUrl);
            }
            return imgUrl;
        } catch (error) {
            return NoImage;
        }
    } else {
        return NoImage;
    }
}
