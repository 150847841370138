import React, { useState, useEffect } from "react";
import Layout from "../Layout/Layout";
import StoreSettings from "./StoreSettings/StoreSettings";
import "./StorePage.css";
import StoreInfo from "./StoreInfo/StoreInfo";
import { Redirect } from "react-router-dom";
import Loading from "Loading";

import SuccessMessage from "components/SuccessMessage/SuccessMessage";
import { API } from "config";
import axios from "axios";

const StorePage = (props) => {
  const [storeInfos, setStoreInfos] = useState([]);
  const [isLogin, setIsLogin] = useState(true);
  const [currentCategory, setCurrentCategory] = useState();

  const [currentSearch, setCurrentSearch] = useState("");

  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [productsDraft, setProductsDraft] = useState([]);

  const [successShow, setSuccessShow] = useState(false);

  useEffect(() => {
    if (successShow) {
      setTimeout(function () {
        setSuccessShow(false);
      }, 2000);
    }
  }, [successShow]);

  useEffect(() => {
    localStorage.setItem(["business"], JSON.stringify(storeInfos));
  }, [storeInfos]); //[cart, storeInfos]

  const changeCategory = (index) => {
    console.log("indexxxxx", index);
    setCurrentCategory(index);
    // index, "abc");
  };

  useEffect(() => {
    const is_auth = async () => {
      const jwt = JSON.parse(localStorage.getItem("jwt"));
      const token = jwt.token;
      let form_data = new FormData();
      form_data.append("token", token);
      const response = await axios.post(`${API}/account/token/`, form_data);
      return response.data.success;
    };

    const categorySortSearch = (Data) => {
      let newArr = Data.categories;

      if (currentCategory === "All") {
        setCategories(
          newArr.sort(function (a, b) {
            if (a.rank < b.rank) {
              return -1;
            }
            if (a.rank > b.rank) {
              return 1;
            }
            return 0;
          })
        );
        // setOriginalStores(Data);
        // alwaysSort(Data, currentSort);
      } else {
        // console.log("dsdasdsa", newArr, "=", currentCategory);
        newArr = newArr.filter((item) => item.name === currentCategory);
      }
      setCategories(newArr);
      // console.log(newArr, index, currentSort, currentSearch);
      searchProcess(newArr, currentSearch);
    };
    is_auth().then((data) => {
      if (data) {
        const jwt = JSON.parse(localStorage.getItem("jwt"));
        const token = jwt.token;

        fetch(`${API}/store/details/${props.match.params.storeSlug}/`, {
          method: "GET",
          headers: {
            Authorization: `Token ${token}`,
          },
        })
          .then((res) => res.json())
          .then((ress) => {
            let data = ress.result;
            console.log(ress, "GET STORE DATA");
            //   console.log(data.categories, data.categories.length, "---0");
            setStoreInfos(data);
            localStorage.setItem("branch", data.branch);
            localStorage.setItem("id", data.id);
            localStorage.setItem("slug", data.slug);
            console.log("Products----", data.products);
            setProducts(data.products.filter((item) => item.draft === false));
            setProductsDraft(
              data.products.filter((item) => item.draft === true)
            );
            // setProducts(data.products);
            categorySortSearch(data);
          });
        setIsLogin(true);
      } else {
        setIsLogin(false);
        console.log("-------------------logout");
      }
    });
  }, [currentCategory, currentSearch, props.match.params.storeSlug]);

  const searchProcess = (arr, value) => {
    value = value.trim().toLowerCase();
    arr = arr.filter((item) => item.name.trim().toLowerCase().includes(value));
    setCategories(arr);
  };

  const changeSearch = (value) => {
    setCurrentSearch(value);
  };

  return (
    <Layout>
      {!isLogin && (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )}
      {storeInfos.length === 0 ? (
        <Loading />
      ) : (
        <div className="pageDiv">
          <div className="storeInfoDiv">
            <StoreInfo
              storeInfos={storeInfos}
              currentCategory={currentCategory}
              changeCategory={changeCategory}
              changeSearch={changeSearch}
              categories={categories}
              products1={products}
              setProducts={setProducts}
              productsDraft={productsDraft}
              setProductsDraft={setProductsDraft}
              setCategories={setCategories}
              setCurrentCategory={setCurrentCategory}
              setSuccessShow={setSuccessShow}
            />
          </div>

          <div className="cartDiv">
            <StoreSettings store={storeInfos} />
          </div>
        </div>
      )}
      <SuccessMessage show={successShow}></SuccessMessage>
    </Layout>
  );
};

export default StorePage;
