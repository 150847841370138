import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

// import Layout from "./components/Layout/Layout";
// import Stores from "./components/HomePage/Stores/Stores";

// import Data from "./data.json";

// import Form from "./components/Form/Form";
// import HomeCategories from "./components/HomePage/HomeCategories/HomeCategories";
import StoreBusiness from "components/SignupBusiness/SignupBusiness";
// import axios from "axios";

const App = () => {
  return <StoreBusiness></StoreBusiness>;
};

export default App;
