import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// import MenuItem from "@material-ui/core/MenuItem";
// import Autocomplete from "@material-ui/lab/Autocomplete";
// import NumberFormat from "react-number-format";
import { API } from "../config";
import axios from "axios";
// import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Select from "react-select";
import { useHistory } from "react-router-dom";
// import ImgDropzone from "components/ImgDropzone/ImgDropzone";
import "./user.css";
// const useStyles = makeStyles((theme) => ({
//   root: {
//     "& .MuiTextField-root": {
//       margin: theme.spacing(1),
//       width: 400,
//     },
//   },
// }));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "80%",
  },
  textPhone: {
    width: "30%",
  },
  selectField: {
    width: "40%",
  },
}));

export default function StoreForm() {
  const history = useHistory();
  const jwt = JSON.parse(localStorage.getItem("jwt"));
  const token = jwt.token;
  const header = {
    Authorization: `Token ${token}`,
  };
  const classes = useStyles();
  const [department, setDepartment] = useState("");
  const [categories, setCategories] = useState([]);

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedQuarter, setSelectedQuarter] = useState(null);
  // const [curCityId, setCurCityId] = useState(null);
  // const [curQuarterId, setCurQuarterId] = useState(null);
  const [phone, setPhone] = useState();
  // const [backgroundImg, setBackgroundImg] = useState(null);
  // const [logoImg, setlogoImg] = useState(null);
  // const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState({
    name: false,
    department: false,
    city: false,
    quarter: false,
    address: false,
    email: false,
    emailMsg: "",
    invalidEmail: false,
    phone: false,
    invalidPhone: false,
  });

  const getCities = () => {
    const cities = `${API}/cities`;
    axios
      .get(cities)
      .then(function (response) {
        // handle success
        setCities(response.data.result);
        // console.log("radi", response.data.result);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {});
  };

  const getStoreDepartments = () => {
    // console.log(`${API}/store/foraddstoredepartments`);

    // const restaurants = `${API}/store/departments1`;
    axios
      .get(`${API}/store/departments1`)
      .then(function (response) {
        // handle success
        setCategories(response.data.result);
        // console.log("departments", response.data.result);
      })
      .catch(function (error) {
        // handle error
        // console.log("error----", error);
      })
      .then(function () {});
  };

  useEffect(() => {
    getStoreDepartments();
    getCities();
  }, []);

  const handleChangeDepartment = (event) => {
    setDepartment(event);
  };
  // const handleCityChange = (event, values) => {
  //   setCurCityId(values.id);
  // };
  // const handleQuarterChange = (event, values) => {
  //   setCurQuarterId(values.id);
  // };
  const handlePhoneChange = (event) => {
    setPhone(event);
  };

  // const handleChangeName = (event) => {
  //   setName(event.target.value);
  // };
  const handleChangeAddress = (event) => {
    setAddress(event.target.value);
  };
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleChangeCity = (selectedCity) => {
    setSelectedCity(selectedCity);
  };
  const handleChangeQuarter = (selectedQuarter) => {
    setSelectedQuarter(selectedQuarter);
  };
  // const onBackDrop = (acceptedFiles) => {
  //   console.log(acceptedFiles, "11111");
  //   setBackgroundImg(URL.createObjectURL(acceptedFiles[0]));
  // };
  // const onLogoDrop = (acceptedFiles) => {
  //   console.log(acceptedFiles, "222");
  //   setlogoImg(URL.createObjectURL(acceptedFiles[0]));
  // };

  const saveStore = async (form_data) => {
    var URL = `${API}/store`;
    console.log("URL", API);
    const newStore = await axios.post(URL, form_data, {
      headers: header,
    });
    // console.log("hata oldu", newStore);
    // console.log("hataaaa", Object.keys(newStore.data.errors));
    if (newStore.data.error === false) {
      // window.location.reload();
      history.push("/user/dashboard/store");
    } else {
      let error2 = { ...error };
      let key = Object.keys(newStore.data.errors)[0];
      error2[key] = true;
      error2.emailMsg = newStore.data.errors.email;
      setError(error2);
    }
  };

  const handleSubmit = (event) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let error2 = { ...error };
    event.preventDefault();
    event.target.name.value === ""
      ? (error2.name = true)
      : (error2.name = false);

    department === ""
      ? (error2.department = true)
      : (error2.department = false);
    console.log("city", selectedCity);
    console.log("city.eroro111", error2.city);
    selectedCity === null ? (error2.city = true) : (error2.city = false);
    // console.log("quarter", selectedQuarter);
    // selectedQuarter === null ? (error2.city = true) : (error2.city = false);

    console.log("city.eroro2", error2.city);
    event.target.address.value === ""
      ? (error2.address = true)
      : (error2.address = false);
    if (event.target.email.value === "") {
      error2.email = true;
      error2.emailMsg = "* Can't be empty";
    } else {
      error2.email = false;
      if (re.test(event.target.email.value)) {
        error2.invalidEmail = false;
      } else {
        error2.invalidEmail = true;
      }
    }
    if (event.target.phone.value === "") {
      error2.phone = true;
    } else {
      error2.phone = false;
      console.log("phone", event.target.phone.value);
      if (isValidPhoneNumber(event.target.phone.value)) {
        error2.invalidPhone = false;
        console.log("buraya geliyor false");
      } else {
        error2.invalidPhone = true;
        console.log("buraya geliyor True");
      }
    }
    let success = true;
    setError(error2);
    console.log("seterorrororro", error2.phone);
    for (var i in error2) {
      if (error2[i] === true) {
        console.log("error2", error2[i]);
        success = false;
        break;
      }
    }

    if (success) {
      let form_data = new FormData();
      // logoImg !== null && form_data.append("logo", logoImg, logoImg.name);
      // backgroundImg !== null &&
      //     form_data.append("background", backgroundImg, backgroundImg.name);
      form_data.append("name", event.target.name.value);
      form_data.append("city", selectedCity.id);
      form_data.append("quarter", selectedQuarter.id);
      form_data.append("phone", event.target.phone.value);
      form_data.append("address", event.target.address.value);
      form_data.append("email", event.target.email.value);
      form_data.append("department", department.id);
      saveStore(form_data);
    }
  };
  useEffect(() => {
    console.log("useEffect", error);
  }, [error]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 mb-2">
            <TextField
              className={clsx(classes.margin, classes.textField)}
              label="Name"
              id="name"
              name="name"
              size="small"
              // onChange={handleChangeName}
            />
          </div>
          {error.name && (
            <div className="col-12">
              <span className="errorMsg">* Can't be empty</span>
            </div>
          )}
          <div className="col-12 mb-3">
            <div className="row">
              <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                <Select
                  value={department}
                  className={clsx(classes.margin, classes.textField)}
                  onChange={handleChangeDepartment}
                  options={categories}
                  isClearable={true}
                  name="department"
                  placeholder="Select Department"
                />
              </div>
            </div>
          </div>
          {error.department && (
            <div className="col-12">
              <span className="errorMsg">* Can't be empty</span>
            </div>
          )}
          <div className="col-12 mb-3">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-sm-8 col-md-5 col-lg-4 col-xl-4">
                    <Select
                      value={selectedCity}
                      className={clsx(classes.margin, classes.textField)}
                      onChange={handleChangeCity}
                      options={cities}
                      isClearable={true}
                      // className={clsx(classes.margin, classes.selectField)}
                      name="city"
                      placeholder="Select City"
                    />
                  </div>
                  <div className="col-12 col-sm-8 col-md-5 col-lg-4 col-xl-4">
                    {selectedCity !== null && (
                      <Select
                        value={selectedQuarter}
                        className={clsx(classes.margin, classes.textField)}
                        onChange={handleChangeQuarter}
                        options={
                          cities.filter(
                            (item) => item.value === selectedCity.value
                          )[0].quarters
                        }
                        isClearable={true}
                        name="quarter"
                        placeholder="Select quarter"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {error.city && (
            <div className="col-12">
              <span className="errorMsg">* Can't be empty</span>
            </div>
          )}
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                <TextField
                  className={clsx(classes.margin, classes.textField)}
                  // className={
                  //   ("errorClass", clsx(classes.margin, classes.textField))
                  // }
                  label="Address"
                  id="standard-size-small"
                  size="small"
                  name="address"
                  value={address}
                  onChange={handleChangeAddress}
                />
              </div>
            </div>
          </div>
          {error.address && (
            <div className="col-12">
              <span className="errorMsg">* Can't be empty</span>
            </div>
          )}
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4">
                <TextField
                  label="Email"
                  className={clsx(classes.margin, classes.textField)}
                  size="small"
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleChangeEmail}
                />
              </div>
            </div>
          </div>
          {error.email && (
            <div className="col-6">
              <span className="errorMsg">* Can't be empty</span>
            </div>
          )}
          {error.invalidEmail && (
            <div className="col-6">
              <span className="errorMsg">*Invalid email</span>
            </div>
          )}
          <div className="col-12">
            <span>Phone</span>
          </div>
          <div className="col-12 mb-4">
            <div className="row">
              <div className="col-12 col-sm-8 col-md-5 col-lg-5 col-xl-4">
                <PhoneInput
                  // className={clsx(classes.margin)}
                  className={clsx(classes.margin, classes.textField)}
                  defaultCountry="XK"
                  placeholder="Enter phone number"
                  value={phone}
                  name="phone"
                  onChange={handlePhoneChange}
                />
                {/* <NumberFormat
                  // itemDiscount errorDiscount
                  value={phone}
                  // className="itemPrice"
                  thousandSeparator={false}
                  inputMode="numeric"
                  decimalSeparator={"."}
                  format="######"
                  onFocus={(e) => e.target.select()}
                  name="quantity"
                  // onChange={handleChangeProductQuantity}
                  // onBlur={handleonBlurUpdateProductPrice}
                  // onKeyPress={handleKeyPressProductPrice}
                /> */}
              </div>
            </div>
          </div>
          {error.phone && (
            <div className="col-6">
              <span className="errorMsg">* Can't be empty</span>
            </div>
          )}
          {error.invalidPhone && (
            <div className="col-6">
              <span className="errorMsg">*Invalid phone</span>
            </div>
          )}
          <div className="col-12">
            <Button
              type="submit"
              // onClick={handleSaveButton}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
