import React from "react";
import "./ImageDropzone.css";
import Dropzone from "react-dropzone";
import getImage from "functions/getImage";

const ImageDropzone = ({ img, onImgDrop, load }) => {
  return (
    <React.Fragment>
      <Dropzone onDrop={onImgDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="imgDrop">
            <img className="DropImg" src={getImage(img)} alt="" />
            <div className="dropDiv">
              <input {...getInputProps()} />
              {/* <p>521 X 512</p> */}
            </div>
          </div>
        )}
      </Dropzone>
    </React.Fragment>
  );
};

export default ImageDropzone;
