import React, { useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { isAuthenticated } from "../auth";
// import { Link } from "react-router-dom";
import VerticalTabs from "./VerticalTabs";
import "./Dashboard.css";
// import VerticalTabs from "components/VerticalTabs/VerticalTabs";

// import { getPurchaseHistory } from "./apiUser";      //kullanilacak
// import moment from "moment";

const Dashboard = () => {
  // const [history, setHistory] = useState([]);

  const {
    user: {
      //  _id,
      name,
      //  email,
      //  role,
      //  active,
      admin,
    },
  } = isAuthenticated();
  // const token = isAuthenticated().token;

  const init = (userId, token) => {
    // console.log(token);
    // getPurchaseHistory(userId, token).then((data) => {
    //     console.log("dfsfsdfsdfsd", data);
    //     if (data.error) {
    //         console.log(data.error);
    //     } else {
    //         setHistory(data);
    //     }
    // });
  };

  useEffect(() => {
    const token = isAuthenticated().token;
    init(1, token); //_id, token
  }, []);
  console.log("--------------------", admin);
  // const userLinks = () => {
  //     return (
  //         <div className="card">
  //             <h4 className="card-header">User Links</h4>
  //             <ul className="list-group">
  //                 <li className="list-group-item">
  //                     <Link className="nav-link" to="/cart">
  //                         My Cart
  //                     </Link>
  //                 </li>
  //                 <li className="list-group-item">
  //                     <Link className="nav-link" to={`/profile/${_id}`}>
  //                         Update Profile
  //                     </Link>
  //                 </li>
  //                 <li className="list-group-item">
  //                     <Link className="nav-link" to={`/profile/${_id}`}>
  //                         Stores
  //                     </Link>
  //                 </li>
  //             </ul>
  //         </div>
  //     );
  // };

  // const userInfo = () => {
  //     return (
  //         <div className="card mb-5">
  //             <h3 className="card-header">User Information</h3>
  //             <ul className="list-group">
  //                 <li className="list-group-item">
  //                     {name}
  //                     <div className="ml-5 d-inline">
  //                         {active === 0 ? (
  //                             <span style={{ color: "red" }}>
  //                                 Your account is not activated
  //                             </span>
  //                         ) : (
  //                             <span style={{ color: "green" }}>
  //                                 Account is active
  //                             </span>
  //                         )}
  //                     </div>
  //                 </li>
  //                 <li className="list-group-item">{email}</li>
  //                 <li className="list-group-item">
  //                     {role === 1 ? "Admin" : "Registered User"}
  //                 </li>
  //             </ul>
  //         </div>
  //     );
  // };

  // const purchaseHistory = (history) => {
  //     return (
  //         <div className="card mb-5">
  //             <h3 className="card-header">Purchase history</h3>
  //             <ul className="list-group">
  //                 <li className="list-group-item">
  //                     {history.map((h, i) => {
  //                         return (
  //                             <div>
  //                                 <hr />
  //                                 {h.products.map((p, i) => {
  //                                     return (
  //                                         <div key={i}>
  //                                             <h6>Product name: {p.name}</h6>
  //                                             <h6>
  //                                                 Product price: ${p.price}
  //                                             </h6>
  //                                             <h6>
  //                                                 Purchased date:{" "}
  //                                                 {moment(
  //                                                     p.createdAt
  //                                                 ).fromNow()}
  //                                             </h6>
  //                                         </div>
  //                                     );
  //                                 })}
  //                             </div>
  //                         );
  //                     })}
  //                 </li>
  //             </ul>
  //         </div>
  //     );
  // };

  return (
    <Layout
      title="Dashboard"
      description={`G'day ${name}!`}
      className="container-fluid"
    >
      <VerticalTabs />
      {/* <div className="row">
        <div className="col-3">{userLinks()}</div>
        <div className="col-9">
          {userInfo()}
          {purchaseHistory(history)} 
        </div>
      </div> */}
    </Layout>
  );
};

export default Dashboard;
