import React from 'react';
// import axios from "axios";

import './Stores.css';
import Store from '../StoreItem/StoreItem';

const Stores = ({ stores, searchText }) => {
    return (
        <div className="storeContainer">
            {stores.length > 0 ? (
                stores.map((item, key) => (
                    <Store key={key} id={item.pk} value={item}></Store>
                ))
            ) : searchText !== '' ? (
                <div className="help-message">
                    No businnes found with name "{searchText}"
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

export default Stores;
