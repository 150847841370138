import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import NavbarButtons from "components/Layout/Navbar/NavbarButtons/NavbarButtons";

import Drawer from "@material-ui/core/Drawer";

import logo from "img/store-tele24.png";
import "./DrawerContent.css";
// import Divider from "@material-ui/core/Divider";
// import { API } from "config";

const DrawerContent = ({
  departmentSlug,
  departments,
  drawerIsOpen,
  closeDrawerHandler,
  clickOption,
}) => {
  const [drawerSide, setDrawerSide] = useState("left");
  const [drawerContent, setDrawerContent] = useState("");
  // const [departSlug, setDepartSlug] = useState("");
  // const [categories, setCategories] = useState([]);

  useEffect(() => {
    changeContent();
    console.log("sssonra", departments);
  }, [departmentSlug, clickOption]);

  const closeDrawer = () => {
    changeContent();
    closeDrawerHandler();
  };

  const changeContent = (ind = 0, departSlug = "", categorySlug = "") => {
    let content = "";
    // if (depSlug !== "") {
    //     setDepartSlug(depSlug);
    // }

    content = (
      <React.Fragment>
        <NavbarButtons onClick={closeDrawer} />
        {/* <CartIcon cartSize={cartSize} /> */}
      </React.Fragment>
    );

    setDrawerContent(content);
  };
  const isActive = (path) => {
    if (departmentSlug !== undefined && departmentSlug === path) {
      return "active";
      // return { color: "#ff9900" };
    } else {
      return "";
      // return { color: "#ffffff" };
    }
  };
  return (
    <Drawer
      open={drawerIsOpen}
      onClose={closeDrawer}
      anchor={drawerSide}
      className="drawerDiv"
    >
      <nav className="navbar navbar-expand-md  bg-tele">
        <Link className="navbar-brand logo mb-1" to="/">
          <img src={logo} className="" alt="logo" />
        </Link>
        <div className="mb-1">
          <i
            className="fa fa-times"
            style={{
              color: "#fff",
              fontSize: "20px",
            }}
            onClick={closeDrawerHandler}
          ></i>
        </div>
      </nav>

      <div className="drawerButtons">{drawerContent}</div>
    </Drawer>
  );
};

export default DrawerContent;
