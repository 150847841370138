import React, { useState } from "react";

import "./StoreSettings.css";
import { Link } from "react-router-dom";
import WarehousePopup from "components/WarehousePopup/WarehousePopup";
const StoreSettings = ({ store }) => {
  const [showWarehouseModel, setShowWarehouseModel] = useState(false);
  const onClickWarehouses = () => {
    console.log("onClickWarehouses");
    setShowWarehouseModel(true);
  };
  console.log("ffff", store.warehouse);
  return (
    <React.Fragment>
      <div className="cartTitle">
        <h3 className="text-center titleText">Settings </h3>
        <hr></hr>
      </div>
      <Link
        to={{
          pathname: `${store.slug}/orders/`,
        }}
      >
        <h4 className="text-center">Orders </h4>
      </Link>
      <h4
        onClick={onClickWarehouses}
        className="text-center"
        style={{ cursor: "pointer" }}
      >
        Warehouses{" "}
      </h4>
      <WarehousePopup
        show={showWarehouseModel}
        handleClose={() => setShowWarehouseModel(false)}
        warehousesCopy={store.warehouse}
        store_id={store.id}
        // handleDelete={deleteProduct}
        // product={forDelete}
        // text={"product"}
      />
    </React.Fragment>
  );
};

export default StoreSettings;
