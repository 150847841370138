import React, { Fragment } from "react";
import "./NavbarButtons.css";
import { Link, withRouter } from "react-router-dom";

import { isAuthenticated, signout } from "auth";

const isActive = (history, path) => {
  if (history.location.pathname === "/" && path === "/login") {
    return "active";
  } else if (history.location.pathname === path) {
    return "active";
    // return { color: "#ff9900" };
  } else {
    return "";
    // return { color: "#ffffff" };
  }
};

const NavbarButtons = ({ history }) => {
  // const btnArr = [
  //     { name: "Home", url: "/", icon: "home" },
  //     {
  //         name: "Sign up as Business",
  //         url: "/sign-up-business",
  //         icon: "briefcase",
  //     },
  //     { name: "Login", url: "/login", icon: "sign-in" },
  //     { name: "Sign Up", url: "/sign-up", icon: "user-plus" },
  // ];
  return (
    <React.Fragment>
      {/* {isAuthenticated() && isAuthenticated().user.telmed === undefined && } */}
      {isAuthenticated() && isAuthenticated().user.role === 0 && (
        <React.Fragment>
          <li className="nav-item">
            <Link
              className={`nav-link ${isActive(history, "/user/dashboard")}`}
              to="/user/dashboard"
            >
              <i className="fa fa-address-card  mr-2"></i>
              Dashboard
            </Link>
          </li>
        </React.Fragment>
      )}

      {isAuthenticated() && isAuthenticated().user.role === 1 && (
        <li className="nav-item">
          <Link
            className={`nav-link ${isActive(history, "/admin/dashboard")}`}
            to="/admin/dashboard"
          >
            Dashboard
          </Link>
        </li>
      )}

      {!isAuthenticated() && (
        <Fragment>
          <Link
            className={`nav-link ${isActive(history, "/sign-up")}`}
            to="/sign-up"
          >
            <i className="fa fa-briefcase  mr-2"></i>
            Sign up
          </Link>
          <Link
            className={`nav-link ${isActive(history, "/login")}`}
            to="/login"
          >
            <i className="fa fa-sign-in  mr-2"></i>
            Login
          </Link>
        </Fragment>
      )}
      {isAuthenticated() && (
        <Fragment>
          {isAuthenticated().user.telmed === true ? (
            <Link
              className={`nav-link ${isActive(history, "/user/telmed")}`}
              to="/user/telmed"
            >
              <i className="fa fa-user-plus  mr-2"></i>
              Dashboard
            </Link>
          ) : (
            <Link
              className={`nav-link ${isActive(history, "/user/dashboard")}`}
              to="/user/dashboard"
            >
              <i className="fa fa-user-plus  mr-2"></i>
              Dashboard
            </Link>
          )}

          <li className="nav-item d-block d-sm-none">
            <Link
              className={`nav-link ${isActive(
                history,
                "/user/dashboard/store"
              )}`}
              to="/user/dashboard/store"
            >
              <i className="fa fa-building  mr-2"></i>
              Stores
            </Link>
          </li>
          <li className="nav-item d-block d-sm-none">
            <Link
              className={`nav-link ${isActive(
                history,
                "/user/dashboard/add_store"
              )}`}
              to="/user/dashboard/add_store"
            >
              <i className="fa fa-plus  mr-2"></i>
              Add Store
            </Link>
          </li>
          <span
            className="nav-link"
            onClick={() =>
              signout(() => {
                history.push("/");
              })
            }
          >
            <i className="fa f a-sign-out "></i>
            Sign out
          </span>
        </Fragment>
      )}
    </React.Fragment>
  );
};

export default withRouter(NavbarButtons);
