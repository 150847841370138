import React from "react";

import "./StoreItem.css";
import Rating from "components/Rating/Rating";
import StoreImages from "components/StoreImages/StoreImages";
import { useHistory } from "react-router-dom";
// import defaultLogo from '../../img/logo.png';
// import defaultBack from '../../img/background.png';

const Store = ({ id, value }) => {
    const history = useHistory();

    const routeChange = () => {
        let slug = value.slug;
        let path = `/${slug}`;
        history.push(path);
    };
    return (
        <div className="storeDiv " key={id}>
            <div className="content">
                <StoreImages background={value.background} logo={value.logo} />
                <div className="storeItemInfoDiv" onClick={routeChange}>
                    <div className="storeName mb-2">{value.name}</div>
                    <div className="mb-2">
                        <span className="workingHours">
                            {value.working_hours}
                        </span>
                    </div>
                    <div>
                        <b>Minimum Order:</b>
                        <span>{value.minimum_order} €</span>
                    </div>

                    <div>
                        <b>Delivery Fee:</b>
                        <span>{value.delivery_fee} €</span>
                    </div>

                    <div>
                        <b>Description:</b>
                        <span>{value.description}</span>
                    </div>

                    <div>
                        <b>Distance:</b>
                        <span>{value.distance} km</span>
                    </div>

                    <div>
                        <b>Delivery time:</b>
                        <span>{value.delivery_time}</span>
                    </div>

                    <Rating value={value.rating} />
                </div>
            </div>
        </div>
    );
};

export default Store;
