import React, { useState } from "react";
import "./Navbar.css";
import logo from "img/store.tele724.png";
// import Language from "./Language/Language";
// import CartIcon from "./CartIcon/CartIcon";
import NavbarButtons from "./NavbarButtons/NavbarButtons";
// import logo from "img/store-tele24.png";
import { Link, useHistory } from "react-router-dom";
import DrawerContent from "components/DrawerContent/DrawerContent";

const Navbar = ({ cartSize }) => {
  const history = useHistory();

  // const routeChange = () => {
  //     history.push("/");
  // };

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  //   const [clickOption, setClickOption] = useState("");
  const clickOption = "";
  // const [value, setValue] = useState(0);
  // const [departments, setDepartments] = useState([]);
  const departments = [];
  return (
    <div className="container-full">
      <nav className="navbar navbar-expand-md navbar-expand-sm bg-tava">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setDrawerIsOpen(true)}
          >
            <span className="navbar-toggler-icon">
              <i className="fa fa-bars"></i>
            </span>
          </button>

          <Link className="navbar-brand logo" to="/user/dashboard">
            <img
              src={logo}
              width="220"
              className="d-inline-block  mr-2"
              alt=""
            />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            // data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => history.push("/cart")}
            style={{ visibility: "hidden" }}
          >
            <span className="navbar-toggler-icon">
              <i className="fa fa-file-text"></i>
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto" style={{ listStyle: "none" }}>
              <NavbarButtons />
            </ul>
          </div>
        </div>
        <DrawerContent
          departmentSlug={""}
          departments={departments}
          drawerIsOpen={drawerIsOpen}
          clickOption={clickOption}
          closeDrawerHandler={() => setDrawerIsOpen(false)}
        />
      </nav>
    </div>
  );
};

export default Navbar;
