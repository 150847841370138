import React from "react";

import "./CategoryButtons.css";
import SearchBar from "components/SearchBar/SearchBar";

const CategoryButtons = ({
  currentCategory,
  changeCategory,
  changeSearch,
  storeInfos,
  setSuccessShow,
}) => {
  return (
    <div className="categoriesContainer">
      <div className="w-100">
        <hr />
      </div>
      <div className="w-100 mt-3">
        <SearchBar changeSearch={changeSearch} />
      </div>
    </div>
  );
};

export default CategoryButtons;
