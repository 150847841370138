import React, { useState, useEffect } from "react";

import "./Product.css";
import NumberFormat from "react-number-format";

import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
// import EditProductPopup from "./EditProductPopup/EditProductPopup";
import DeletePopup from "../../DeletePopup/DeletePopup";
import ImageDropzone from "components/ImageDropzone/ImageDropzone";
import axios from "axios";
import { API } from "config";

const Product = ({ product, delProduct, setSuccessShow, setCategories }) => {
  const [editProduct, setEditProduct] = useState({ ...product });

  useEffect(() => {
    setEditProduct({ ...product });
  }, [product]);
  const [Img, setImg] = useState(editProduct.images);
  useEffect(() => {
    setImg(editProduct.images);
  }, [editProduct]);

  const jwt = JSON.parse(localStorage.getItem("jwt"));
  const token = jwt.token;
  const header = {
    Authorization: `Token ${token}`,
  };
  const [state, setState] = useState({
    checkedA: product.active,
  });
  const [showEditModel, setShowEditModel] = useState(false);
  const [showDeleteModel, setShowDeleteModel] = useState(false);

  function openEditModal() {
    setShowEditModel(true);
  }
  function openDeleteModal() {
    setShowDeleteModel(true);
  }
  const deleteProduct = () => {
    delProduct(product.id);

    setShowDeleteModel(false);
  };

  const updateProduct = async (active, images = null) => {
    let URLproductUpdate = `${API}/${product.id}/product/update/`;
    let form_data = new FormData();

    form_data.append("active", active);
    form_data.append("title", editProduct.name);
    form_data.append("description", editProduct.description);
    form_data.append("price", editProduct.price);
    if (images !== null) {
      form_data.append("image", images[0], images[0].name);
    }

    const update = await axios.put(URLproductUpdate, form_data, {
      headers: header,
    });
    console.log("update", update);
    setCategories(update.data);
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    updateProduct(event.target.checked);
  };
  const handleonBlurUpdateProductName = (event) => {
    console.log("nameeeee test", product.name, event.target.value);
    updateProduct(state.checkedA);
  };
  const handleKeyPressProductName = (event) => {
    if (event.key === "Enter") {
      updateProduct(state.checkedA);
    }
  };
  const handleChangeUpdateProductName = (event) => {
    setEditProduct({
      name: event.target.value,
      description: editProduct.description,
      price: editProduct.price,
      images: Img,
    });
  };
  const handleonBlurUpdateProductDescription = (event) => {
    updateProduct(state.checkedA);
  };
  const handleKeyPressProductDescription = (event) => {
    if (event.key === "Enter") {
      updateProduct(state.checkedA);
    }
  };
  const handleChangeUpdateProductDescription = (event) => {
    setEditProduct({
      name: editProduct.name,
      description: event.target.value,
      price: editProduct.price,
      images: Img,
    });
    // setSuccessShow(true);
  };
  const handleonBlurUpdateProductPrice = (event) => {
    updateProduct(state.checkedA);
  };
  const handleKeyPressProductPrice = (event) => {
    if (event.key === "Enter") {
      updateProduct(state.checkedA);
    }
  };
  const handleChangeUpdateProductPrice = (event) => {
    setEditProduct({
      name: editProduct.name,
      description: editProduct.description,
      price: event.target.value.slice(0, -2),
      images: Img,
    });
  };
  const onImgDrop = (acceptedFiles) => {
    console.log(acceptedFiles, "222");
    setImg(URL.createObjectURL(acceptedFiles[0]));
    updateProduct(state.checkedA, acceptedFiles);
  };
  // console.log("------", editProduct);
  return (
    <div className="productDiv">
      <div className="itemImgDiv">
        <ImageDropzone img={Img} onImgDrop={onImgDrop} />
      </div>
      <div className="itemInfoDiv">
        <input
          value={editProduct.name}
          type="text"
          name="name"
          className="itemName"
          placeholder="Type Name"
          onChange={handleChangeUpdateProductName}
          onBlur={handleonBlurUpdateProductName}
          onKeyPress={handleKeyPressProductName}
        />
        <FormControlLabel
          value="active"
          control={
            <Switch
              color="primary"
              name="checkedA"
              onChange={handleChange}
              checked={state.checkedA}
            />
          }
        />
        <NumberFormat
          value={editProduct.price}
          className="itemPrice"
          thousandSeparator={false}
          inputMode="numeric"
          decimalSeparator={"."}
          onFocus={(e) => e.target.select()}
          suffix={" €"}
          name="price"
          onChange={handleChangeUpdateProductPrice}
          onBlur={handleonBlurUpdateProductPrice}
          onKeyPress={handleKeyPressProductPrice}
        />
      </div>
      <div className="descriptionDiv">
        <textarea
          name="description"
          placeholder="Type Description"
          value={editProduct.description}
          onChange={handleChangeUpdateProductDescription}
          onBlur={handleonBlurUpdateProductDescription}
          onKeyPress={handleKeyPressProductDescription}
        ></textarea>
        <div>
          <EditIcon onClick={openEditModal} />
          <DeleteForeverIcon onClick={openDeleteModal} />
        </div>
      </div>

      <DeletePopup
        show={showDeleteModel}
        handleClose={() => setShowDeleteModel(false)}
        handleDelete={deleteProduct}
        product={editProduct}
        text={"product"}
      />
    </div>
    // <div className="productDiv" onClick={() => showPopup(product, "new")}>
    //   <div className="itemImgDiv">
    //     <div
    //       className="quantityDiv px-2 bg-success d-inline-block"
    //       // onClick={() => handleIncrement(product)}  popupta yapacaz bu islemi
    //     >
    //       {/* <span className="float-right quantity">{getQuantity()}</span> */}
    //     </div>
    //     <img
    //       src={getImage(product.images)}
    //       className="itemImage"
    //       alt={product.name}
    //     />
    //   </div>
    //   <div className="itemInfoDiv">
    //     <span className="itemName">{product.name}</span>
    //     {parseInt(product.price) > 0 && (
    //       <span className="itemPrice">
    //         {parseFloat(product.price).toFixed(2)} €
    //       </span>
    //     )}
    //   </div>
    //   <div className="descriptionDiv ">
    //     <p>{product.description}</p>
    //   </div>
    // </div>
  );
};

export default Product;
