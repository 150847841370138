import { useState, useEffect } from 'react';
const [locall, setLocall] = useState([]);

const [quantityArr, setQuantityArr] = useState([]);

useEffect(() => {
    let jsonArr =
        localStorage.getItem('rememberMe') != null
            ? JSON.parse(localStorage.getItem('rememberMe'))
            : [];
    setLocall(jsonArr);
    jsonArr.map((item, key) =>
        setQuantityArr([
            ...quantityArr,
            { id: item.id, quantity: item.quantity },
        ])
    );
    // console.log('------:', localStorage.getItem('rememberMe'));
}, []);

const searchOnLocal = (id, jsonArr) => {
    // let getItem = localStorage.getItem('rememberMe');
    // let jsonValue = JSON.parse(getItem);
    // console.log('==+', jsonValue);
    let localItem = [];

    let varMi = quantityArr.filter((item) => item.id === id);

    if (varMi.length > 0) {
        let itemm = quantityArr.filter((item) => item.id === id)[0];
        // console.log('bir sozz', varMi, itemm);
        itemm.quantity += 1;
        jsonArr.quantity = itemm.quantity;

        //jsonArr yi guncelledik, ama locale kaydedecek degisken ayni locall kaliyordu degismeden
        //onun quantitysini degistirmemiz lazimdi, onu degistirdim
        let yeniArr = locall.filter((item) => item.id === id)[0];
        yeniArr.quantity = itemm.quantity;

        localItem = locall;
        setQuantityArr(quantityArr);
    } else {
        setQuantityArr([...quantityArr, { id: id, quantity: 1 }]);
        jsonArr.quantity = 1;
        localItem = [...locall, jsonArr]; //locall !== null ? [...locall, jsonArr] : jsonArr;
        // console.log('???', localItem, locall, jsonArr);
    }
    localStorage.setItem(['rememberMe'], JSON.stringify(localItem));
    setLocall(localItem);
};

export default (product) => {
    // let product = category.products.filter((item) => item.id === id)[0];
    //id alip product i da getirebiliriz
    console.log(quantityArr);

    // console.log(id, '00000', product);
    let jsonArr = {
        id: product.id,
        name: product.name,
        images: product.images,
        extended_options: [],
        code: '11NhHI',
        options: [
            {
                id: 58,
                name: 'Thirsty?',
                suboptions: [{ id: 202, name: 'Coke', price: 10 }],
            },
        ],
        price: 8,
        total: 18,
        ingredients: product.ingredients,
        balance: 0,
        category_id: product.category_id,
        extended_data_options: {},
        data_options: { '202': true },
    };

    searchOnLocal(product.id, jsonArr);
};
