import React from "react";

import { Tab } from "semantic-ui-react";
// import axios from "axios";
import StoreForm from "user/StoreForm";
import Stores from "components/Stores/Stores";
// import StoreCard from "./StoreCard";
// import StoreForm from "./StoreForm";
// import AddCategory from "./AddCategory";
// import { Grid } from "@material-ui/core";
// import { API } from "config";

const panes = [
  {
    menuItem: "Stores",
    render: () => (
      <Tab.Pane>
        <h3>My stores</h3>
        <Stores />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Add New Store",
    render: () => (
      <Tab.Pane>
        <h3>Add New Store</h3>
        <StoreForm />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Update Profile",
    render: () => <Tab.Pane></Tab.Pane>,
  },
];

const VerticalTabs = () => (
  <Tab menu={{ fluid: true, vertical: true, tabular: true }} panes={panes} />
);

export default VerticalTabs;
