import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import App from "./App";
import Routes from "./Routes";
// import "semantic-ui-css/semantic.min.css";
//package json private in altina yapistir, django icin
// "proxy": "http://94.100.50.222:8000/",
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
ReactDOM.render(<Routes />, document.getElementById("root"));
