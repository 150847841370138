import React, { Fragment } from "react";
import { useTable, useSortBy, useExpanded, usePagination } from "react-table";
import { Table, Row, Col, Button, Input, CustomInput } from "reactstrap";
const OrderTable = ({ columns, data, renderRowSubComponent, total }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useSortBy,
    useExpanded,
    usePagination
  );
  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };
  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };
  // if () {
  //   console.log(total.created_data.total__sum);
  // }

  return (
    <Fragment>
      {total !== undefined && (
        <Fragment>
          <hr></hr>
          <div className="container">
          <Table size="sm">
            <thead>
              <tr>
                <th>Status</th>
                <th>Quantity</th>
                <th>Sum €</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Paid</td>
                <td>{total.paid_data.id__count}</td>
                <td>
                  {total.paid_data.line_item_total__sum === null
                    ? 0
                    : total.paid_data.line_item_total__sum}
                </td>
              </tr>
              <tr>
                <td>Shipped</td>
                <td>{total.shipped_data.id__count}</td>
                <td>
                  {total.shipped_data.line_item_total__sum === null
                    ? 0
                    : total.shipped_data.line_item_total__sum}
                </td>
              </tr>
              <tr>
                <td>Created</td>
                <td>{total.created_data.id__count}</td>
                <td>
                  {total.created_data.line_item_total__sum === null
                    ? 0
                    : total.created_data.line_item_total__sum}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Total</b>
                </td>
                <td>{total.recent_data.id__count}</td>
                <td>{total.recent_data.line_item_total__sum} €</td>
              </tr>
            </tbody>
          </Table>
          </div>
        </Fragment>
      )}
      <hr></hr>
      <Table {...getTableProps()} size="sm" bordered>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {generateSortingIndicator(column)}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              // <tr {...row.getRowProps()}>
              //   {row.cells.map((cell) => {
              //     return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              //   })}
              // </tr>
              <Fragment key={row.getRowProps().key}>
                <tr>
                  {row.cells.map((cell) => {
                    // console.log(row);
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
                {row.isExpanded && (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {renderRowSubComponent(row)}
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </Table>

      <Row style={{ maxWidth: 1000, margin: "0 auto", textAlign: "center" }}>
        <Col md={3}>
          <Button
            color="primary"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </Button>
          <Button
            color="primary"
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            {"<"}
          </Button>
        </Col>
        <Col md={2} style={{ marginTop: 7 }}>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </Col>
        <Col md={2}>
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>
        <Col md={2}>
          <CustomInput
            type="select"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </CustomInput>
        </Col>
        <Col md={3}>
          <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
            {">"}
          </Button>
          <Button
            color="primary"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default OrderTable;
