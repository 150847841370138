import React, { useState } from "react";
import Modal from "react-modal";
import { Button } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { API } from "config";
import Warehouse from "./Warehouse";
import "./WarehousePopup.css";
const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
}));
function WarehousePopup({ show, handleClose, warehousesCopy, store_id }) {
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
        },
    };

    const classes = useStyles();
    const [warehouses, setWarehouses] = useState(warehousesCopy);
    const [WarehouseName, setWarehouseName] = useState("");
    const AddWarehouse = async () => {
        const jwt = JSON.parse(localStorage.getItem("jwt"));
        let data =
            jwt !== null
                ? {
                      method: "GET",
                      headers: {
                          Authorization: `Token ${jwt.token}`,
                      },
                  }
                : {};
        let URL = `${API}/stores/add/warehouse/`;
        let form_data = new FormData();
        form_data.append("store_id", store_id);
        form_data.append("name", WarehouseName);
        const update = await axios.post(URL, form_data, data);
        setWarehouses([
            ...warehouses,
            { name: update.data.name, id: update.data.id },
        ]);
        setWarehouseName("");
        // setSuccessShow(true);
    };
    const onClickAddWarehouse = (event) => {
        console.log("onClickAddIngredient", WarehouseName);

        AddWarehouse();
    };
    const handleChangeWarehouse = (event) => {
        setWarehouseName(event.target.value);
    };
    return (
        <div>
            <Modal
                isOpen={show}
                onRequestClose={handleClose}
                style={customStyles}
                id="warePopup"
                contentLabel="Warehouse"
                ariaHideApp={false}
            >
                <h2 className="text-center my-3">Warehouses</h2>
                <div className="closeBtn" onClick={handleClose}>
                    <i className="fa fa-times"></i>
                </div>
                <ul className="row justify-content-center">
                    {warehouses !== undefined &&
                        warehouses.map((item, key) => (
                            <div className="form-group col-11 mb-2">
                                <li style={{ display: "block" }}>
                                    <Warehouse
                                        item={item}
                                        store_id={store_id}
                                        setWarehouses={setWarehouses}
                                    />
                                </li>
                            </div>
                        ))}
                    <div className="form-group col-10 mt-4 mb-3 ">
                        <li>
                            <input
                                type="text"
                                value={WarehouseName}
                                onChange={handleChangeWarehouse}
                                // onBlur={handleonBlurUpdateIngr}
                                // onKeyPress={handleKeyPressIngr}
                                placeholder="Type warehouse"
                                size="20"
                                className="form-control col-10 d-inline-block"
                            ></input>
                            <i
                                className="fa fa-plus-circle add ml-2"
                                style={{
                                    color: "green",
                                    fontSize: "24px",
                                    // lineHeight: "38px",
                                }}
                                onClick={onClickAddWarehouse}
                            ></i>
                        </li>
                    </div>
                </ul>
                <div className="row justify-content-center">
                    <Button
                        variant="contained"
                        className={`${classes.button} col-5`}
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </div>

                {/* <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<DeleteIcon />}
          onClick={handleDelete}
        >
          Delete
        </Button> */}
            </Modal>
        </div>
    );
}
export default WarehousePopup;
