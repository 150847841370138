import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Layout from "components/Layout/Layout";
import Select from "react-select";
import { API } from "../../config";
import "./NewProduct.css";
import axios from "axios";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SuccessMessage from "components/SuccessMessage/SuccessMessage";
import NumberFormat from "react-number-format";
import TextareaAutosize from "react-textarea-autosize";
import Button from "@material-ui/core/Button";
import MomentUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import MultiImage from "components/MultiImages/MultiImages";
import Tags from "components/Tags/Tags";

const NewProduct = (props) => {
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [product, setProduct] = useState({});

  const [isLogin, setIsLogin] = useState(true);
  const history = useHistory();
  const jwt = JSON.parse(localStorage.getItem("jwt"));
  // const [token, setToken] = useState(null);
  const [jsonData, setJsonData] = useState({});
  const [descriptionTitle, setDescriptionTitle] = useState("");
  const [description, setDescription] = useState("");
  const [categories, setCategories] = useState([]);
  const [measuereUnit, setMeasuereUnit] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedCategory, setSelectCategory] = useState(null);
  const [selectedSubCategory, setSelectSubCategory] = useState(null);
  const [selectedSubSubCategory, setSelectSubSubCategory] = useState(null);
  const [selectedSubSubSubCategory, setSelectSubSubSubCategory] = useState(
    null
  );
  useEffect(() => {
    const jwt = JSON.parse(localStorage.getItem("jwt"));
    let data =
      jwt !== null
        ? {
            method: "GET",
            headers: {
              Authorization: `Token ${jwt.token}`,
            },
          }
        : {};
    var URL_GET_Product = `${API}/products/owner/details/${props.match.params.slug}`;
    axios
      .get(URL_GET_Product, data)
      .then((response) => {
        setProduct(response.data.result);
        setJsonData({ ...jsonData, store: response.data.result.store });
        console.log("product", response.data.result);
      })
      .catch((error) => {
        let errObj = JSON.parse(JSON.stringify(error));
        console.log("products/owner/details---------741", errObj);
        // setIsLogin(false);
      });

    var URL_measuere_unit = `${API}/products/get/measuere_unit/`;
    axios
      .get(URL_measuere_unit, data)
      .then((response) => {
        console.log("measuere_unit", response.data.result);
        setMeasuereUnit(response.data.result);
        // setSelectedUnit(response.data.result[0]);
      })
      .catch((error) => {
        let errObj = JSON.parse(JSON.stringify(error));
        console.log("products/owner/measuere_unit---------741", errObj);
        // setIsLogin(false);
      });
  }, []);
  const handleChangeUnit = (Unit) => {
    console.log("Unit", Unit);
    setSelectedUnit(Unit);
    setJsonData({ ...jsonData, unit: Unit.value });
  };
  const handleOnBlurUnit = (Unit) => {
    // setSelectedUnit(Unit);
    console.log("Unitmm", selectedUnit.value);
    updateProduct(jsonData);
  };
  useEffect(() => {
    console.log("Change Product", product);
    setName(product.name);
    setCode(product.code);
    setDescriptionTitle(product.descriptionTitle);
    setDescription(product.description);
    setSelectedBrand(product.brand);
    setSelectCategory(product.category);
    setSelectSubCategory(product.sub_category);
    setSelectSubSubCategory(product.sub_sub_category);
    setSelectSubSubSubCategory(product.sub_sub_sub_category);
    setQuantity(product.quantity);
    setSelectedUnit(product.measure_unit);
    setPrice(product.price);
    setDiscount(product.offer_price);
    setPercent(
      parseFloat(
        (100 * (parseFloat(product.price) - parseFloat(product.offer_price))) /
          parseFloat(product.price)
      )
    );
    if (product.discount !== undefined) {
      setSelectedDateStart(product.discount.start_date);
      setSelectedDateEnd(product.discount.end_date);
      setDateStart(
        dateFormat(product.discount.start_date, "dd-mm-yyyy hh:MM:ss")
      );
      setDateEnd(dateFormat(product.discount.end_date, "dd-mm-yyyy hh:MM:ss"));
    }
    if (product.madein_kosova !== undefined) {
      setState({
        ...state,
        madeinKosova: product.madein_kosova,
      });
    }
  }, [product]);

  useEffect(() => {
    if (categories.length > 0 && selectedCategory !== null) {
      setSubCategories(
        categories.filter((item) => item.value === selectedCategory.value)[0]
          .sub_categories
      );
    }
  }, [selectedCategory]);
  //   const header = {
  //     Authorization: `Token ${token}`,
  //   };
  const department = localStorage.getItem("branch") || "";
  const [state, setState] = useState({
    madeinKosova: false,
  });
  //   const storeId = localStorage.getItem("id") || "";
  const link = localStorage.getItem("slug") || "";
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [percent, setPercent] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [selectedBrand, setSelectedBrand] = useState(null);
  let today = new Date();
  var dateFormat = require("dateformat");

  const [selectedDateStart, setSelectedDateStart] = useState(today);
  const [selectedDateEnd, setSelectedDateEnd] = useState(today);
  const [dateEnd, setDateEnd] = useState(
    dateFormat(today, "dd-mm-yyyy hh:MM:ss")
  );
  const [dateStart, setDateStart] = useState(
    dateFormat(today, "dd-mm-yyyy hh:MM:ss")
  );
  const [brands, setBrands] = useState([]);

  const [subCategories, setSubCategories] = useState([]);
  const [subSubCategories, setSubSubCategories] = useState([]);
  const [subSubSubCategories, setSubSubSubCategories] = useState([]);
  //   const [Img, setImg] = useState(null);
  //   const [imgStr, setImgStr] = useState("");
  //   const [variations, setVariations] = useState();
  const [successShow, setSuccessShow] = useState(false);

  const [error, setError] = useState({
    category: false,
    price: false,
  });
  const updateProduct = async (new_product) => {
    // const jwt = JSON.parse(localStorage.getItem("jwt"));
    const token = jwt.token;
    const header = {
      Authorization: `Token ${token}`,
    };
    console.log("new_product", new_product);
    var URL = `${API}/products/${product.id}/update/draft/`;

    axios
      .put(
        URL,
        { new_product },
        {
          headers: header,
        }
      )
      .then((res) => {
        console.log("res", res.status);
        console.log("res.data", res.data);
        setProduct(res.data.result);
      })
      .catch((error) => {
        let errObj = JSON.parse(JSON.stringify(error));
        console.log("/update/draft/----------741", errObj);
        if (error.response.status === 401) {
          setIsLogin(false);
        }
      });

    setSuccessShow(true);
  };
  useEffect(() => {
    if (successShow) {
      setTimeout(function () {
        setSuccessShow(false);
      }, 2000);
    }
  }, [successShow]);
  useEffect(() => {
    var URL_GET_Categories = `${API}/${department}/categories`;
    axios.get(URL_GET_Categories).then((response) => {
      setCategories(response.data.result);
      console.log("Muhamed", response.data.result);
    });
    if (department === "techno") {
      var URL_GET_Brends = `${API}/products/brends`;
      axios.get(URL_GET_Brends).then((response) => {
        setBrands(response.data.result);
        // console.log("Brends", response.data.result);
      });
    }
  }, [department]);

  const handleChangeBrand = (selectedBrand) => {
    setSelectedBrand(selectedBrand);

    setJsonData({ ...jsonData, brand: selectedBrand.value });

    console.log(`selectedBrand:`, selectedBrand);
  };
  const handleOnBlurBrand = () => {
    console.log("handleOnBlurBrand", selectedBrand);
    updateProduct(jsonData);
    console.log("--------------------------------", jsonData);
  };
  const handleChangeCategory = (selectedCategory) => {
    console.log("ccccccccccccccccccccc", selectedCategory);
    setSelectCategory(selectedCategory);

    setSubCategories(
      categories.filter((item) => item.id === selectedCategory.id)[0]
        .sub_categories
    );
    console.log(`Category:`, selectedCategory);
    setJsonData({ ...jsonData, category: selectedCategory.id });
    setSelectSubCategory(null);
    setSelectSubSubCategory(null);
    setSelectSubSubSubCategory(null);
  };
  const handleOnBlurCategory = () => {
    updateProduct(jsonData);
  };
  const handleChangeSubCategory = (selectedSubCategory) => {
    setSelectSubCategory(selectedSubCategory);
    setSubSubCategories(
      subCategories.filter((item) => item.id === selectedSubCategory.id)[0]
        .sub_sub_categories
    );
    console.log(`SubCategory:`, selectedSubCategory);
    setJsonData({ ...jsonData, sub_category: selectedSubCategory.id });
    setSelectSubSubCategory(null);
    setSelectSubSubSubCategory(null);
  };
  const handleOnBlurSubCategory = () => {
    updateProduct(jsonData);
  };
  const handleChangeSubSubCategory = (selectedSubSubCategory) => {
    setSelectSubSubCategory(selectedSubSubCategory);
    setSubSubSubCategories(
      subSubCategories.filter(
        (item) => item.id === selectedSubSubCategory.id
      )[0].sub_sub_sub_categories
    );
    console.log(`SubSubCategory:`, selectedSubSubCategory);
  };
  const handleChangeSubSubSubCategory = (selectedSubSubSubCategory) => {
    setSelectSubSubSubCategory(selectedSubSubSubCategory);
    console.log(`SubSubSubCategory:`, selectedSubSubSubCategory);
  };
  const handleChangeProductPrice = (event) => {
    setPrice(event.target.value.slice(0, -2));
    if (parseInt(event.target.value.slice(0, -2)) === 0) {
      setError({ ...error, price: true });
    } else {
      setError({ ...error, price: false });
      setJsonData({
        ...jsonData,
        price: event.target.value.slice(0, -2),
      });
    }
  };
  const handleonBlurUpdateProductPrice = () => {
    updateProduct(jsonData);
  };

  //   useEffect(() => {
  //     if (imgStr !== "") {
  //       let data = {};
  //       data["store"] = product.store;
  //       data["image"] = imgStr;
  //       updateProduct(data);
  //     }
  //   }, [imgStr]);
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    setJsonData({ ...jsonData, madein_kosova: event.target.checked });
    console.log("nameeeeee", event.target.checked, event.target.name);
    if (event.target.name !== "LimitProdQuantity") {
      // updateProduct(event.target.name, event.target.checked);
    }
  };
  const handleBlurMadeinKosova = () => {
    console.log("handleBlurMadeinKosova***************************", jsonData);
    updateProduct(jsonData);
  };
  const handleChangeProductQuantity = (event) => {
    console.log("Muhamed", event.target.value);
    setQuantity(event.target.value);
  };
  const handleonBlurUpdateProductQuantity = (event) => {
    let data = {};
    console.log("event.target.value", event.target.value);
    data["store"] = product.store;
    data["quantity"] = event.target.value;
    updateProduct(data);
  };
  const handleDateChangeStart = (event) => {
    setSelectedDateStart(event);
    console.log("-*************---", event._d);
    setDateStart(dateFormat(event._d, "dd-mm-yyyy hh:MM:ss"));
  };

  const updateDiscount = () => {
    let data = {};
    if (selectedDateStart >= selectedDateEnd) {
      setError({ ...error, dateStartEnd: true });
    } else {
      setError({ ...error, dateStartEnd: false });
      data["discount_start"] = dateStart;
      data["store"] = product.store;
      data["discount"] = discount;
      data["percent"] = percent;
      data["discount_end"] = dateEnd;
      console.log("data", data);
      updateProduct(data);
    }
  };

  const handleonBlurDateStart = (event) => {
    setDateStart(event.target.value);
    updateDiscount();
  };

  const handleDateChangeEnd = (event) => {
    setSelectedDateEnd(event);
    setDateEnd(dateFormat(event._d, "dd-mm-yyyy hh:MM:ss"));
  };

  const handleonBlurDateEnd = (event) => {
    // let data = {};
    console.log("eventttt", event.target.value);
    setDateEnd(event.target.value);
    updateDiscount();
  };
  const handleChangeProductDiscount = (event) => {
    setDiscount(parseFloat(event.target.value.slice(0, -2)));
  };
  const handleonBlurUpdateProductDiscount = (event) => {
    updateDiscount();
  };
  const handleKeyPressProductDiscount = (event) => {
    updateDiscount();
  };

  const handleChangeProductPercent = (event) => {
    setPercent(parseFloat(event.target.value.slice(0, -2)));
  };
  const handleonBlurUpdateProductPercent = (event) => {
    updateDiscount();
  };
  const handleKeyPressProductPercent = (event) => {
    updateDiscount();
  };
  useEffect(() => {
    if (isNaN(percent)) {
      setDiscount(0);
    } else {
      setDiscount(
        parseFloat(
          parseFloat(price) - (parseFloat(price) / 100) * parseFloat(percent)
        ).toFixed(2)
      );
    }
    console.log("selectedDateStart", selectedDateStart);
    if (selectedDateStart === undefined) {
      setSelectedDateStart(today);
      setSelectedDateEnd(today);
      setDateEnd(dateFormat(today, "dd-mm-yyyy hh:MM:ss"));
      setDateStart(dateFormat(today, "dd-mm-yyyy hh:MM:ss"));
    }
  }, [percent]);

  useEffect(() => {
    console.log("ddddddd", dateStart, dateEnd);
  }, [dateStart]);

  useEffect(() => {
    if (isNaN(discount)) {
      setPercent(0);
    } else {
      setPercent(
        parseFloat(
          ((parseFloat(price) - parseFloat(discount)) / parseFloat(price)) * 100
        ).toFixed(2)
      );
    }
  }, [discount, price]);

  const handleChangeProductName = (e) => {
    setName(e.target.value);
  };
  const handleonBlurProductName = () => {
    let data = {};
    data["store"] = product.store;
    data["name"] = name;
    updateProduct(data);
  };
  const handleChangeProductCode = (e) => {
    setCode(e.target.value);
  };
  const handleonBlurProductCode = () => {
    let data = {};
    data["store"] = product.store;
    data["code"] = code;
    updateProduct(data);
  };
  const onClickBack = () => {
    history.push(`/${link}`);
    // console.log("onClickBack", link);
  };
  const handleChangeDescriptionTitle = (event) => {
    setDescriptionTitle(event.target.value);
    setJsonData({ ...jsonData, descriptionTitle: event.target.value });
  };
  const handleonBlurUpdateStoreName = () => {
    updateProduct(jsonData);
  };
  const onChangeDescription = (event) => {
    setDescription(event.target.value);
    setJsonData({ ...jsonData, description: event.target.value });
  };
  const onBlurDescription = () => {
    updateProduct(jsonData);
  };
  const onClickBtnSave = () => {
    console.log("onClickBtnSave");
    // let jsonData2 = { ...jsonData };
    let error2 = { ...error };

    name === "" ? (error2.name = true) : (error2.name = false);
    console.log("selectedCategory", selectedCategory);
    selectedCategory === null
      ? (error2.category = true)
      : (error2.category = false);

    parseInt(price) === 0 ? (error2.price = true) : (error2.price = false);
    console.log("selectedDateStart", selectedDateStart);
    console.log("selectedDateEnd", selectedDateEnd);
    if (parseInt(discount) > parseInt(price)) {
      error2.discount = true;
    } else {
      error2.discount = false;
    }
    if (parseInt(discount) > 0) {
      if (selectedDateStart >= selectedDateEnd) {
        error2.dateStartEnd = true;
      } else {
        error2.dateStartEnd = false;
      }
    }
    let success = true;
    setError(error2);
    for (var i in error2) {
      if (error2[i] === true) {
        success = false;
        break;
      }
    }
    if (success) {
      console.log("save");
      let data = {};
      data["store"] = product.store;
      data["draft"] = false;
      updateProduct(data);
      console.log("link", link);
      history.push(`/${link}`);
    }
  };
  useEffect(() => {
    console.log("setQuantity", quantity);
  }, [quantity]);

  return (
    <Layout>
      {!isLogin && (
        <Redirect
          to={{
            pathname: "/login",
            // state: { from: props.location },
          }}
        />
      )}
      {/* onSubmit={handleSubmit} */}
      <div className="container">
        {" "}
        <div className="row">
          <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 mb-1">
            <legend className="text-center mt-3">New Product Draft</legend>
          </div>
          <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-1">
            <div className="row mt-3">
              <div className="col-sm">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={onClickBack}
                  className="mr-2"
                  // className={classes.button}
                  startIcon={<ArrowBackIcon />}
                >
                  Back
                </Button>
              </div>
              <div className="col-sm">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  type="submit"
                  onClick={onClickBtnSave}
                  // className={classes.button}
                  // startIcon={<SaveIcon />}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 mb-1 row">
            {/* prvi */}
            <div className="titleContainer col-12 col-md-8">
              <span className="font-weight-bold">Name</span>
              <div className="titleDiv">
                <input
                  type="text"
                  value={name}
                  name="name"
                  className="inputName"
                  onChange={handleChangeProductName}
                  onBlur={handleonBlurProductName}
                  // onKeyPress={handleKeyPress}
                />
              </div>
              {error.name && (
                <div className="w-100">
                  <span className="errorMsg">* Name should not be empty.</span>
                </div>
              )}
            </div>

            <div className="col-12 col-md-4 pt-3">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.madeinKosova}
                    onChange={handleChange}
                    onBlur={handleBlurMadeinKosova}
                    name="madeinKosova"
                    color="primary"
                  />
                }
                label="Made in Kosova"
              />
            </div>
            <div className="titleContainer col-12 col-md-8">
              <span className="font-weight-bold">Code</span>
              <div className="titleDiv">
                <input
                  type="text"
                  value={code}
                  name="code"
                  className="inputName"
                  onChange={handleChangeProductCode}
                  onBlur={handleonBlurProductCode}
                  // onKeyPress={handleKeyPress}
                />
              </div>
              {error.name && (
                <div className="w-100">
                  <span className="errorMsg">* Name should not be empty.</span>
                </div>
              )}
            </div>
            {brands.length > 0 && (
              <div className="my-2">
                <div className="col-12">
                  <span className="font-weight-bold">Product brand</span>
                </div>
                <div className="col-6">
                  <Select
                    value={selectedBrand}
                    onChange={handleChangeBrand}
                    onBlur={handleOnBlurBrand}
                    options={brands}
                    name="brand"
                    placeholder="Select Brend"
                  />
                </div>
              </div>
            )}
            <div className="col-12 col-md-6">
              <div className="w-100 mb-1">
                <span className="font-weight-bold">Category</span>
              </div>
              {/* <div className="col-12 mb-1">
                <span className="font-weight-bold">Category</span>
              </div> */}
              <div className="w-100 mb-1">
                <Select
                  value={selectedCategory}
                  onChange={handleChangeCategory}
                  onBlur={handleOnBlurCategory}
                  options={categories}
                  isClearable={true}
                  name="category"
                  placeholder="Category"
                />
              </div>
              <div className="w-100 mb-1">
                {subCategories.length > 0 && selectedCategory !== null && (
                  <Select
                    value={selectedSubCategory}
                    onChange={handleChangeSubCategory}
                    onBlur={handleOnBlurSubCategory}
                    name="subCategory"
                    isClearable={true}
                    options={
                      categories.filter(
                        (item) => item.value === selectedCategory.value
                      )[0].sub_categories
                    }
                    placeholder="Sub category"
                  />
                )}
              </div>
              <div className="w-100 mb-1">
                {subSubCategories.length > 0 &&
                  selectedSubCategory !== null && (
                    <Select
                      value={selectedSubSubCategory}
                      onChange={handleChangeSubSubCategory}
                      name="subSubCategory"
                      isClearable={true}
                      placeholder="Sub sub category"
                      options={
                        subCategories.filter(
                          (item) => item.value === selectedSubCategory.value
                        )[0].sub_sub_categories
                      }
                    />
                  )}
              </div>
              <div className="w-100 mb-1">
                {subSubSubCategories.length > 0 &&
                  selectedSubSubCategory !== null && (
                    <Select
                      value={selectedSubSubSubCategory}
                      onChange={handleChangeSubSubSubCategory}
                      name="subSubSubCategory"
                      isClearable={true}
                      placeholder="Sub sub sub category"
                      options={
                        subSubCategories.filter(
                          (item) => item.value === selectedSubSubCategory.value
                        )[0].sub_sub_sub_categories
                      }
                    />
                  )}
              </div>
            </div>
            {error.category && (
              <div className="col-12">
                <span className="errorMsg">
                  * Category should not be empty.
                </span>
              </div>
            )}

            <div className="col-12">
              <span className="font-weight-bold">Description title</span>
              <div className="titleContainer">
                <div className="titleDiv">
                  <input
                    type="text"
                    name="descriptionTitle"
                    value={descriptionTitle}
                    className="inputName"
                    onChange={handleChangeDescriptionTitle}
                    onBlur={handleonBlurUpdateStoreName}
                    // onKeyPress={handleKeyPress}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <span className="font-weight-bold">Description</span>
              <TextareaAutosize
                maxRows={6}
                style={{ width: "100%" }}
                name="description"
                onChange={onChangeDescription}
                onBlur={onBlurDescription}
                value={description}
                // defaultValue={product.description}
              />
            </div>

            <div className="col-12">
              <span className="font-weight-bold">Quantity</span>
            </div>
            <div className="col-12">
              <NumberFormat
                // itemDiscount errorDiscount
                value={quantity}
                // className="itemPrice"
                thousandSeparator={false}
                inputMode="numeric"
                decimalSeparator={"."}
                format="######"
                onFocus={(e) => e.target.select()}
                name="quantity"
                onChange={handleChangeProductQuantity}
                onBlur={handleonBlurUpdateProductQuantity}
                className="col-6 col-md-3 mr-3 d-inline-block"
                // onKeyPress={handleKeyPressProductPrice}
              />

              <Select
                value={selectedUnit}
                onChange={handleChangeUnit}
                onBlur={handleOnBlurUnit}
                options={measuereUnit}
                isClearable={true}
                name="measuereUnit"
                placeholder="Measuere unit"
                className="col-6 col-md-4 d-inline-block"
              />
            </div>
            <div className="col-12">
              <div className="w-100">
                <span className="font-weight-bold">Price</span>
              </div>
              <div className="w-100">
                <NumberFormat
                  value={price}
                  // className="itemPrice"
                  thousandSeparator={false}
                  inputMode="numeric"
                  decimalSeparator={"."}
                  onFocus={(e) => e.target.select()}
                  suffix={" €"}
                  name="price"
                  onChange={handleChangeProductPrice}
                  onBlur={handleonBlurUpdateProductPrice}
                  className="col-6 col-md-3"
                  // onKeyPress={handleKeyPressProductPrice}
                />
              </div>

              {error.price && (
                <div className="w-100">
                  <span className="errorMsg">
                    * Price must be greater than zero.
                  </span>
                </div>
              )}
            </div>

            <div className="col-12">
              <div className="w-100">
                <span className="font-weight-bold">Discount</span>
              </div>
              <div className="w-50 discountDivv">
                <div className="">
                  <NumberFormat
                    // itemDiscount errorDiscount
                    value={discount}
                    className={
                      parseInt(discount) >= parseInt(price) &&
                      parseInt(price) > 0
                        ? "itemDiscount errorDiscount"
                        : "itemDiscount"
                    }
                    thousandSeparator={false}
                    inputMode="numeric"
                    decimalSeparator={"."}
                    fixedDecimalScale={true}
                    onFocus={(e) => e.target.select()}
                    suffix={" €"}
                    name="discount"
                    onChange={handleChangeProductDiscount}
                    onBlur={handleonBlurUpdateProductDiscount}
                    onKeyPress={handleKeyPressProductDiscount}
                    className="w-100"
                  />
                </div>
                <div className="">
                  <NumberFormat
                    // itemDiscount errorDiscount
                    value={percent >= 100 ? 0 : percent}
                    className={
                      parseInt(discount) >= parseInt(price) &&
                      parseInt(price) > 0
                        ? "itemDiscount errorDiscount"
                        : "itemDiscount"
                    }
                    thousandSeparator={false}
                    inputMode="numeric"
                    decimalSeparator={"."}
                    onFocus={(e) => e.target.select()}
                    suffix={" %"}
                    format="## %"
                    name="percent"
                    onChange={handleChangeProductPercent}
                    onBlur={handleonBlurUpdateProductPercent}
                    onKeyPress={handleKeyPressProductPercent}
                    className="w-100"
                  />
                </div>

                <div className="">
                  {parseInt(discount) <= parseInt(price) && discount > 0 && (
                    <div>
                      <div className="w-100">
                        <span className="font-weight-bold">Start</span>
                      </div>
                      <div className="">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DateTimePicker
                            value={selectedDateStart}
                            onChange={handleDateChangeStart}
                            onBlur={handleonBlurDateStart}
                            format="DD-MM-YYYY hh:mm:ss"
                            name="discountDateStart"
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  )}
                </div>
                <div className="">
                  {parseInt(discount) <= parseInt(price) && discount > 0 && (
                    <div className="">
                      <div className="w-100">
                        <span className="font-weight-bold">End</span>
                      </div>
                      <div className="">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DateTimePicker
                            value={selectedDateEnd}
                            onChange={handleDateChangeEnd}
                            onBlur={handleonBlurDateEnd}
                            format="DD-MM-YYYY hh:mm:ss"
                            name="discountDateEnd"
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* end date must be greater than start date */}
            {parseInt(discount) >= parseInt(price) && parseInt(price) > 0 && (
              <div className="col-12">
                <span className="errorMsg">
                  * Discount should be smaller than price.
                </span>
              </div>
            )}
            {error.dateStartEnd && (
              <div className="col-12">
                <span className="errorMsg">
                  * End date must be greater than start date.
                </span>
              </div>
            )}

            <div className="my-2 col-12">
              <Link
                to={{
                  pathname: `/variations/${product.slug}`,

                  product: product,
                  url: `/product/${product.slug}`,
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  className="btn btn-lg py-2"
                >
                  Create variations
                </Button>
              </Link>
            </div>

            <div className="col-12 my-4 varoll">
              <MultiImage
                product_id={product.id}
                gallery={product.gallery}
              ></MultiImage>
            </div>
          </div>
          <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-1">
            <div className="row mt-4">
              <div className="col-12">
                <Tags product_id={product.id} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessMessage show={successShow}></SuccessMessage>
    </Layout>
  );
};
export default NewProduct;
