import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
// import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
// import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
// import Switch from "@material-ui/core/Switch";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import { API } from "../config";
// import axios from "axios";
import { Link } from "react-router-dom";
import getImage from "functions/getImage";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function StoreCard(props) {
  const classes = useStyles();
  const { name, logo, background, active, slug } = props.store;

  // const [state, setState] = React.useState({
  //   checkedA: active,
  //   checkedB: active,
  // });
  // const jwt = JSON.parse(localStorage.getItem("jwt"));
  // const token = jwt.token;
  // const updateStore = async (id, active) => {
  //   //<int:pk>/update/store/
  //   console.log(id, active, token);
  //   const header = {
  //     Authorization: `Token ${token}`,
  //   };
  //   var URL = `${API}/${id}/update/store/`;
  //   let form_data = new FormData();

  //   form_data.append("active", active);
  //   form_data.append("name", name);

  //   const update = await axios.put(URL, form_data, {
  //     headers: header,
  //   });
  // };
  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  //   updateStore(id, event.target.checked);
  // };
  return (
    <Card className={classes.root}>
      <Link to={"/" + slug}>
        <CardHeader
          avatar={
            <Avatar
              aria-label="recipe"
              src={getImage(logo)}
              className={classes.avatar}
            >
              S
            </Avatar>
          }
          title={name}
          subheader=""
        />
        <CardMedia
          className={classes.media}
          image={getImage(background)}
          title={name}
        />
      </Link>
      {active ? (
        ""
      ) : (
        <CardContent>
          <span className="errorMsg">
            Your store is not active please contact the administrator.
          </span>
        </CardContent>
      )}

      {/* <CardActions disableSpacing>
        <FormControlLabel
          value="active"
          control={
            <Switch
              color="primary"
              name="checkedA"
              onChange={handleChange}
              checked={state.checkedA}
            />
          }
          label="Active"
          labelPlacement="end"
        />
      </CardActions> */}
    </Card>
  );
}
