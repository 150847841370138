import React, { useMemo, useState } from "react";

import "./StoreInfo.css";
import StoreImages from "components/StoreImages/StoreImages";
import CategoryButtons from "../CategoryButtons/CategoryButtons";
import ProductCard from "../Product/ProductCard/ProductCard";
import { API } from "config";
import axios from "axios";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import AppsIcon from "@material-ui/icons/Apps";
import TocIcon from "@material-ui/icons/Toc";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ProductTable from "../Product/Table/ProductTable";
import { Table, Card } from "reactstrap";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import DeletePopup from "../../DeletePopup/DeletePopup";
import NewProductPopup from "components/NewProductPopup/NewProductPopup";
import { Redirect } from "react-router-dom";
const StoreInfo = ({
  storeInfos,
  currentCategory,
  changeCategory,
  changeSearch,
  categories,
  products1,
  setProducts,
  productsDraft,
  setProductsDraft,
  cart,
  // showPopup,
  setCategories,
  setCurrentCategory,
  setSuccessShow,
}) => {
  const Genres = ({ values }) => {
    return (
      <>
        {values.map((genre, idx) => {
          return (
            <span key={idx} className="badge">
              {genre.value.split("<->")[1]}
            </span>
          );
        })}
      </>
    );
  };

  const renderRowSubComponent = (row) => {
    const { product_variants } = row.original;
    console.log("MuhamedSezairi", product_variants);
    return (
      <Card style={{ width: "18rem", margin: "0 auto" }}>
        <strong className="text-center"> Varations </strong>
        <Table size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Variant(s)</th>
              <th>Price €</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            {product_variants.map((item, key) => (
              <tr>
                <th scope="row">{key + 1}</th>
                <td>
                  <Genres values={item.values} />
                </td>
                <td>{item.price}</td>
                <td>{item.count}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
    );
  };

  const columns1 = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander", // 'id' is required
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? "👇" : "👉"}
          </span>
        ),
      },
      {
        Header: "#",
        id: "row",
        maxWidth: 50,
        filterable: false,
        Cell: (row) => {
          return (
            <span style={{ fontWeight: "bold" }}>{row.row.index + 1}</span>
          );
        },
      },
      {
        Header: "Code",
        accessor: "code",
      },
      {
        Header: "Title",
        accessor: "name",
      },
      {
        Header: "Price",
        accessor: "price",
      },

      {
        Header: "Desconto",
        accessor: "offer_price",
      },

      {
        Header: "Quantity",
        accessor: "quantity",
      },
      {
        Header: "Actions",
        disableSortBy: true,
        accessor: "id",

        Cell: (row) => (
          <React.Fragment>
            <Link
              to={{
                pathname: `/update_product/${row.cell.row.original.slug}`,

                product: row.cell.row.original,
              }}
            >
              {" "}
              <EditIcon />
            </Link>
            <DeleteForeverIcon
              onClick={() => {
                openDeleteModal(row.cell.row.original);
              }}
            ></DeleteForeverIcon>
          </React.Fragment>
        ),
      },
    ],
    []
  );
  const [forDelete, setForDelete] = useState({});
  function openDeleteModal(forDel) {
    setForDelete(forDel);
    setShowDeleteModel(true);
  }

  const deleteProduct = () => {
    delProduct(forDelete.id);

    setShowDeleteModel(false);
  };
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [showNewProductModel, setShowNewProductModel] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [showTable, setShowTable] = useState(true);

  const [storeName, setStoreName] = useState(storeInfos.name);
  const [isLogin, setIsLogin] = useState(true);
  const jwt = JSON.parse(localStorage.getItem("jwt"));
  const token = jwt.token;
  const header = {
    Authorization: `Token ${token}`,
  };

  const updateProduct = async (id, active) => {
    const header = {
      Authorization: `Token ${token}`,
    };
    var URL = `${API}/${id}/product/update/`;
    let form_data = new FormData();

    form_data.append("active", active);

    const newProducts = await axios.put(URL, form_data, {
      headers: header,
    });
    setProducts(newProducts.data.filter((item) => item.draft === false));
  };

  const delProduct = async (id) => {
    let URL = `${API}/${id}/product/update/`;
    axios
      .delete(URL, {
        headers: header,
      })
      .then((res) => {
        console.log("res", res.status);
        console.log("res.data", res.data);
        setProducts(res.data.filter((item) => item.draft === false));
      })
      .catch((error) => {
        let errObj = JSON.parse(JSON.stringify(error));
        console.log("-------------------------741", errObj);
        if (error.response.status === 401) {
          setIsLogin(false);
        }
      });
  };

  const updateStoreName = async () => {
    const header = {
      Authorization: `Token ${token}`,
    };
    var URL = `${API}/${storeInfos.id}/update/store/`;
    let form_data = new FormData();

    form_data.append("active", storeInfos.active);
    form_data.append("name", storeName);

    axios
      .put(URL, form_data, {
        headers: header,
      })
      .then((res) => {
        console.log("res", res.status);
        console.log("res.data", res.data);
      })
      .catch((error) => {
        let errObj = JSON.parse(JSON.stringify(error));
        console.log("-------------------------741", errObj);
        if (error.response.status === 401) {
          setIsLogin(false);
        }
      });
    setSuccessShow(true);
  };
  const handleonBlurUpdateStoreName = (event) => {
    updateStoreName();
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      updateStoreName();
    }
  };
  const handleChangeStoreName = (event) => {
    setStoreName(event.target.value);
  };
  const ShowCart = (event) => {
    setShowCart(true);
    setShowTable(false);
  };
  const ShowTable = (event) => {
    setShowTable(true);
    setShowCart(false);
  };
  const handleonClickAddProduct = () => {
    setShowNewProductModel(true);
  };
  return (
    <React.Fragment>
      {!isLogin && (
        <Redirect
          to={{
            pathname: "/login",
          }}
        />
      )}
      <StoreImages storeInfos={storeInfos} setSuccessShow={setSuccessShow} />
      <div className="titleContainer">
        <div className="titleDiv">
          <input
            type="text"
            value={storeName}
            className="inputTitle"
            onChange={handleChangeStoreName}
            onBlur={handleonBlurUpdateStoreName}
            onKeyPress={handleKeyPress}
          />
        </div>
      </div>
      <CategoryButtons
        currentCategory={currentCategory}
        changeCategory={changeCategory}
        changeSearch={changeSearch}
        storeInfos={storeInfos}
        setSuccessShow={setSuccessShow}
      />
      <div className="row mt-2 mb-2">
        <div className="col ml-4">
          <Button
            onClick={handleonClickAddProduct}
            variant="outlined"
            color="primary"
            startIcon={<AddCircleIcon />}
          >
            Add Product
          </Button>
        </div>
        <div className="col">
          <div className="float-right mr-2">
            <Button
              variant="outlined"
              color="primary"
              endIcon={<AppsIcon />}
              onClick={ShowCart}
            >
              Cart
            </Button>
          </div>
          <div className="float-right mr-2">
            <Button
              variant="outlined"
              color="primary"
              endIcon={<TocIcon />}
              onClick={ShowTable}
            >
              Table
            </Button>
          </div>
        </div>
      </div>
      {showTable && products1 !== undefined && (
        <ProductTable
          columns={columns1}
          data={products1}
          renderRowSubComponent={renderRowSubComponent}
        />
      )}

      {showCart && (
        <div className="row">
          {products1.map((item, key) => (
            <div
              className="col-md-3 col-sm-4 col-xs-6 mb-2"
              style={{ minHeight: "150px" }}
            >
              <ProductCard
                item={item}
                key={key}
                delProduct={delProduct}
                updateProduct={updateProduct}
                setSuccessShow={setSuccessShow}
              ></ProductCard>
            </div>
          ))}
        </div>
      )}

      <DeletePopup
        show={showDeleteModel}
        handleClose={() => setShowDeleteModel(false)}
        handleDelete={deleteProduct}
        product={forDelete}
        text={"product"}
      />
      <NewProductPopup
        show={showNewProductModel}
        handleClose={() => setShowNewProductModel(false)}
        productsDraft={productsDraft}
        setProductsDraft={setProductsDraft}
        id={storeInfos.id}
        slug={storeInfos.slug}
      />
    </React.Fragment>
  );
};

export default StoreInfo;
