import React from "react";

import "./Rating.css";

const Rating = ({ value }) => {
    const getWidth = () => {
        return `${(value * 100) / 5}%`;
    };
    return (
        <div className="ratingDiv">
            <div className="rating">
                <div
                    className="rating-upper"
                    style={{ width: `${getWidth()}` }}
                >
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                </div>
                <div className="rating-lower">
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                </div>
            </div>

            <span className="starText">({value} ratings)</span>
        </div>
    );
};

export default Rating;
