import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import axios from "axios";
import StoreCard from "./StoreCard";
import StoreForm from "./StoreForm";
// import AddCategory from './AddCategory';
import { Grid } from "@material-ui/core";
import { API } from "../config";
import { Link } from "react-router-dom";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `vertical-tab-${index}`,
//     "aria-controls": `vertical-tabpanel-${index}`,
//   };
// }

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "auto",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    display: "flex",
    marginLeft: "20px",
  },
  tabPanel: {
    marginLeft: "40px",
    width: "calc(100% - 200px)",
  },
}));

export default function VerticalTabs({ role }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [stores, setStores] = useState([]);

  let location = window.location.href;
  console.log("location", location);
  if (location[location.length - 1] === "/") {
    location = location.toString().slice(0, location.length - 1);
  }
  let arr = location.split("/");
  const page_name = arr[arr.length - 1];
  console.log("babababa", page_name);

  useEffect(() => {
    window.location.href.endsWith("/user/dashboard/store") &&
      document
        .querySelectorAll(
          ".MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit"
        )[0]
        .click();
    window.location.href.endsWith("/user/dashboard/add_store") &&
      document
        .querySelectorAll(
          ".MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit"
        )[1]
        .click();
  }, [window.location.href]);
  // const [loading, setLoading] = useState(false);

  const jwt = JSON.parse(localStorage.getItem("jwt"));
  const token = jwt.token;

  useEffect(() => {
    const header = {
      Authorization: `Token ${token}`,
    };
    const restaurants = `${API}/stores`;
    let form_data = new FormData();
    form_data.append("site", "mango724");
    axios
      .post(restaurants, form_data, {
        headers: header,
      })
      .then(function (response) {
        // handle success
        console.log(response);
        console.log(response.data.result, API);
        // setStores(response.data);
        setStores(response.data.result);
        console.log("stores", stores);
        // setLoading(true);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {});
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log("new value", newValue);
  };
  console.log("dadsadsadad---role", role);
  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab
          key={1}
          label="Stores"
          component={Link}
          to="/user/dashboard/store"
        />
        <Tab
          key={2}
          label="Add New Store"
          component={Link}
          to="/user/dashboard/add_store"
        />
        <Tab
          key={3}
          label="Update Profile"
          label="Add New Store"
          component={Link}
          to="/user/dashboard/user_update"
        />
        {role === true && <Tab key={4} label="Add Category" />}
      </Tabs>
      <TabPanel
        key={1}
        className={`${classes.tabPanel} pt-3`}
        value={value}
        index={0}
      >
        Stores
        <React.Fragment>
          <div className="">
            {stores.map((s, i) => (
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 d-inline-block">
                <StoreCard key={s.id} store={s} />
              </div>
            ))}
          </div>
        </React.Fragment>
      </TabPanel>
      <TabPanel key={2} className={classes.tabPanel} value={value} index={1}>
        <h3>Add New Store</h3>
        <StoreForm />
      </TabPanel>
      <TabPanel key={3} value={value} index={2}>
        Update Profile
      </TabPanel>
      {/* <TabPanel
                key={4}
                className={classes.tabPanel}
                value={value}
                index={3}
            >
                <h3>Add Category</h3>
                <AddCategory />
            </TabPanel> */}
    </div>
  );
}
