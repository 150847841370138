import React, { useMemo, useState, useEffect } from "react";

import "./Orders.css";
import Layout from "components/Layout/Layout";
import OrderTable from "./OrderTable";
import is_auth from "functions/is_auth";
import axios from "axios";
import { API } from "config";
import { Line } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import MomentUtils from "@date-io/moment";
import {
  // DateTimePicker,
  DatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Button, Label, Input, FormGroup } from "reactstrap";
const data_static = {
  labels: ["1", "2", "3", "4", "5", "6", "7"],
  datasets: [
    {
      label: "Dataset",
      fill: false,
      lineTension: 0.1,
      backgroundColor: "rgba(75,192,192,0.4)",
      borderColor: "rgba(75,192,192,1)",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "rgba(75,192,192,1)",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(75,192,192,1)",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [0, 0, 0, 0, 0, 0, 0],
    },
  ],
};
const Orders = (props) => {
  console.log("proooooooo", props.match.params.storeSlug);
  // const [data1, setData1] = useState([]);
  let history = useHistory();
  const [orders, setOrders] = useState([]);
  const renderRowSubComponent = (row) => {
    const {
      // name: { first, last },
      // location: { city, street, postcode },
      name,
      address,
      quarterName,
      cityName,
      postal_code,
      address_notes,
    } = row.original.shipping_address;
    return (
      <div
        className="addressContainer"
        style={{ width: "18rem", margin: "0 auto", display: "table" }}
      >
        <div className="adressText">
          <label>
            <b>Full Name:</b>
            <span className="ml-2">{name}</span>
          </label>
          <label>
            <b>Address:</b>
            <span className="ml-2">{address}</span>
          </label>
          <label>
            <b>City:</b>
            <span className="ml-2">{cityName}</span>
          </label>
          <label>
            <b> Quarter:</b>
            <span className="ml-2">{quarterName}</span>
          </label>
          <label>
            <b>Post Code:</b>
            <span className="ml-2">{postal_code}</span>
          </label>
          <label>
            <b>Address notes:</b>
            <span className="ml-2">{address_notes}</span>
          </label>
        </div>
      </div>
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "Order Id",
        accessor: "order_item_id",
        disableSortBy: true,
      },

      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Sale date",
        accessor: "updated",
      },
      {
        Header: "Price",
        accessor: "last_price",
      },
      {
        Header: "Quantity",
        accessor: "quantity",
      },
      {
        Header: "Shipping",
        accessor: "shipping",
      },
      {
        Header: "Total",
        accessor: "line_item_total",
      },
      {
        Header: "User email",
        accessor: "user.email",
      },
      {
        Header: "Full name",
        accessor: "user.full_name",
      },
      {
        Header: "Phone",
        accessor: "user.phone",
      },
      {
        Header: () => "Address",
        id: "expander", // 'id' is required
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? "👇" : "👉"}
          </span>
        ),
      },
      // { accessor: "cash", header: "Cash", footer: "Total: " },
    ],
    []
  );
  const [selectedDateStart, setSelectedDateStart] = useState(new Date());
  const [selectedDateEnd, setSelectedDateEnd] = useState(new Date());
  const [staticData, setStaticData] = useState(data_static);
  const [data, setData] = useState();
  // const [tableData, setTabeleData] = useState();
  const [total, setTotal] = useState();
  const getData = (val = "?type=today", value = "today") => {
    is_auth().then((data) => {
      if (data) {
        const jwt = JSON.parse(localStorage.getItem("jwt"));
        const token = jwt.token;
        const header = {
          Authorization: `Token ${token}`,
        };
        // var URL = `${API}/analytics/data/${storeId}/${val}`;
        var URL = `${API}/orders/tele724/${props.match.params.storeSlug}/get/items/${val}`;
        axios
          .get(URL, {
            headers: header,
          })
          .then((response) => {
            console.log("000", response);
            console.log("data", response.data.table.orders);
            // let new_data = [...data];
            // new_data.labels = response.data.labels;
            // new_data.datasets.data = response.data.datasets.data;
            setData(response.data);
            // setTabeleData(response.data.table.orders);

            setOrders(response.data.table.orders);
            setTotal(response.data.table.total);
            console.log("setTotal", response.data.table.total);
            // setData1(response.data.table.orders);
          })
          .catch((error) => {
            let errObj = JSON.parse(JSON.stringify(error));

            if (error.response.status === 401) {
              console.log("-------------------------741", errObj);
              // setIsLogin(false);
            }
          });
        // setIsLogin(true);
      } else {
        // setIsLogin(false);
        history.push("/login");
        console.log("-------------------logout");
      }
    });
  };
  useEffect(() => {
    getData();
  }, []);
  const [value, setValue] = useState("");
  const [showDate, setShowDate] = useState(false);
  const handleDateChangeStart = (event) => {
    setSelectedDateStart(event);
  };
  const handleDateChangeEnd = (event) => {
    setSelectedDateEnd(event);
  };

  useEffect(() => {
    console.log("denemem data", data);
    let new_data = { ...staticData };
    // console.log("new_data.labels", new_data.labels);
    // console.log("new_data.datasets.data", new_data.datasets);
    if (data !== undefined) {
      // console.log("data.labels", data.labels);
      // console.log("data.data", data.data);
      console.log("data.name", data.name);
      new_data.labels = data.labels;
      new_data.datasets[0].data = data.data;
      new_data.datasets[0].label = data.name;
      setStaticData(new_data);
    }
  }, [data]);
  const handleRadioChange = (event) => {
    setValue(event.target.value);
    console.log("dddddddddd****---", event.target.value);
    if (event.target.value === "other") {
      setShowDate(true);
    } else {
      setShowDate(false);
    }
    // setHelperText(" ");
    // setError(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let val = "";
    if (value === "other") {
      val = `?type=${value}&start=${event.target.discountDateStart.value}&end=${event.target.discountDateEnd.value}`;
    } else {
      val = `?type=${value}`;
    }

    getData(val, value);
  };
  return (
    <Layout>
      <div className="container">
        <h3 className="text-center">My account activities</h3>

        <div className="row">
          <div className="col-12 col-sm-12 col-md-3">
            <b>Select time period</b>
            <form className="rcorners2" onSubmit={handleSubmit}>
              <FormGroup tag="fieldset" onChange={handleRadioChange}>
                <FormGroup check>
                  <Label check>
                    <Input type="radio" name="radio1" value="today" /> Today
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="radio" name="radio1" value="week" /> This week
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="radio" name="radio1" value="4weeks" /> Last
                    four weeks
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="radio" name="radio1" value="other" /> Other
                  </Label>
                </FormGroup>
              </FormGroup>
              {showDate && (
                <div className="row mb-3">
                  <div className="col-12">
                    <b>Select Date</b>
                  </div>
                  <div className="col-6">
                    {" "}
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        value={selectedDateStart}
                        onChange={handleDateChangeStart}
                        format="DD-MM-YYYY"
                        name="discountDateStart"
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className="col-6">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        value={selectedDateEnd}
                        onChange={handleDateChangeEnd}
                        format="DD-MM-YYYY"
                        name="discountDateEnd"
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              )}
              <Button
                none
                type="submit"
                size="sm"
                color="primary"
                className="btnGetData"
              >
                Get Data
              </Button>
            </form>
          </div>
          <div className="col-12 col-sm-12 col-md-9">
            <Line data={staticData} />
          </div>
        </div>
        {/* <div className="row mb-3">
          <div className="col-12">
            <Line data={staticData} />
          </div>
        </div> */}
        {/* <OrderTable
          columns={columns}
          data={orders}
          // total={total}
          renderRowSubComponent={renderRowSubComponent}
        /> */}
      </div>
      {/* <div className="cartTitle">
        <h3 className="text-center titleText">Orders</h3>
        <hr></hr>
      </div> */}
      {/* <div className="container"> */}
      <OrderTable
        columns={columns}
        data={orders}
        total={total}
        renderRowSubComponent={renderRowSubComponent}
      />
      {/* </div> */}
    </Layout>
  );
};

export default Orders;
