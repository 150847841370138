import React, { useState, useEffect } from "react";
// import { red } from "@material-ui/core/colors";
// import CancelIcon from "@material-ui/icons/Cancel";
import ListAltIcon from "@material-ui/icons/ListAlt";
import axios from "axios";
import { API } from "config";

const Warehouse = ({ item, store_id, Ingrs, setWarehouses }) => {
    //   const jwt = JSON.parse(localStorage.getItem("jwt"));
    //   const token = jwt.token;
    //   const header = {
    //     Authorization: `Token ${token}`,
    //   };
    const [WarehouseNameUpdate, setWarehouseNameUpdate] = useState(item.name);

    const updateIngredient = async (operation) => {
        const jwt = JSON.parse(localStorage.getItem("jwt"));
        let data =
            jwt !== null
                ? {
                      method: "GET",
                      headers: {
                          Authorization: `Token ${jwt.token}`,
                      },
                  }
                : {};
        let URL = `${API}/stores/update/warehouse/`;
        let form_data = new FormData();
        form_data.append("store_id", store_id);
        form_data.append("name", WarehouseNameUpdate);
        form_data.append("id", item.id);
        form_data.append("operation", operation);
        const update = await axios.post(URL, form_data, data);
        console.log("update data", update.data);
        setWarehouses(update.data);
    };
    const handleChangeWarehouse = (event) => {
        setWarehouseNameUpdate(event.target.value);
    };

    const onClickDeleteIngredient = (event) => {
        let r = window.confirm(
            "Are you sure you want to delete this warehouse?"
        );
        if (r === true) {
            updateIngredient("delete");
            console.log("delete");
        } else {
        }
    };
    const handleonBlurUpdateWarehouse = (event) => {
        if (WarehouseNameUpdate === "") {
            console.log("delete");
            updateIngredient("delete");
        } else {
            console.log("update");
            updateIngredient("update");
        }
    };
    const handleKeyPressWarehouse = (event) => {
        if (event.key === "Enter") {
            if (WarehouseNameUpdate === "") {
                updateIngredient("delete");
                console.log("delete");
            } else {
                console.log("update");
                updateIngredient("update");
            }
        }
    };
    console.log("buraya geliyor mu acaba");
    return (
        <React.Fragment>
            <div className="w-100">
                <input
                    type="text"
                    className="form-control editable col-11  d-inline-block"
                    value={WarehouseNameUpdate}
                    onBlur={handleonBlurUpdateWarehouse}
                    onKeyPress={handleKeyPressWarehouse}
                    onChange={handleChangeWarehouse}
                    placeholder="Type ingredient"
                    size="20"
                    data-ng-change="updateIngredient(ingredient, curProduct)"
                ></input>
                <i
                    className="fa fa-times-circle remove float-right "
                    style={{
                        color: "red",
                        fontSize: "24px",
                        lineHeight: "38px",
                    }}
                    onClick={onClickDeleteIngredient}
                ></i>
                {/* <ListAltIcon /> */}
            </div>
        </React.Fragment>
    );
};
export default Warehouse;
