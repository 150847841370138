import React, { useState } from "react";
import "./ImgUpload.css";

import ImgDropzone from "components/ImgDropzone/ImgDropzone";
import axios from "axios";
import { API } from "config";

const ImgUpload = () => {
  const storeInfos = { logo: "", header: "" };
  const [backgroundImg, setBackgroundImg] = useState(storeInfos.header);
  const [logoImg, setlogoImg] = useState(storeInfos.logo);

  const updateImage = async (type_, image) => {
    const jwt = JSON.parse(localStorage.getItem("jwt"));
    let data =
      jwt !== null
        ? {
            method: "GET",
            headers: {
              // Accept: "application/json",
              // "Content-Type": "application/json",
              Authorization: `Token ${jwt.token}`,
            },
          }
        : {};
    let form_data = new FormData();
    form_data.append(type_, image, image.name);
    form_data.append("name", "test");
    form_data.append("active", "test");
    var URL_PUT = `${API}/43/update/store/`;
    const change = await axios.put(URL_PUT, form_data, data);
    console.log("change", change);
    // setSuccessShow(true);
  };
  const onBackDrop = (acceptedFiles) => {
    setBackgroundImg(URL.createObjectURL(acceptedFiles[0]));
    console.log(acceptedFiles, "11111", acceptedFiles[0].name);
    updateImage("background", acceptedFiles[0]);
  };
  const onLogoDrop = (acceptedFiles) => {
    setlogoImg(URL.createObjectURL(acceptedFiles[0]));
    updateImage("logo", acceptedFiles[0]);
  };
  return (
    <ImgDropzone
      logoImg={logoImg}
      backgroundImg={backgroundImg}
      onLogoDrop={onLogoDrop}
      onBackDrop={onBackDrop}
    ></ImgDropzone>
  );
};

export default ImgUpload;
