import { API } from "../config";
export const signupBusiness = (user) => {
  user.role = 1;
  return fetch(`${API}/signup`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const signup = (user) => {
  user.role = 2;
  return fetch(`${API}/signup`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const signin = (user) => {
  return fetch(`${API}/signin`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log("karisir", err);
      console.log(err);
    });
};

export const authenticate = (data, next) => {
  if (typeof window !== "undefined") {
    localStorage.setItem("jwt", JSON.stringify(data));
    next();
  }
};

// export const signout = (next) => {
//   if (typeof window !== "undefined") {
//     localStorage.removeItem("jwt");
//     next();
//     return fetch(`${API}/signout`, {
//       method: "GET",
//     })
//       .then((response) => {
//         console.log("signout", response);
//       })
//       .catch((err) => console.log(err));
//   }
// };
export const signout = (next, token) => {
  
  if (typeof window !== "undefined") {
    let jwt = JSON.parse(localStorage.getItem("jwt"));
    let token = jwt.token;
    localStorage.removeItem("jwt");
    localStorage.removeItem("cart_id");
    next();
    return fetch(`${API}/signout`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        console.log("signout", response);
      })
      .catch((err) => console.log(err));
  }
};

export const isAuthenticated = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (localStorage.getItem("jwt")) {
    return JSON.parse(localStorage.getItem("jwt"));
  } else {
    return false;
  }
};

export const confirmEmail = (key) => {
  return fetch(`${API}/email/confirm/${key}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
